<template>

  <div class="main-body" >
    <img src="@/assets/img/banner-sq.png" height="" width="100%" style="min-width:1200px">
    <div class="c-1200" style="position:relative;border-radius: 6px;margin-bottom: 50px;">
      <el-form ref="registrationInfo" :model="registrationInfo" :rules="registRules" label-width="150px" size="small">
      <div class="block" style="position:relative;margin-top:-126px;background-color: #fff;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
        <div style="text-align:center;padding:20px;height:80px;">
        <!-- <div style="text-align:center;border-bottom: 1px dashed #E60000;padding:20px;height:80px;"> -->
          <img src="@/assets/img/title-grsq.png" height="80px" width="">
        </div>
        <hr style="width:95%;text-align:center;border-top:2px dotted #E60000;">
  <!-- <div class="main-body" style="width:1200px;margin:0 auto;padding-bottom:50px">
    <div style="text-align:center">
      个人会员申请
    </div>
    <div> -->



          <div class="block-title">
          </div>
          <div class="block-content"  style="padding:0px 40px 10px 0px">
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="姓名" prop="userName">
                    <el-input v-model="registrationInfo.userName" clearable class="e-input" placeholder="请输入姓名" />
                  </el-form-item>
                </div>
                <div class="block-content-line-item" v-if="registrationInfo.id==null || registrationInfo.id==''"> 
                  <el-form-item label="手机号码" prop="phone">
                    <el-input v-model="registrationInfo.phone" clearable class="e-input" placeholder="请输入手机号码 " />
                    <span style="font-size:12px;" class="lh18">（ * 注册手机号必须没有在本系统中注册过）</span>
                  </el-form-item>
                </div>
                <div class="block-content-line-item" v-if="registrationInfo.id!=null && registrationInfo.id!=''"> 
                  <el-form-item label="手机号码" prop="phone">
                    <el-input v-model="registrationInfo.phone" clearable class="e-input" readonly placeholder="请输入手机号码 " />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex"  v-if="registrationInfo.id==null || registrationInfo.id==''">
                <div class="block-content-line-item">
                  <el-form-item label="登陆密码" prop="password">
                    <el-input v-model="registrationInfo.password" type="password" clearable class="e-input" placeholder="请输入登陆密码" />
                    <span style="font-size:12px;" class="lh18">（ * 注册密码必须同时拥有字母和数字）</span>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="再次输入密码" prop="rePassword">
                    <el-input v-model="registrationInfo.rePassword" type="password" clearable class="e-input" placeholder="请输入登陆密码" />
                    <span style="font-size:12px;" class="lh18">（ * 注册密码必须同时拥有字母和数字）</span>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="图形码" prop="picCode">
                    <el-col :span="19">
                      <el-input v-model="registrationInfo.picCode" clearable class="e-input" placeholder="请输入图形码" />
                    </el-col>
                  <el-col :span="5">
                    <div class="login-code">
                      <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                    </div>
                  </el-col>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="短信码" prop="smsCode">
                    <el-row>
                      <el-col :span="18">
                        <el-input v-model="registrationInfo.smsCode" clearable class="e-input" placeholder="请输入短信码" />
                      </el-col>
                      <el-col :span="6">
                        <el-button type="default" class="login-btn" v-if="sendMessageState=='0'" @click="sendLoginCode" >{{sendBtnMessage}}</el-button>
                        <el-button type="default" class="login-btn" v-if="sendMessageState=='1'" >{{sendBtnMessage}}</el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="证件类型" prop="certType">
                    <el-select v-model="registrationInfo.certType" placeholder="请选择">
                      <el-option
                        v-for="item in certTypeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="证件号码" prop="certNum">
                    <el-input v-model="registrationInfo.certNum" clearable class="e-input" placeholder="请输入证件号码" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="身份证正面（人像面）" prop="certPic">
                    <el-upload
                    :class="{hideUpload: certPicHideUpload}"
                    action="/api/system/auth/upload"
                    :limit="1"
                    list-type="picture-card"
                    :before-upload="certPicUploadBefore"
                    :on-success="certPicUploadSuccess"
                    :on-remove="certPicRemove"
                    :on-preview="certPicPreview"
                    :on-exceed="exceedImage"
                    :file-list="certPicList"
                    ref="dmtCertPicList"
                    >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <!--弹框展示图片-->
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                  </el-form-item>
                </div>

                <div class="block-content-line-item">
                  <el-form-item label="身份证反面（国徽面）" prop="certPicEmblem">
                    <el-upload
                    :class="{hideUpload: certPicEmblemHideUpload}"
                    action="/api/system/auth/upload"
                    :limit="1"
                    list-type="picture-card"
                    :before-upload="certPicEmblemUploadBefore"
                    :on-success="certPicEmblemUploadSuccess"
                    :on-remove="certPicEmblemRemove"
                    :on-preview="certPicPreview"
                    :on-exceed="exceedEmblemImage"
                    :file-list="certPicEmblemList"
                    ref="dmtCertPicEmblemList"
                    >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <!--弹框展示图片-->
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="性别" prop="sex">
                    <el-select v-model="registrationInfo.sex" placeholder="请选择">
                      <el-option
                        v-for="item in sexEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="出生年月" prop="birthday">
                    <el-date-picker
                      v-model="registrationInfo.birthday"
                      type="date"
                      placeholder="选择出生年月">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="申请类别" prop="communityJob">
                    <el-select v-model="registrationInfo.communityJob" placeholder="请选择">
                      <el-option
                        v-for="item in communityJobEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="民族" prop="nation">
                    <el-select v-model="registrationInfo.nation" placeholder="请选择">
                      <el-option
                        v-for="item in nationEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="国籍" prop="nationality">
                    <el-select v-model="registrationInfo.nationality" placeholder="请选择">
                      <el-option
                        v-for="item in countryEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="邮政编码" prop="postCode">
                    <el-input v-model="registrationInfo.postCode" clearable class="e-input" placeholder="请输入邮政编码" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="通讯地址" prop="address">
                    <el-input v-model="registrationInfo.address" clearable class="e-input" placeholder="请输入通讯地址" />
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="其他社会职务" prop="otherJob">
                    <el-input v-model="registrationInfo.otherJob" clearable class="e-input" placeholder="请输入其他社会职务" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="政治面貌" prop="political">
                    <el-select v-model="registrationInfo.political" placeholder="请选择">
                      <el-option
                        v-for="item in politicalEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="本组织党内职务" prop="partyJob">
                    <el-input v-model="registrationInfo.partyJob" clearable class="e-input" placeholder="请输本组织党内职务" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="党组织关系所在单位" prop="partyUnity" class="bcli-lh18 pd-50">
                    <el-input v-model="registrationInfo.partyUnity" clearable class="e-input" placeholder="请输入党组织关系所在单位" />
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="文化程度" prop="eduDegree">
                    <el-select v-model="registrationInfo.eduDegree" placeholder="请选择">
                      <el-option
                        v-for="item in degreeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="是否社工师" prop="socialWorker">
                    <el-select v-model="registrationInfo.socialWorker" placeholder="请选择">
                      <el-option
                        v-for="item in socialWorkerEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="职业资格水平" prop="professionLevel">
                    <el-select v-model="registrationInfo.professionLevel" placeholder="请选择">
                      <el-option
                        v-for="item in professionLevelEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="从业单位名称及职务" prop="unityJob" class="bcli-lh18 pd-40">
                    <el-input v-model="registrationInfo.unityJob" clearable class="e-input" placeholder="请输入从业单位名称及职务" />
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="从业单位类别" prop="unityType">
                    <el-select v-model="registrationInfo.unityType" placeholder="请选择">
                      <el-option
                        v-for="item in unityTypeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="所属市州" prop="city">
                    <el-input v-model="registrationInfo.city" clearable class="e-input" placeholder="请输入所属市州" />
                  </el-form-item>
                </div>
                <div class="block-content-line-item">
                  <el-form-item label="所属行业" prop="industryType">
                    <el-input v-model="registrationInfo.industryType" clearable class="e-input" placeholder="请输入所属行业" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="个人简历" prop="resume">
                    <el-input v-model="registrationInfo.resume" type="textarea" clearable class="e-input" placeholder="请输入个人简历" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div class="block-content-line-item">
                  <el-form-item label="获得的荣誉及发表的论文" prop="honor" class="bcli-lh18 pd-50">
                    <el-input v-model="registrationInfo.honor" type="textarea" clearable class="e-input" placeholder="请输入获得的荣誉及发表的论文" />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="text-align:center">
              <el-button type="default" class="register-btn" @click="confirmRegister" >确认提交</el-button>
            </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>

import { getCodeImg,sendMsg } from "@/api/login";
import { getSystemEnum,userRegister,getCustomerUserById,userEdit } from "@/api/register";
import {isEmpty,validatePhone} from '@/utils/validator'
export default {
  name: "user_edit",
  components: {},
  data() {
    let _this = this
    let validatePic = function(rule, value, callback){
        if(_this.certPicList.length==0){
          callback(new Error('请上传身份证正面'))
        }
        callback()
    }
    let validateEmblemPic = function(rule, value, callback){
        if(_this.certPicEmblemList.length==0){
          callback(new Error('请上传身份证反面'))
        }
        callback()
    }
    return {
      codeUrl: "",
      sendMessageState:'0',
      sendBtnMessage:"发送验证码",
      certTypeEnum:[],
      sexEnum:[{code:0,info:"男"},{code:1,info:"女"}],
      communityJobEnum:[],
      nationEnum:[],
      countryEnum:[],
      politicalEnum:[],
      degreeEnum:[],
      socialWorkerEnum:[{code:0,info:"否"},{code:1,info:"是"}],
      professionLevelEnum:[],
      unityTypeEnum:[],
      registrationInfo:{},
      dialogImageUrl: '',
      dialogVisible: false,
      certPicHideUpload:false,
      certPicEmblemHideUpload:false,
      certPicList:[],
      certPicEmblemList:[],
      registRules: {
        userName: [
            { required: true, message: "请输入姓名", trigger: "blur"}
          ],
        phone: [
            { required: true, message: "请输入登录手机号", trigger: "blur"}
          ],
        password: [
            { required: true, message: "请输入密码", trigger: "blur"}
          ], 
        rePassword: [
            { required: true, message: "请再次输入密码", trigger: "blur"}
          ], 
        picCode: [
            { required: true, message: "请输入图形码", trigger: "blur"}
          ], 
        smsCode: [
            { required: true, message: "请输入短信码", trigger: "blur"}
          ], 
        certType: [
          { required: true, message: "请选择证件类型", trigger: "blur"}
        ], 
        certNum: [
          { required: true, message: "请输入证件号码", trigger: "blur"}
        ], 
        certPic: [
            { required: true,validator: validatePic, trigger: "change"}
        ], 
        certPicEmblem: [
            { required: true,validator: validateEmblemPic, trigger: "change"}
        ], 
        sex: [
          { required: true, message: "请选择性别", trigger: "blur"}
        ], 
        birthday: [
          { required: true, message: "请选择生日", trigger: "blur"}
        ], 
        communityJob: [
          { required: true, message: "请选择申请类别", trigger: "blur"}
        ], 
        nation: [
          { required: true, message: "请选择民族", trigger: "blur"}
        ],
        nationality: [
          { required: true, message: "请选择国籍", trigger: "blur"}
        ],
        postCode: [
          { required: true, message: "请选择邮政编码", trigger: "blur"}
        ],
        address: [
          { required: true, message: "请选择通讯地址", trigger: "blur"}
        ],
        otherJob: [
          { required: true, message: "请输入其他社会职务", trigger: "blur"}
        ],
        political: [
          { required: true, message: "请输入政治面貌", trigger: "blur"}
        ],
        partyJob: [
          { required: true, message: "请输入本组织党内职务", trigger: "blur"}
        ],
        partyUnity: [
          { required: true, message: "请输入党组织关系所在单位", trigger: "blur"}
        ],
        eduDegree: [
          { required: true, message: "请选择文化程度", trigger: "blur"}
        ],
        socialWorker: [
          { required: true, message: "请选择是否社工师", trigger: "blur"}
        ],
        professionLevel: [
          { required: true, message: "请选择职业资格水平", trigger: "blur"}
        ],
        unityType: [
          { required: true, message: "请选择从业单位类别", trigger: "blur"}
        ],
        unityJob: [
          { required: true, message: "请输入从业单位名称及职务", trigger: "blur"}
        ],
        city: [
          { required: true, message: "请选择所属市州", trigger: "blur"}
        ],
        industryType: [
          { required: true, message: "请输入所属行业", trigger: "blur"}
        ],
        resume: [
          { required: true, message: "请输入个人简历", trigger: "blur"}
        ],
        honor: [
          { required: true, message: "请输入获得的荣誉及发表的论文", trigger: "blur"}
        ]
      }
    };
  },
  mounted() {
    const id = this.$route.query.id
    if(!isEmpty(id)){
      this.registrationInfo.id=id
      this.getCustomerUserById(id)
    }else{
      this.getSystemEnum();
      this.getCode();
    }
  },
  watch:{
  },
  methods: {
   // 获取详情
   getCustomerUserById(id) {
      getCustomerUserById(id).then(res => {
          console.log(res)
          this.registrationInfo=res.data
          this.certPicList=res.certPicList
          if(this.certPicList.length>=1){
            this.certPicHideUpload=true
          }else{
            this.certPicHideUpload=false
          }
          this.certPicEmblemList=res.certPicEmblemList
          if(this.certPicEmblemList.length>=1){
            this.certPicEmblemHideUpload=true
          }else{
            this.certPicEmblemHideUpload=false
          }
          this.getSystemEnum();
          this.getCode();
      });
    },
    // 图形验证码
    getSystemEnum() {
      getSystemEnum().then(res => {
        this.certTypeEnum=res.certTypeEnum;
        this.communityJobEnum=res.communityJobEnum;
        this.nationEnum=res.nationEnum;
        this.countryEnum=res.countryEnum;
        this.politicalEnum=res.politicalEnum;
        this.degreeEnum=res.degreeEnum;
        this.professionLevelEnum=res.professionLevelEnum;
        this.unityTypeEnum=res.unityTypeEnum;
      });
    },
    // 图形验证码
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.registrationInfo.uuid = res.uuid;
        }
      });
    },
    // 短信验证码
    sendLoginCode(){
      const phone = this.registrationInfo.phone
      if(phone==null || !validatePhone(phone)){
          this.$message.warning('手机号为空或不合法')
          return
      }
      if(this.registrationInfo.picCode == null){
        this.$message.warning('图形验证码未填写!')
        return
      }
      const data = {
        loginPhone:phone,
        picCode:this.registrationInfo.picCode,
        uuid:this.registrationInfo.uuid
      }
      sendMsg(data).then(response => {
          console.log(response)
          this.$message.success('验证码已发送')
          this.getCode();
          this.registrationInfo.smsUuid=response.smsUuid
          this.sendMessageState='1'
          let count = 60
          const secondCount = setInterval(()=>{
              if(count>0){
                  this.sendMessageState='1'
                  this.sendBtnMessage = count + 's'
                  count--
              }else{
                  this.sendMessageState='0'
                  this.sendBtnMessage = '发送验证码'
                  window.clearInterval(secondCount)
              }
          },1000)
      })
    },
    confirmRegister(){
      this.$refs["registrationInfo"].validate(valid => {
        if (valid) {
          //上传图片
          this.registrationInfo.uploadPicList=this.certPicList;
          this.registrationInfo.uploadPicEmblemList=this.certPicEmblemList;
          if(isEmpty(this.registrationInfo.id)){
            userRegister(this.registrationInfo).then(response => {
                console.log(response)
                this.$message.success('注册成功')
                // 跳转到登录页
                this.$router.push({
                  path:'/menu_page/sys/login'
                })
            })
          }else{
            userEdit(this.registrationInfo).then(response => {
                console.log(response)
                this.$message.success('修改成功')
                // 跳转到详情页面
                this.$router.push({
                  path:'/menu_page/my/my_index'
                })
            }) 
          }
        } 
      });
    },
    // 上传前
    certPicUploadBefore(file){
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt2M;
    },
    exceedImage(){
        this.certPicHideUpload=true
        this.$message.warning('上传数量超过限制')
    },
    // 上传成功
    certPicUploadSuccess(res, file) {
      if(res.code==200){
        this.certPicList.push(res.file)
        if(this.certPicList.length>=1){
          this.certPicHideUpload=true
        }else{
          this.certPicHideUpload=false
        }
      }else{
         this.$message.error('上传失败');
        this.$refs.dmtCertPicList.uploadFiles.splice(this.$refs.dmtCertPicList.uploadFiles.indexOf(res),1)
      }
    },
    // 删除图片
    certPicRemove(file, fileList) {
      console.log(file, fileList);
      for(let i=0;i<this.certPicList.length;i++){
        if (this.certPicList[i].id === file.id) {
          this.certPicList.splice(i,1)
          break
        }
      }
      if(this.certPicList.length>=1){
        this.certPicHideUpload=true
      }else{
        this.certPicHideUpload=false
      }
    },
    // 上传前
    certPicEmblemUploadBefore(file){
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt2M;
    },
    exceedEmblemImage(){
        this.certPicEmblemHideUpload=true
        this.$message.warning('上传数量超过限制')
    },
    // 上传成功
    certPicEmblemUploadSuccess(res, file) {
      if(res.code==200){
        this.certPicEmblemList.push(res.file)
        if(this.certPicEmblemList.length>=1){
          this.certPicEmblemHideUpload=true
        }else{
          this.certPicEmblemHideUpload=false
        }
      }else{
         this.$message.error('上传失败');
        this.$refs.dmtCertPicEmblemList.uploadFiles.splice(this.$refs.dmtCertPicEmblemList.uploadFiles.indexOf(res),1)
      }
    },
    // 删除图片
    certPicEmblemRemove(file, fileList) {
      console.log(file, fileList);
      for(let i=0;i<this.certPicEmblemList.length;i++){
        if (this.certPicEmblemList[i].id === file.id) {
          this.certPicEmblemList.splice(i,1)
          break
        }
      }
      if(this.certPicEmblemList.length>=1){
        this.certPicEmblemHideUpload=true
      }else{
        this.certPicEmblemHideUpload=false
      }
    },
    // 预览图片
    certPicPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  },
};
</script>

<!-- <style scoped lang="scss"> -->
<style scoped>

.flex{
  display:flex;
}
.block-title{
  margin-top:50px;
}
.block-content-line-item{flex:4}
/deep/ .block-content-line-item .el-select{width:100%;}
/deep/ .block-content-line .el-form-item__label{padding-left: 20px;}
/deep/ .bcli-lh18  .el-form-item__label, .lh18{line-height: 18px;}
/deep/ .label-center .el-form-item__label{text-align: center;}
.label1{
  width: 150px;padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2{
  padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2 span{
  padding-left:5px;padding-right: 5px;text-decoration: underline;
}

.login-code{
  width: 60px;
  height: 38px;
  img {
    cursor: pointer;
    vertical-align: middle; 
  }
 }
.login-code-img {
  height: 32px;
  margin-left: 10px;
}
.login-btn{
  height: 32px;
  margin-left: 10px;
  width:calc(100% - 10px);
}

.register-btn{
  margin-top: 30px;
  margin-bottom: 50px;
  /* margin-left: 50%; */
}

/** 隐藏上传图标*/
/deep/ .hideUpload .el-upload--picture-card{
  visibility: hidden;
}
/deep/ .pd-60 .el-form-item__label{padding-left: 60px;}
/deep/ .pd-50 .el-form-item__label{padding-left: 50px;}
/deep/ .pd-40 .el-form-item__label{padding-left: 40px;}
</style>