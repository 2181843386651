<!-- 作者:  -->
<!-- 页面功能:  -->
<!-- 创建时间: 2023年04月05日 22:45:11 -->
<template>
  <div>
    <video id="localVideo" autoplay controls="true" height=300 width=600>
        <source src="rtmp://47.92.23.206/live/livestream/test" type="rtmp/flv"/>
      </video>
  </div>
</template>

<script>
import video from 'video.js'

import 'video.js/dist/video-js.css'
export default {
  components: { },
  data() {
    return {

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
      //this.playVideo()
    var player = video('localVideo')
      player.play()
  },
  //生命周期 - 方法
  methods: {
      playVideo:async function(){ 
            const constraints = {
                'video': true,
                'audio': true
            }
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            const videoElement = document.querySelector('video#localVideo');
            videoElement.srcObject = stream;
        }
  }
};
</script>
<style scoped>
  /* @import url(); 引入css类 */

</style>