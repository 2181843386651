import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui'
// import Fontawesome from 'font-awesome'
import 'element-ui/lib/theme-chalk/index.css'
// import '@/assets/css/element-custom.css'

import '@/assets/css/default.css'
// import '@/assets/css/bootstrap.min.css'
import '@/assets/css/font-awesome.min.css'
// import 'font-awesome/css/font-awesome.min.css'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";
import { getDicts } from "@/api/dict/data";
// 分页组件
import Pagination from "@/components/Pagination";
// 字典数据组件
import DictData from '@/components/DictData'

// 字典标签组件
import DictTag from '@/components/DictTag'
import { autoLogin } from "@/api/login";
import {setToken,getToken,removeToken} from '@/utils/auth'

import {VueMasonryPlugin} from 'vue-masonry'
Vue.use(VueMasonryPlugin)

Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree

Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)

DictData.install()

Vue.config.productionTip = false

Vue.use(ElementUI)
// Vue.use(Fontawesome)

if(process.env.NODE_ENV === 'development'){
      // 企业测试账号
      var loginData={loginPhone:"15994775314",inputPwd:"123456",type:"2"}
      //var loginData={loginPhone:"15929968783",inputPwd:"123456",type:"2"}
      //个人测试账号
      //  var loginData={loginPhone:"15994775314",inputPwd:"123456",type:"1"}
      // var loginData={loginPhone:"17791518068",inputPwd:"123456",type:"1"}

      autoLogin(loginData).then(response => {
        console.log('login success!')
        const token = response.token
        setToken(token)
        store.dispatch("user/login",response.loginInfo)
        localStorage.setItem("loginInfo",JSON.stringify(response.loginInfo))
        return Promise.resolve(response)
      }).then(()=> {
        new Vue({
          render: h => h(App),
          router,
          store
        }).$mount('#app')
      })
}else{
  // 刷新保存登录信息
  const loginInfo=localStorage.getItem("loginInfo");
  if(loginInfo!=null){
    store.dispatch("user/login",JSON.parse(loginInfo))
  }
  new Vue({
    render: h => h(App),
    router,
    store
  }).$mount('#app')
}

















