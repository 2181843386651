<template>
  <div class="main-body" style="background-color:#fff !important;">
      <div>
      <!-- <div style="margin:auto;background-color:#fff;padding:20px 60px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;"> -->
        <el-table :data="signRecordList">
            <el-table-column label="课程名称" align="center" prop="courseName" />
            <el-table-column label="课程类型" align="center" prop="courseType">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.course_type" :value="scope.row.courseType"/>
            </template>
            </el-table-column>
            <el-table-column label="状态" width="100px" align="center" prop="status">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.sign_status" :value="scope.row.status"/>
            </template>
            </el-table-column>
            <el-table-column label="姓名" align="center" prop="name" />
            <el-table-column label="性别" align="center" prop="sex">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.sys_user_sex" :value="scope.row.sex"/>
            </template>
            </el-table-column>
            <el-table-column label="联系方式" align="center" prop="phone" />
            <el-table-column label="文化程度" align="center" prop="edu">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.edu" :value="scope.row.edu"/>
            </template>
            </el-table-column>
            <el-table-column label="身份证" align="center" prop="idCard" />
            <el-table-column label="联系地址" align="center" prop="address" />
            <el-table-column label="工作单位" align="center" prop="company" />
            <el-table-column label="职位" align="center" prop="job" />
            <el-table-column label="参加工作时间" align="center" prop="jobTime" width="180">
            <template slot-scope="scope">
                <span>{{ parseTime(scope.row.jobTime, '{y}-{m}-{d}') }}</span>
            </template>
            </el-table-column>
            <el-table-column label="现工作岗位" align="center" prop="post" />
            <el-table-column label="从事本职业(工种)技术工作年限" align="center" prop="workYear" />
            <el-table-column label="从事本职业(工种)工龄" align="center" prop="workAge" />
            <el-table-column label="原证书职业名称及等级" align="center" prop="grade" />
            <el-table-column label="原证书编号" align="center" prop="certificateNum" />
            <el-table-column label="申报职业" align="center" prop="declarationApplyJob" />
            <el-table-column label="申报等级" align="center" prop="declarationApplyGrade" />
            <el-table-column label="原证书时间" align="center" prop="certificateDate" width="180">
            <template slot-scope="scope">
                <span>{{ parseTime(scope.row.certificateDate, '{y}-{m}-{d}') }}</span>
            </template>
            </el-table-column>
            <el-table-column label="获奖情况" align="center" prop="awards" />
            <el-table-column label="是否需要住宿" align="center" prop="lodgings">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.needs" :value="scope.row.lodgings"/>
            </template>
            </el-table-column>
            <el-table-column label="是否需要午餐" align="center" prop="lunchs">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.needs" :value="scope.row.lunchs"/>
            </template>
            </el-table-column>
            <el-table-column label="是否需要晚餐" align="center" prop="dinners">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.needs" :value="scope.row.dinners"/>
            </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-add" @click="goSignRecordDetail(scope.row.id)">
                详情
                </el-button>
            </template>
            </el-table-column>
        </el-table>
      </div>
    </div>
  </template>
  
  <script>
  
  import {getSignRecordList} from "@/api/login";

  export default {
    name: "routerpage-index",
    dicts: ['certificate_type','course_type','sign_status','sys_user_sex','needs','edu'],
    components: {},
    data() {
      return {
        loginId:"",
        loginPhone:"",
        loginName:"",
        loginType:"",
        signRecordList:[]
      };
    },
    mounted() {
      this.loginId=this.$store.getters.loginId
      this.loginPhone=this.$store.getters.loginPhone
      this.loginName=this.$store.getters.loginName
      this.loginType=this.$store.getters.loginType
      this.getSignRecordList();
    },
    watch:{
    },
    methods: {
      // 我的报名列表
      getSignRecordList:function(){
        getSignRecordList().then(response => {
          this.signRecordList=response.list
        })
      },
      goSignRecordDetail:function(id){
        this.$router.push({
          path:'/menu_page/train_sign_detail?recordId='+id
        })
      }
    },
  };
  </script>
  <style rel="stylesheet/scss" lang="css">
   .login-code{
    width: 100%;
    height: 38px;
    img {
      cursor: pointer;
      vertical-align: middle; 
    }
   }
   .login-code-img {
      height: 38px;
      /* margin-left: 10px; */
      width:100%;
    }
    .login-btn{
      height: 38px;
      width:120px;
      width:100%;
      /* margin-left: 10px; */
    }
    .el-link{
      font-size: 12px !important;
      line-height:14px;
      float:right;
      vertical-align: middle;
      margin-top:15px;
    }
  </style>