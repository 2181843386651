<!-- 作者: 关佳琪 -->
<!-- 页面功能: 公共主页头 -->
<!-- 创建时间: 2020年05月07日 10:37:44 -->
<template>
 <div class="header">
  <div>
    <div class="c-1200">
      <el-row :gutter="10" style="    line-height: 100px;">
        <el-col :span="12">
          <img src="../../assets/img/logo.png" style="height:76px; vertical-align: middle;"/>
        </el-col>
        <el-col :span="4">
          <el-input
            type="text"
            v-model="keyWord"
            placeholder="请输入课程或者文章"
            style="width: 100%; 
            cursor: pointer"
            @keyup.enter.native="goToSearch">
            <template #suffix>
              <i class="el-icon-search" @click="goToSearch"></i>
            </template>
          </el-input>
        </el-col>
        <el-col :span="4" v-if="loginId==null || loginId==''">
          
          <div style="text-align:center;text-align: right;line-height: 100px;height:100px;">
            <el-link type="info" style="line-height:20px;font-size: 14px;float:none;vertical-align: middle;margin-top: 0px;" @click="login">
              登陆 / 注册
            </el-link>
          </div>
        </el-col>
        <el-col :span="4" v-if="loginId!=null && loginId!=''">
          <div style="text-align:center;text-align: right;">
            <el-dropdown @command="handleCommand" style="height:30px; vertical-align: middle;line-height:30px;">
              <span class="el-dropdown-link" style="color:#666666 !important;">
                {{loginName}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="myData">
                  {{loginType=='1'?'个人信息':'企业信息'}}
                </el-dropdown-item>
                <el-dropdown-item command="resetPassword">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <div style="background-color: #FE0000;">
    <div class="c-1200 main-menu">
      <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#FE0000"
        text-color="#fff"
        font-size="32px"
        active-text-color="#fff">
        <el-menu-item index="0" class="nemu1" @click="toPage('/menu_page/index',{module:0})">首页</el-menu-item>
        <el-submenu index="1">
          <template slot="title">新闻资讯</template>
          <el-menu-item index="1" @click="toNews(1,1)">焦点新闻</el-menu-item>
          <el-menu-item index="1" @click="toNews(1,2)">行业动态</el-menu-item>
          <el-menu-item index="1" @click="toNews(1,3)">政策法规</el-menu-item>
          <el-menu-item index="1" @click="toNews(1,4)">工作动态</el-menu-item>
          <el-menu-item index="1" @click="toNews(1,5)">酒业文创</el-menu-item>
          <el-menu-item index="1" @click="toNews(1,6)">党建工作</el-menu-item>
          <el-menu-item index="1" @click="toNews(1, 9)">品牌营销</el-menu-item>
          <!-- <el-submenu index="2-4">
            <template slot="title">选项4</template>
            <el-menu-item index="2-4-1">选项1</el-menu-item>
            <el-menu-item index="2-4-2">选项2</el-menu-item>
            <el-menu-item index="2-4-3">选项3</el-menu-item>
          </el-submenu> -->
        </el-submenu>
        <el-menu-item index="8"  class="nemu1" @click="toNews(8, 1)">通知公告</el-menu-item>
        <el-submenu index="2">
          <template slot="title">技术服务</template>
          <el-menu-item index="2" @click="toNews(2,1)">人才培育</el-menu-item>
          <el-menu-item index="2" @click="toNews(2,2)">品评酿造</el-menu-item>
          <el-menu-item index="2" @click="toNews(2,3)">设备器皿</el-menu-item>
          <el-menu-item index="2" @click="toNews(2,4)">标准</el-menu-item>
        </el-submenu>
        <!-- <el-menu-item index="5"  class="nemu1">服务专栏</el-menu-item> -->
        <!-- <el-menu-item index="5"><a href="https://www.ele.me" target="_blank">协会介绍</a></el-menu-item> -->
        <el-submenu index="4">
          <template slot="title">协会介绍</template>
          <el-submenu index="4-1">
            <template slot="title">关于我们</template>
            <el-menu-item index="4" @click="toNewsDetail('3ef429e2425f47e7b0f3667d584f2d24')">协会历史</el-menu-item>
            <el-menu-item index="4" @click="toNewsDetail('07a007fea6344defb1fbb7e6a86c738d')">协会简介</el-menu-item>
            <el-menu-item index="4" @click="toNewsDetail('f36ee0dec00c4d548046b1e511f416a2')">服务内容</el-menu-item>
          </el-submenu>
          <el-submenu index="4-2">
            <template slot="title">组织架构</template>
            <el-menu-item index="4" @click="toNewsDetail('bfd5104346014abcb684bd05f5f12cd4')">协会</el-menu-item>
            <el-menu-item index="4" @click="toNewsDetail('0787149a49e34c40a553952a7fdb9cb4')">秘书处</el-menu-item>
            <el-menu-item index="4" @click="toNewsDetail('504c28f15e19429d87038ffee087d827')">白酒专家委员会</el-menu-item>
            <el-menu-item index="4" @click="toNewsDetail('e8bf54900fa14fcfa00294106b822c59')">文化专家委员会</el-menu-item>
            <el-menu-item index="4" @click="toNewsDetail('1e8a97ff5427423aa9d1a997e1eb8b9a')">营销专家委员会</el-menu-item>
            <el-menu-item index="4" @click="toNewsDetail('90ac0ef251b045fa86d4be984fd0490b')">顾问委员会</el-menu-item>
          </el-submenu>
        </el-submenu>
        
        <el-submenu index="6">
          <template slot="title">人才服务</template>
          <el-menu-item index="6" @click="trainExamList">培训报名</el-menu-item>
        </el-submenu>
        <el-menu-item index="9"  class="nemu1" @click="queryCertificate">证书校验</el-menu-item>
        <el-menu-item index="7"  class="nemu1" @click="toNews(7, 1)">会员风采</el-menu-item>
        <el-menu-item index="3"  class="nemu1" @click="toNews(3, 1)">酿酒科技</el-menu-item>
      </el-menu>
    </div>
  </div>

  <!-- <div style="background-color:#FE0000;height:55px;">
    <div class="c-1200 menu-">
      <div class="menu-item menu-active">首页</div>
      <div>新闻资讯</div>
      <div>技术服务</div>
      <div>酿酒科技</div>
      <div>服务专栏</div>
      <div>协会介绍</div>
    </div>
  </div> -->
 </div>
</template>

<script>
import { webLogout } from "@/api/login";
import {removeToken} from '@/utils/auth'
export default {
  data() {
    return {
			activeIndex: '',
      activeIndex2: '0',
      keyWord:"",
      loginId:"",
      loginPhone:"",
      loginName:"",
      loginType:""
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
      
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
      this.loginId=this.$store.getters.loginId
      this.loginPhone=this.$store.getters.loginPhone
      this.loginName=this.$store.getters.loginName
      this.loginType = this.$store.getters.loginType
      const moduleId = this.$route.query.module
      if (moduleId != null) { 
        this.activeIndex2 = moduleId + ''
      }
  },
  //生命周期 - 方法
  methods: {
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
    },
    toNews(module, type) { 
      window.location.href = '/menu_page/news_list?module=' + module + '&type=' + type
    },
    toPage(url,param) { 
      this.$router.push({
        path: url,
        query: param
      })
    },
    toNewsDetail(id) {
      window.location.href = '/menu_page/news_detail?id=' + id
    },
    trainExamList(){
      this.$router.push({
        path:'/menu_page/train_exam_list?module='+6
      })
    },
    queryCertificate(){
      this.$router.push({
        path: '/menu_page/certificate/certificate_query'
      })
    },

    login(){
      this.$router.push({
        path:'/menu_page/sys/login'
      })
    },
    updateModule(moduleId) { 
      this.activeIndex2 = moduleId
    },
    // 登出
    logout:function(){
      webLogout().then(response => {
        this.$message.success('登出成功!')
        this.$store.dispatch("user/logout")
        // token清空
        removeToken()
        localStorage.removeItem("loginInfo")
        this.loginId=null
        // 跳转到登录页
        this.$router.push({
          path:'/menu_page/sys/login'
        })
      })
    },
    resetPassword:function(){
      // 跳转到登录页
      this.$router.push({
        path:'/menu_page/sys/reset_password'
      })
    },
    handleCommand(command) {
      if(command=='myData'){
        this.$router.push({
          path:'/menu_page/my/my_index'
        })
      }
      if(command=='resetPassword'){
        this.resetPassword()
      }
      if(command=='logout'){
        this.logout()
      }
    },
    goToSearch:function(){
      // 跳转到检索页
      this.$router.push({
        path:'/menu_page/search_list',
        query: { keyWord: this.keyWord}
      })
    },
  }
};
</script>
<!-- <style scoped lang="scss"> -->
<style scoped>
  /* header */
/* /deep/.main-menu .el-menu {font-weight: bolder;} */
/deep/ .el-menu .el-submenu__title,/deep/ .el-menu .nemu1{font-size: 18px !important;}

/deep/ .el-menu .el-menu--horizontal .el-menu .el-submenu__title,/deep/ .el-menu .el-menu--horizontal .el-menu .el-menu-item {font-size: 18px !important;}
/deep/.main-menu .el-menu .el-icon-arrow-down:before{color:#fff !important}
/deep/.main-menu .el-menu .el-submenu__title i{color:#fff !important}
/deep/ .el-submenu__title i{color:#fff !important}
/deep/.main-menu .el-icon-arrow-right:before{color:#fff !important}
/deep/.main-menu .el-menu  .is-active,/deep/.main-menu .el-menu .is-active .el-submenu__title{background-color: #E60000 !important;border-bottom-color:#E60000 !important;}
/deep/.main-menu .el-menu.el-menu--horizontal,/deep/.main-menu .el-menu .is-active .el-submenu__title{border-bottom:none !important}
/* /deep/ .el-menu,.el-menu .el-submenu__title{background-color: #FE0000 !important;}
/deep/ .el-menu .el-submenu__title{background-color: #FE0000 !important;}
/deep/ .el-menu .el-menu-item {background-color: #FE0000 !important;}

/deep/ .el-menu--horizontal .el-menu .el-menu-item {background-color: #FE0000 !important;} */


.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>