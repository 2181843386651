<template>
  <div class="main-body">
    <div class="c-1200" style="margin-bottom:30px;">
      <div class="nav-box">
        <i class="fa fa-map-pin nav-icon"  aria-hidden="true"></i>
        <span class="nav-c">
          <!-- {{ getModuleName() }} -->
          我的
        </span>
        <span class="nav-g">/</span>
        <span class="nav-c">
          <!-- {{getTypeName()}} -->
          我的报名
        </span>
        <span class="nav-g">/</span>
        <span class="nav-z">
          {{ courseInfo.courseName}}
          <!-- 培训考试列表 -->
        </span>
      </div>
      <div style="background-color:#fff;padding:50px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;position: relative;">
      <el-button v-if="canSign"  type="primary" plain style="position:absolute;margin: 0 auto;margin-left:1000px;margin-top: 20px;height: 35px;line-height: 35px;padding: 0px 20px;" @click="goToSign">我要报名</el-button>
      <el-button v-else  type="info" plain style="position:absolute;margin: 0 auto;margin-left:1000px;margin-top: 20px;height: 35px;line-height: 35px;padding: 0px 20px;" @click="goSignList">我的报名</el-button>
      <div style="text-align:center">
        <p v-text="courseInfo.courseName"></p>
        <p style="color:#777;font-size:12px;"><dict-tag :options="dict.type.course_type" :value="courseInfo.courseType"/></p>
        <p style="color:#777;font-size:12px;">讲师：<span  v-text="courseInfo.teacher"></span></p>
        <p style="color:#777;font-size:12px;">开始时间：<span>{{ parseTime(courseInfo.startTime, '{y}-{m}-{d}') }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;结束时间：<span>{{ parseTime(courseInfo.endTime, '{y}-{m}-{d}') }}</span></p>
      </div>
      <div >
        <p style="color:#777;font-size:14px;" v-html="courseInfo.courseDetail"></p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getRecord } from "@/api/courses/record";
import { judgeSigned } from "@/api/courses/signRecord";

export default {
  name: "train_exam_list",
  dicts: ['course_type'],
  components: {},
  data() {
    return {
      courseId: null,
      courseInfo: {},
      signed: -1,
      canSign: true,
    };
  },
  created(){
    this.courseId = this.$route.query.courseId
    this.getDetail();
    this.judgeSigneds();
  },
  watch:{
  },
  methods: {
    getDetail(){
      getRecord(this.courseId).then(response => {
          this.courseInfo = response.data;
        });
    },
    goToSign(){
      if(this.$store.getters.loginState===0){
        this.$router.push({path: '/menu_page/train_exam_registration',query:{ courseId: this.courseId,module:6}});
      }else{
        this.$message.warning('您的申请还未审批通过!')
      }
    },
    goSignList(){
      //this.$router.push({path: '/menu_page/train_sign_list'});
      this.$router.push({path: '/menu_page/train_sign_detail',query:{ recordId: this.signed,module:6 }});
    },
    judgeSigneds(){
      judgeSigned(this.courseId).then(response => {
          this.signed = response.data;
          if(this.signed != -1){
            this.canSign = false;
          }else {
            this.canSign = true;
          }
          
        });

    }
  },
};
</script>
<!-- <style scoped lang="scss"> -->
<style scoped >

.flex{
  display:flex;
}
.block-title{
  margin-top:50px;
}
.block-content-line-item{flex:4}
/deep/ .block-content-line-item .el-select{width:100%;}
/deep/ .block-content-line .el-form-item__label{padding-left: 30px;}
/deep/ .bcli-lh18  .el-form-item__label{line-height: 18px;}
/* /deep/ .block-content-line-item .el-form-item__content{margin-left: 120px !important;} */
/deep/ .label-center .el-form-item__label{text-align: center;}
.label1{
  width: 150px;padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2{
  padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2 span{
  padding-left:5px;padding-right: 5px;text-decoration: underline;
}
</style>