<template>
    <div class="main-body" style="background-color:#fff !important;">
      <div>
      <!-- <div style="margin:auto;background-color:#fff;padding:20px 60px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;"> -->
        <el-table :data="certList">
            <el-table-column label="证书名称" align="left" prop="certificateName" />
            <el-table-column label="证书编号" align="left" prop="certificateNo" />
            <el-table-column label="证书类型" align="left" prop="certificateType">
            <template slot-scope="scope">
                <dict-tag :options="dict.type.certificate_type" :value="scope.row.certificateType"/>
            </template>
            </el-table-column>
            <el-table-column label="获得者姓名" align="left" prop="name" />
            <el-table-column label="获得者证件号" align="left" prop="idCard" />
            <el-table-column label="关联课程" align="left" prop="courseName" />
            <el-table-column label="证书获取日期" align="center" prop="certificateTime" width="180">
            <template slot-scope="scope">
                <span>{{ parseTime(scope.row.certificateTime, '{y}-{m}-{d}') }}</span>
            </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-add" @click="downCert(scope.row.certificate)">
                查看
            </el-button>
            </template>
        </el-table-column>
        </el-table>
        <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getMyCertList"
      />
      </div>
    </div>
  </template>
  
  <script>
  
  import {getMyCertList} from "@/api/login";
  export default {
    name: "my_cert",
    dicts: ['certificate_type','course_type','sign_status','sys_user_sex','needs','edu'],
    components: {},
    data() {
      return {
        loginId:"",
        loginPhone:"",
        loginName:"",
        loginType:"",        // 总条数
        total: 0,
        certList:[],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10
        }
      };
    },
    mounted() {
      this.loginId=this.$store.getters.loginId
      this.loginPhone=this.$store.getters.loginPhone
      this.loginName=this.$store.getters.loginName
      this.loginType=this.$store.getters.loginType
      this.getMyCertList();
    },
    watch:{
    },
    methods: {
      // 我的证书列表
      getMyCertList:function(){
        getMyCertList(this.queryParams).then(response => {
          this.certList=response.rows
          this.total = response.total;
        })
      },
      downCert:function(url){
        // window.location.href=url
        window.open(url);
      }
    },
  };
  </script>
  <style rel="stylesheet/scss" lang="css">
   .login-code{
    width: 100%;
    height: 38px;
    img {
      cursor: pointer;
      vertical-align: middle; 
    }
   }
   .login-code-img {
      height: 38px;
      /* margin-left: 10px; */
      width:100%;
    }
    .login-btn{
      height: 38px;
      width:120px;
      width:100%;
      /* margin-left: 10px; */
    }
    .el-link{
      font-size: 12px !important;
      line-height:14px;
      float:right;
      vertical-align: middle;
      margin-top:15px;
    }
  </style>