import request from '@/utils/request'
import md5 from '@/utils/md5'
import { isEmpty } from '@/utils/validator'
// 企业注册
export function customerRegister(data){
  if(!isEmpty(data.password)){
    data.loginPwd=md5.hex_md5(data.loginPwd)
  }
  return request({
    url: '/system/auth/customer/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 用户注册
export function userRegister(data){
  if(!isEmpty(data.password)){
    data.password=md5.hex_md5(data.password)
  }
  return request({
    url: '/system/auth/user/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}
// 枚举
export function getSystemEnum(data){
  return request({
    url: '/system/auth/enum',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 证书查询
export function getCertificateList(data){
  return request({
    url: '/system/auth/certificate/list',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 企业详情
export function getCustomerById(id){
  return request({
    url: '/system/customer/'+id,
    method: 'get'
  })
}

// 修改用户
export function userEdit(data){
  return request({
    url: '/system/customer/user',
    method: 'put',
    data
  })
}

// 用户详情
export function getCustomerUserById(id){
  return request({
    url: '/system/customer/user/'+id, 
    method: 'get'
  })
}

// 修改企业
export function customerEdit(data){
  return request({
    url: '/system/customer',
    method: 'put',
    data
  })
}
