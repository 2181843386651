import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// 定义路由
const routes = [{
  path: '/',
  component: Layout,
  redirect: '/menu_page/index',
  children:[
  ]
}]
const layouts = {}
const hasnolist = ['index']
const extConfig = {
  'regist':{
    headerName:'header-no-function'
  }
}
//初始化布局
initLayout()
// 导入路由
importPages(require.context('@/views/routerpage', true,/\.vue$/))
function importPages (r) {
  r.keys().forEach(key => {
    const file = (key.split('/'))
    file[0] = '/'
    const fullName = file[file.length-1].split('.')[0]
    const leftActive = (fullName.split('_'))[0]
    let children = routes
    for(let i=0;i<file.length-1;i++){
      children = getChildNode(file[i].split('.')[0],children)
    }
    children.push({
      path:fullName,
      component:() => import('@/views/routerpage' + key.split('.')[1]),
      meta: {
        keepAlive: (hasnolist.indexOf(fullName) < 0),
        leftActive: leftActive,
        queryParams: {},
        extConfig:extConfig[fullName]
      }
    })
  })
}

function getChildNode(name,parent){
  for(let i=0;i<parent.length;i++){
    if(parent[i].path == name){
      if(parent[i].children!=null){
        return parent[i].children
      }else{
        const children = []
        parent[i].children = children
        return children
      }
    }
  }
  const children = []
  if(layouts[name]!=null){
    parent.push({
      path:name,
      children:children,
      component:() => import('@/layout/' + name)
    })
  }else{
    parent.push({
      path:name,
      children:children,
      component:() => import('@/layout/default')
    })
  }
  return children
}
function initLayout(){
  let r = require.context('@/layout', true,/\.vue$/)
  r.keys().forEach(key => {
    let name = key.split('/')[1].split('.')[0]
    layouts[name] = '@/layout/' + name
  })
}
Vue.use(Router)
export default new Router({
  // mode: API_CONFIG.G_MODE,
  mode: 'history',
  // base: API_CONFIG.G_SERVERURL,
  routes: routes
})
