import request from '@/utils/request'

export function saveNews(data){
    return request({
      url: '/news/news/add',
      method: 'post',
      data
    })
  }
  export function editNews(data){
    return request({
      url: '/news/news/edit',
      method: 'post',
      data
    })
  }
export function newsList(data){
  return request({
    url: '/news/news/page/list',
    method: 'post',
    data
  })
}

export function deleteNews(data){
  return request({
    url: '/news/news/delete',
    method: 'post',
    data
  })
}

export function getNewsDetail(data){
  return request({
    url: '/news/news/detail',
    method: 'post',
    data
  })
}

/**
 * 所有类型列表
 * @param {*} data 
 */
export function getAllTypeList(data){
  return request({
    url: '/news/news/all/type/list',
    method: 'post',
    data
  })
}

/**
 * 获取分类详情
 * @param {*} data 
 */
export function getTypeDetail(data){
  return request({
    url: '/news/news/type/detail',
    method: 'post',
    data
  })
}

/**
 * 冻结门店广告
 * @param {*} data 
 */
export function frozenNews(data){
  return request({
    url: '/news/news/frozen',
    method: 'post',
    data
  })
}
/**
 * 解冻门店广告
 * @param {*} data 
 */
export function nufrozenNews(data){
  return request({
    url: '/news/news/nufrozen',
    method: 'post',
    data
  })
}
  /**
 * 设为热门或者取消热门
 * @param {*} data 
 */
export function changeNewsHot(data){
  return request({
    url: '/news/news/change/hot',
    method: 'post',
    data
  })
}

// 查询新闻管理列表
export function listRecord(query) {
  return request({
    url: '/display/news/list',
    headers: {
      isToken: false
    },
    method: 'get',
    params: query
  })
}

export function getInfo(id) {
  return request({
    url: '/display/news/' + id,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}

// 全文检索文章或者课程
export function searchAll(query) {
  return request({
    url: '/display/news/search',
    headers: {
      isToken: false
    },
    method: 'get',
    params: query
  })
}