<template>
  <div class="main-body" style="padding:10px">
    <div style="margin:60px auto;width:400px;background-color:#fff;padding:40px 60px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
      <div style="text-align:center;font-size: 20px;margin-bottom: 30px;">
            登　陆
          </div>
      <el-form :model="loginForm" class="login-form" style="width:100%;margin: auto;">
        <div style="margin:0px">
          <el-row>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-form-item>
                  <!-- <el-input v-model="loginForm.loginPhone" placeholder="请输入注册手机号（或企业可输入社信码）" maxlength="11"></el-input> -->
                  <el-input v-model="loginForm.loginPhone" placeholder="请输入注册手机号" maxlength="11"></el-input>
              </el-form-item>
              <!--tab1-->
              <el-tab-pane label="密码登录" name="first">
                <el-form-item>
                    <el-input v-model="loginForm.inputPwd" placeholder="请输入密码" type="password"></el-input>
                </el-form-item>
              </el-tab-pane>

              <el-form-item class="mb5">
                <el-row :gutter="20">
                  <el-col :span="16">
                      <el-input v-model="loginForm.picCode" placeholder="请输入验证码" class="code"></el-input>
                  </el-col>
                  <el-col :span="8">
                    <div class="login-code">
                      <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item> 

              <!--tab2-->
              <el-tab-pane label="手机登录" name="second">
                <el-form-item>
                  <el-row :gutter="20">
                    <el-col :span="16">
                      <el-input v-model="loginForm.smsCode" placeholder="请输入短信码"></el-input>
                    </el-col>
                    <el-col :span="8">
                      <el-button type="default" class="login-btn" v-if="sendMessageState=='0'" @click="sendLoginCode" style="width:100%;">{{sendBtnMessage}}</el-button>
                      <el-button type="default" class="login-btn" v-if="sendMessageState=='1'"  style="width:100%;">{{sendBtnMessage}}</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-tab-pane>
              <el-form-item style="line-height:12px !important;">
                  <el-radio v-model="loginForm.type" label="1">个人登录</el-radio>
                  <el-radio v-model="loginForm.type" label="2">企业登录</el-radio>
                  <el-link type="primary" @click="findPwd">忘记密码</el-link>
              </el-form-item>
            </el-tabs>  
          </el-row>
          <el-row style="text-align:center">
                <el-button type="primary" class="login-btn" @click="login">登录</el-button>
                <!-- <el-button type="default" class="login-btn" @click="findPwd"  style="margin-left:50px">找回密码</el-button> -->
          </el-row>
          <br/>
          <el-row >
                <el-link type="info" style="float:left;font-size:13px !important" @click="register_user">
                  个人注册
                </el-link>
                <el-link type="info" style="float:left;font-size:13px !important;margin-left:30px" @click="register_customer">
                  企业注册
                </el-link>
              </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getCodeImg,webLogin,sendMsg } from "@/api/login";
import {validatePhone} from '@/utils/validator'
import {setToken} from '@/utils/auth'
  export default {
  name: "nemu_login",
    data() {
      return {
        activeName: 'first',
        codeUrl: "",
        // 验证码开关
        captchaOnOff: true,
        loginForm:{
            type:"1",
            loginMode:"1"
        },
        sendMessageState:'0',
        sendBtnMessage:"发送验证码"
      };
    },
    mounted() {
      // 验证码
      this.getCode();
    },
    methods: {
      // tab
      handleClick(tab, event) {
        console.log(tab, event);
      },
      // 图形验证码
      getCode() {
        getCodeImg().then(res => {
          this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
          if (this.captchaOnOff) {
            this.codeUrl = "data:image/gif;base64," + res.img;
            this.loginForm.uuid = res.uuid;
          }
        });
      },
      // 登录
      login:function(){
        if(this.loginForm.loginPhone == null){
            this.$message.warning('登录手机号未填写!')
            return
        }
        if(this.activeName=='first'){
          if(this.loginForm.inputPwd == null){
            this.$message.warning('密码未填写!')
            return
          }
          if(this.loginForm.picCode == null){
            this.$message.warning('图形验证码未填写!')
            return
          }
          this.loginForm.loginMode='1'
        }
        if(this.activeName=='second'){
          if(this.loginForm.smsCode == null){
            this.$message.warning('短信验证码未填写!')
            return
          }
          this.loginForm.loginMode='2'
        }
        webLogin(this.loginForm).then(response => {
            const token = response.token
            setToken(token)
            this.$message.success('登录成功!')
            this.getCode();
            this.$store.dispatch("user/login",response.loginInfo)
            localStorage.setItem("loginInfo",JSON.stringify(response.loginInfo))
            if(response.loginInfo.state===-2){
            this.$alert('您的申请未审批通过，请重新申请！', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                  callback: action => {
                    if (action) {
                      if(response.loginInfo.type===1){
                        window.location.href='/menu_page/customer/user_edit?id='+ this.$store.getters.loginId
                       }else{
                        window.location.href='/menu_page/customer/customer_edit?id='+this.$store.getters.loginId;
                      }
                    }
                  }
                });
            }else{
              window.location.href='/menu_page/index?module='+0
            }
        })
    },
    // 短信验证码
    sendLoginCode(){
        const mobilePhone = this.loginForm.loginPhone
        if(mobilePhone==null || !validatePhone(mobilePhone)){
            this.$message.warning('手机号为空或不合法')
            return
        }
        if(this.activeName=='second'){
          if(this.loginForm.picCode == null){
            this.$message.warning('图形验证码未填写!')
            return
          }
        }
        const data = {
          loginPhone:mobilePhone,
          picCode:this.loginForm.picCode,
          uuid:this.loginForm.uuid
        }
        sendMsg(data).then(response => {
            console.log(response)
            this.$message.success('验证码已发送')
            this.getCode();
            this.loginForm.smsUuid=response.smsUuid
            this.sendMessageState='1'
            let count = 60
            const secondCount = setInterval(()=>{
                if(count>0){
                    this.sendMessageState='1'
                    this.sendBtnMessage = count + 's'
                    count--
                }else{
                    this.sendMessageState='0'
                    this.sendBtnMessage = '发送验证码'
                    window.clearInterval(secondCount)
                }
            },1000)
        })
      },
      findPwd(){
        this.$router.push({
          path:'/menu_page/sys/find_password'
        })
      },
      register_customer(){
        this.$router.push({
          path:'/menu_page/customer/customer_edit'
        })
      },
      register_user(){
        this.$router.push({
          path:'/menu_page/customer/user_edit'
        })
      },
    }
  };
</script>
<style rel="stylesheet/scss" lang="css">
 .login-code{
  width: 100%;
  height: 38px;
  img {
    cursor: pointer;
    vertical-align: middle; 
  }
 }
 .login-code-img {
    height: 38px;
    /* margin-left: 10px; */
    width:100%;
  }
  .login-btn{
    height: 38px;
    width:120px;
    width:100%;
    /* margin-left: 10px; */
  }
  .el-link{
    font-size: 12px !important;
    line-height:14px;
    float:right;
    vertical-align: middle;
    margin-top:15px;
  }
</style>