<template>
  <div class="main-body" style="padding:10px">
    <div style="margin:auto;background-color:#fff;padding:20px 60px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
      <el-form ref="queryForm" :model="queryForm" :rules="queryRules" class="login-form" style="width:50%;margin: auto;">
        <div style="margin:0px">
          <el-row>
             <el-form-item style="line-height:12px !important;">
                  <el-radio v-model="queryForm.type" label="1">证书编号</el-radio>
                  <el-radio v-model="queryForm.type" label="2">身份证号</el-radio>
              </el-form-item>
              <el-form-item prop="keyWork">
                <el-row :gutter="20">
                  <el-col :span="20">
                    <el-input v-model="queryForm.keyWork" placeholder="请输入证书编号或身份证号"></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="primary" class="login-btn" @click="queryCertificate">查询</el-button>
                  </el-col>
                </el-row>
            </el-form-item>
          </el-row>
        </div>
      </el-form>
      <el-table :data="certList" style="width:50%;margin: auto;">
        <el-table-column label="证书名称" align="left" prop="certificateName" />
        <el-table-column label="证书编号" align="left" prop="certificateNo" />
        <el-table-column label="获得者姓名" align="left" prop="name" />
        <el-table-column label="获得者证件号" align="left" prop="idCard" />
      </el-table>
    </div>
  </div>
</template>
<script>
import {getCertificateList } from "@/api/register";
export default {
  name: "certificate_query",
  components: {},
  data() {
    return {
      queryForm:{
        type:'1'
      },
      certList:[],
      queryRules: {
        keyWork: [
          { required: true, message: "请输入证书编号或身份证号", trigger: "blur"}
        ],
      }
    };
  },
  mounted() {

  },
  watch:{
  },
  methods: {
    queryCertificate(){
      this.$refs["queryForm"].validate(valid => {
        if (valid) {
          if(this.queryForm.type==='1'){
            this.queryForm.idCard= null
            this.queryForm.certificateNo= this.queryForm.keyWork
          }
          if(this.queryForm.type==='2'){
            this.queryForm.certificateNo=null
            this.queryForm.idCard= this.queryForm.keyWork
          }
          getCertificateList(this.queryForm).then(response => {
              this.certList=response.list
          })
        }
      });
    }
  },
};
</script>
<style rel="stylesheet/scss" lang="css">
 .login-code{
  width: 100%;
  height: 38px;
  img {
    cursor: pointer;
    vertical-align: middle; 
  }
 }
 .login-code-img {
    height: 38px;
    /* margin-left: 10px; */
    width:100%;
  }
  .login-btn{
    height: 38px;
    width:120px;
    width:100%;
    /* margin-left: 10px; */
  }
  .el-link{
    font-size: 12px !important;
    line-height:14px;
    float:right;
    vertical-align: middle;
    margin-top:15px;
  }
</style>