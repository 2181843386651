/*是否合法IP地址*/
export function validateIP( value ) {
    if(value==''||value==undefined||value==null){
      return false;
    }else {
      const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      if ((!reg.test(value)) && value != '') {
        return false;
      } else {
         return true;
      }
    }
  }
  
  /* 是否手机号码或者固话*/
  export function validatePhoneTwo( value ) {
    const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
    if (value == '' || value == undefined || value == null) {
      return false;
    } else {
      if ((!reg.test(value)) && value != '') {
        return false;
      } else {
         return true;
      }
    }
  }
  /* 是否固话*/
  export function validateTelphone( value ) {
    const reg =/0\d{2}-\d{7,8}/;
    if(value==''||value==undefined||value==null){
      return false;
    }else {
      if ((!reg.test(value)) && value != '') {
        return false;
      } else {
         return true;
      }
    }
  }
  /* 是否手机号码*/
  export function validatePhone( value ) {
    const reg =/^[1][3,4,5,7,8][0-9]{9}$/;
    if(value==''||value==undefined||value==null){
      return false;
    }else {
      if ((!reg.test(value)) && value != '') {
        return false;
      } else {
         return true;
      }
    }
  }
  /* 是否身份证号码*/
  export function validateIdNo( value ) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if(value==''||value==undefined||value==null){
      return false;
    }else {
      if ((!reg.test(value)) && value != '') {
        return false;
      } else {
         return true;
      }
    }
  }
  /* 是否邮箱*/
  export function validateEMail( value ) {
    const reg =/^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
    if(value==''||value==undefined||value==null){
      return false;
    }else{
      if (!reg.test(value)){
        return false;
      } else {
         return true;
      }
    }
  }
  /* 合法uri*/
  export function validateURL(textval) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(textval);
  }
  
  /*验证内容是否英文数字以及下划线*/
  export function isPassword( value ) {
    const reg =/^[_a-zA-Z0-9]+$/;
    if(value==''||value==undefined||value==null){
      return false;
    } else {
      if (!reg.test(value)){
        return false;
      } else {
         return true;
      }
    }
  }
  
  /*自动检验数值的范围*/
  export function checkMax20000( value ) {
    if (value == '' || value == undefined || value == null) {
      return false;
    } else if (!Number(value)) {
      return false;
    } else if (value < 1 || value > 20000) {
      return false;
    } else {
       return true;
    }
  }
  
  //验证数字输入框最大数值,32767
  export function checkMaxVal( value ) {
    if (value < 0 || value > 32767) {
      return false;
    } else {
       return true;
    }
  }
  //验证是否1-99之间
  export function isOneToNinetyNine( value ) {
    if (!value) {
      return false;
    }
    setTimeout(() => {
      if (!Number(value)) {
        return false;
      } else {
        const re = /^[1-9][0-9]{0,1}$/;
        const rsCheck = re.test(value);
        if (!rsCheck) {
          return false;
        } else {
           return true;
        }
      }
    }, 0);
  }
  
  // 验证是否整数
  export function isInteger( value ) {
    if (!value) {
      return false;
    }
    setTimeout(() => {
      if (!Number(value)) {
        callback(new Error('请输入正整数'));
      } else {
        const re = /^[0-9]*[1-9][0-9]*$/;
        const rsCheck = re.test(value);
        if (!rsCheck) {
          return false;
        } else {
           return true;
        }
      }
    }, 0);
  }
  // 验证是否整数,非必填
  export function isIntegerNotMust( value ) {
    if (!value) {
      return false;
    }
    setTimeout(() => {
      if (!Number(value)) {
        return false;
      } else {
        const re = /^[0-9]*[1-9][0-9]*$/;
        const rsCheck = re.test(value);
        if (!rsCheck) {
          callback(new Error('请输入正整数'));
        } else {
           return true;
        }
      }
    }, 1000);
  }
  
  // 验证是否是[0-1]的小数
  export function isDecimal( value ) {
    if (!value) {
      return false;
    }
    setTimeout(() => {
      if (!Number(value)) {
        return false;
      } else {
        if (value < 0 || value > 1) {
          return false;
        } else {
           return true;
        }
      }
    }, 100);
  }
  
  // 验证是否是[1-10]的小数,即不可以等于0
  export function isBtnOneToTen( value ) {
    if (typeof value == 'undefined') {
      return false;
    }
    setTimeout(() => {
      if (!Number(value)) {
        return false;
      } else {
        if (!(value == '1' || value == '2' || value == '3' || value == '4' || value == '5' || value == '6' || value == '7' || value == '8' || value == '9' || value == '10')) {
          return false;
        } else {
           return true;
        }
      }
    }, 100);
  }
  // 验证是否是[1-100]的小数,即不可以等于0
  export function isBtnOneToHundred( value ) {
    if (!value) {
      return false;
    }
    setTimeout(() => {
      if (!Number(value)) {
        return false;
      } else {
        if (value < 1 || value > 100) {
          return false;
        } else {
           return true;
        }
      }
    }, 100);
  }
  // 验证是否是[0-100]的小数
  export function isBtnZeroToHundred( value ) {
    if (!value) {
      return false;
    }
    setTimeout(() => {
      if (!Number(value)) {
        return false;
      } else {
        if (value < 0 || value > 100) {
          return false;
        } else {
           return true;
        }
      }
    }, 100);
  }
  
  // 验证端口是否在[0,65535]之间
  export function isPort( value ) {
    if (!value) {
      return false;
    }
    setTimeout(() => {
      if (value == '' || typeof(value) == undefined) {
        return false;
      } else {
        const re = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
        const rsCheck = re.test(value);
        if (!rsCheck) {
          return false;
        } else {
           return true;
        }
      }
    }, 100);
  }
  // 验证端口是否在[0,65535]之间，非必填,isMust表示是否必填
  export function isCheckPort( value ) {
    if (!value) {
       return true;
    }
    setTimeout(() => {
      if (value == '' || typeof(value) == undefined) {
        return false;
      } else {
        const re = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
        const rsCheck = re.test(value);
        if (!rsCheck) {
          return false;
        } else {
           return true;
        }
      }
    }, 100);
  }
  
  /* 小写字母*/
  export function validateLowerCase(str) {
    const reg = /^[a-z]+$/;
    return reg.test(str);
  }
  /* 大写字母*/
  export function validateUpperCase(str) {
    const reg = /^[A-Z]+$/;
    return reg.test(str);
  }
  
  /* 大小写字母*/
  export function validatAlphabets(str) {
    const reg = /^[A-Za-z]+$/;
    return reg.test(str);
  }
  
  //验证是否为空
  export function isEmpty(value) {// 验证是否 空
    if (value == null || value == '' || value.length < 1 || value == "undefined")
    {
      return true;
    }else {
      return false;
    }
  }
  
  export function isNumber(value) {
    if(value == null || value == '' || value.length < 1 || value == "undefined"){
      return false
    }
    var numReg = /^[0-9]+$/
    var numRe = new RegExp(numReg)
  
    return !numRe.test(value)
  }

  /**
 * 所占字节数
 *
 * UTF-8 一种可变长度的Unicode编码格式，使用一至四个字节为每个字符编码（Unicode在范围 D800-DFFF 中不存在任何字符）
 * 000000 - 00007F(128个代码)      0zzzzzzz(00-7F)                             一个字节
 * 000080 - 0007FF(1920个代码)     110yyyyy(C0-DF) 10zzzzzz(80-BF)             两个字节
 * 000800 - 00D7FF
 * 00E000 - 00FFFF(61440个代码)    1110xxxx(E0-EF) 10yyyyyy 10zzzzzz           三个字节
 * 010000 - 10FFFF(1048576个代码)  11110www(F0-F7) 10xxxxxx 10yyyyyy 10zzzzzz  四个字节
 * {@link https://zh.wikipedia.org/wiki/UTF-8}
 *
 * UTF-16 编码65535以内使用两个字节编码，超出65535的使用四个字节（JS内部，字符储存格式是：UCS-2——UTF-16的子级）
 * 000000 - 00FFFF  两个字节
 * 010000 - 10FFFF  四个字节
 * {@link https://zh.wikipedia.org/wiki/UTF-16}
 *
 * GBK(ASCII的中文扩展) 除了0~126编号是1个字节之外，其他都2个字节（超过65535会由2个字显示）
 * {@link https://zh.wikipedia.org/wiki/汉字内码扩展规范}
 *
 * @param  {String} str
 * @param  {String} [charset= 'gbk'] utf-8, utf-16
 * @return {Number}
 */
export function sizeofByte (str, charset = 'utf-8') {
  let total = 0
  let charCode

  charset = charset.toLowerCase()

  if (charset === 'utf-8' || charset === 'utf8') {
    for (let i = 0, len = str.length; i < len; i++) {
      charCode = str.codePointAt(i)

      if (charCode <= 0x007f) {
        total += 1
      } else if (charCode <= 0x07ff) {
        total += 2
      } else if (charCode <= 0xffff) {
        total += 3
      } else {
        total += 4
        i++
      }
    }
  } else if (charset === 'utf-16' || charset === 'utf16') {
    for (let i = 0, len = str.length; i < len; i++) {
      charCode = str.codePointAt(i)

      if (charCode <= 0xffff) {
        total += 2
      } else {
        total += 4
        i++
      }
    }
  } else {
    total = str.replace(/[^\x00-\xff]/g, 'aa').length
  }

  return total
}
  