import request from '@/utils/request'

// 查询课程信息列表
export function listRecord(query) {
  return request({
    url: '/courses/list',
    method: 'get',
    params: query
  })
}

// 查询课程信息详细
export function getRecord(id) {
  return request({
    url: '/courses/' + id,
    method: 'get'
  })
}


export function getAllRecord(id) {
  return request({
    url: '/courses/all/' + id,
    method: 'get'
  })
}