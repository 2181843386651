<template>
  <div class="main-body" style="padding:10px">
    <div style="margin:60px auto;width:400px;background-color:#fff;padding:40px 60px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
      <el-form :model="resetPasswordForm" class="login-form" style="width:100%;margin: auto;">
        <div style="margin:0px">
          <div style="text-align:center;font-size: 20px;margin-bottom: 30px;">
            密码找回
          </div>
          <!-- <el-form-item>
              <el-input v-model="resetPasswordForm.loginPhone" placeholder="请输入手机" readonly maxlength="11"></el-input>
          </el-form-item> -->
          <el-form-item>
              <el-input v-model="resetPasswordForm.oldPassword" placeholder="请输入旧密码"  type="password"></el-input>
          </el-form-item>
          <!-- <el-form-item class="mb5">
            <el-row :gutter="20">
              <el-col :span="16">
                  <el-input v-model="resetPasswordForm.picCode" placeholder="请输入验证码" class="code"></el-input>
              </el-col>
              <el-col :span="8">
                <div class="login-code">
                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                </div>
              </el-col>
            </el-row>
          </el-form-item>  -->
          <!-- <el-form-item>
            <el-row  :gutter="20">
              <el-col :span="16">
                <el-input v-model="resetPasswordForm.smsCode" placeholder="请输入短信码"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button type="default" class="login-btn" v-if="sendMessageState=='0'" @click="sendLoginCode" >{{sendBtnMessage}}</el-button>
                <el-button type="default" class="login-btn" v-if="sendMessageState=='1'" >{{sendBtnMessage}}</el-button>
              </el-col>
            </el-row>
          </el-form-item> -->
          <el-form-item>
              <el-col>
                <el-input v-model="resetPasswordForm.password" placeholder="请输入新密码" type="password"></el-input>
              </el-col>
          </el-form-item>
          <el-form-item>
              <el-col>
                <el-input v-model="resetPasswordForm.rePassword" placeholder="请再次输入新密码" type="password"></el-input>
              </el-col>
          </el-form-item>
          <el-button type="primary" class="login-btn" @click="confirmResetPwd" >确定</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getCodeImg,resetPassword,sendMsg } from "@/api/login";
import {validatePhone} from '@/utils/validator'
  export default {
    data() {
      return {
        codeUrl: "",
        // 验证码开关
        captchaOnOff: true,
        resetPasswordForm:{
          type:"1"
        },
        sendMessageState:'0',
        sendBtnMessage:"发送验证码"
      };
    },
    mounted() {
      this.resetPasswordForm.loginPhone=this.$store.getters.loginPhone
      // 验证码
      this.getCode();
    },
    methods: {
      // 图形验证码
      getCode() {
        getCodeImg().then(res => {
          this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
          if (this.captchaOnOff) {
            this.codeUrl = "data:image/gif;base64," + res.img;
            this.resetPasswordForm.uuid = res.uuid;
          }
        });
      },
      // 登录
      confirmResetPwd:function(){
        if(this.resetPasswordForm.loginPhone == null){
            this.$message.warning('登录手机号未填写!')
            return
        }
        // if(this.resetPasswordForm.picCode == null){
        //   this.$message.warning('图形验证码未填写!')
        //   return
        // }
        // if(this.resetPasswordForm.smsCode == null){
        //   this.$message.warning('短信验证码未填写!')
        //   return
        // }
        if(this.resetPasswordForm.oldPassword == null){
          this.$message.warning('新密码未填写!')
          return
        }
        if(this.resetPasswordForm.password == null){
          this.$message.warning('新密码未填写!')
          return
        }
        if(this.resetPasswordForm.rePassword == null){
          this.$message.warning('再次新密码未填写!')
          return
        }
        if(this.resetPasswordForm.rePassword != this.resetPasswordForm.password){
          this.$message.warning('密码填写不一致!')
          return
        }
        resetPassword(this.resetPasswordForm).then(response => {
            this.$message.success('修改成功!')
            this.$router.push({
              path:'/menu_page/sys/login'
            })
        })
    },
    // 短信验证码
    sendLoginCode(){
        const mobilePhone = this.resetPasswordForm.loginPhone
        if(mobilePhone==null || !validatePhone(mobilePhone)){
            this.$message.warning('手机号为空或不合法')
            return
        }
        if(this.activeName=='second'){
          if(this.resetPasswordForm.picCode == null){
            this.$message.warning('图形验证码未填写!')
            return
          }
        }
        const data = {
          loginPhone:mobilePhone,
          picCode:this.resetPasswordForm.picCode,
          uuid:this.resetPasswordForm.uuid
        }
        sendMsg(data).then(response => {
            console.log(response)
            this.$message.success('验证码已发送')
            this.getCode();
            this.resetPasswordForm.smsUuid=response.smsUuid
            this.sendMessageState='1'
            let count = 60
            const secondCount = setInterval(()=>{
                if(count>0){
                    this.sendMessageState='1'
                    this.sendBtnMessage = count + 's'
                    count--
                }else{
                    this.sendMessageState='0'
                    this.sendBtnMessage = '发送验证码'
                    window.clearInterval(secondCount)
                }
            },1000)
        })
    }
    }
  };
</script>
<style rel="stylesheet/scss" lang="css">
.login-code{
 width: 100%;
 height: 38px;
 img {
   cursor: pointer;
   vertical-align: middle; 
 }
}
.login-code-img {
   height: 38px;
   /* margin-left: 10px; */
   width:100%;
 }
  .login-btn{
    height: 38px;
    width:120px;
    width:100%;
    /* margin-left: 10px; */
  }
</style>