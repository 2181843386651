<template>
    <div>
        <link rel ="shortcut icon" type="image/x-icon" href="static/favicon.ico"></link>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name:'layout-index',
    data(){
        return {
            
        }
    },
    methods:{

    },
    watch: {
    //   $route(to){
    //       console.log(to)
    //       if(to.path.indexOf('enterprise_store')>0){
    //           console.log("--------------------")
    //       }
    //   }
    }
}
</script>
<style>
</style>