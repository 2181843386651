<template>
  <div class="main-body">
    <div class="c-1200" style="margin:0 auto;">
      
    </div>
  </div>
</template>

<script>

export default {
  name: "news_list",
  components: {},
  data() {
    return {
    };
  },
  created(){
  },
  watch:{
  },
  methods: {
  },
};
</script>
<!-- <style scoped lang="scss"> -->
<style scoped >
</style>