<template>
  <div>
      登录信息：<div>{{loginId}},{{loginName}},{{loginPhone}},{{loginType}}</div>
      <el-button type="default" class="login-btn" @click="logout" >登出</el-button>
      <el-button type="default" class="login-btn" @click="resetPassword" >修改密码</el-button>
  </div>
</template>

<script>

import { webLogout,getCurrentLoginInfo, resetPassword } from "@/api/login";
import { webRegister } from "@/api/register";
import {removeToken} from '@/utils/auth'
export default {
  name: "routerpage-index",
  components: {},
  data() {
    return {
			loginPhone:"",
      loginName:"",
      loginType:""
    };
  },
  mounted() {
      this.loginId=this.$store.getters.loginId
      this.loginPhone=this.$store.getters.loginPhone
      this.loginName=this.$store.getters.loginName
      this.loginType=this.$store.getters.loginType
      this.getCurrentLoginInfo();
  },
  watch:{
  },
  methods: {
    // 登出
    logout:function(){
        webLogout().then(response => {
            this.$message.success('登出成功!')
            this.$store.dispatch("user/logout")
            // token清空
            removeToken()
            localStorage.removeItem("loginInfo")
            // 跳转到登录页
            this.$router.push({
              path:'/menu_page/sys/login'
            })
        })
    },
    // 查询登录信息
    getCurrentLoginInfo:function(){
      getCurrentLoginInfo().then(response => {
        console.log(response)
      })
    },
    resetPassword:function(){
      // 跳转到登录页
      this.$router.push({
        path:'/menu_page/sys/reset_password'
      })
    }
  },
};
</script>
<style scoped lang="scss">
</style>