<template>
  <div class="main-body" >
    <img src="@/assets/img/banner-sq.png" height="" width="100%" style="min-width:1200px">
    <div class="c-1200" style="position:relative;border-radius: 6px;margin-bottom: 50px;">

      <div style="position:relative;margin-top:-126px;">
      <el-form ref="registrationInfo" :model="registrationInfo" :rules="registRules" label-width="150px" size="small">
      <div class="block" >
        <div style="text-align:center;padding:20px;height:80px;">
        <!-- <div style="text-align:center;border-bottom: 1px dashed #E60000;padding:20px;height:80px;"> -->
          <img src="@/assets/img/title-dwsq.png" height="80px" width="">
        </div>
        <hr style="width:95%;text-align:center;border-top:2px dotted #E60000;">



  <!-- <div class="main-body" style="width:1200px;margin:0 auto;padding-bottom:50px">
    <div style="text-align:center">
      单位会员申请
    </div>
    <div> 
      <el-form ref="registrationInfo" :model="registrationInfo" :rules="registRules" label-width="150px" size="small">
        <div class="block">-->
        <div class="block-title"></div>
        <div class="block-content"   style="padding:0px 40px 10px 0px" >
          <div class="block-content-line flex" v-if="registrationInfo.id==null || registrationInfo.id==''">
            <div class="block-content-line-item">
              <el-form-item label="注册手机号" prop="loginPhone">
                <el-input v-model="registrationInfo.loginPhone" clearable class="e-input" placeholder="请输入注册手机号" />
                <span style="font-size:12px;" class="lh18">（ * 注册手机号必须没有在本系统中注册过）</span>
              </el-form-item>
            </div>
          </div>
          <div class="block-content-line flex" v-if="registrationInfo.id!=null && registrationInfo.id!=''">
            <div class="block-content-line-item">
              <el-form-item label="注册手机号" prop="loginPhone">
                <el-input v-model="registrationInfo.loginPhone" clearable class="e-input" readonly="true" placeholder="请输入注册手机号" />
              </el-form-item>
            </div>
          </div>
          <div class="block-content-line flex" v-if="registrationInfo.id==null || registrationInfo.id==''">
            <div class="block-content-line-item">
              <el-form-item label="登陆密码" prop="loginPwd">
                <el-input v-model="registrationInfo.loginPwd" type="password" clearable class="e-input" placeholder="请输入登陆密码" />
                <span style="font-size:12px;" class="lh18">（ * 注册密码必须同时拥有字母和数字）</span>
              </el-form-item>
            </div>
            <div class="block-content-line-item">
              <el-form-item label="再次输入密码" prop="rePassword">
                <el-input v-model="registrationInfo.rePassword" type="password" clearable class="e-input" placeholder="请再次输入密码" />
              </el-form-item>
            </div>
          </div>
          <div class="block-content-line flex">
            <div class="block-content-line-item">
              <el-form-item label="图形码" prop="picCode">
                <el-col :span="19">
                  <el-input v-model="registrationInfo.picCode" clearable class="e-input" placeholder="请输入图形码" />
                </el-col>
              <el-col :span="5">
                <div class="login-code">
                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                </div>
              </el-col>
              </el-form-item>
            </div>
            <div class="block-content-line-item">
              <el-form-item label="短信码" prop="smsCode">
                <el-col :span="19">
                  <el-input v-model="registrationInfo.smsCode" clearable class="e-input" placeholder="请输入短信码" />
                </el-col>
              <el-col :span="5">
                <el-button type="default" class="login-btn" v-if="sendMessageState=='0'" @click="sendLoginCode" >{{sendBtnMessage}}</el-button>
                <el-button type="default" class="login-btn" v-if="sendMessageState=='1'" >{{sendBtnMessage}}</el-button>
              </el-col>
              </el-form-item>
            </div>
          </div>
        </div>

      <!-- </div> -->
        <!-- <hr style="width:95%;text-align:center;border-top:2px dotted #E60000;"> -->
        <!-- <div class="block"> -->
          <div class="block-title">
            <!-- 企业信息 -->
          </div>
          <div class="block-content"    style="padding:0px 40px 10px 0px">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="单位名称" prop="company">
                  <el-input v-model="registrationInfo.company" clearable class="e-input" placeholder="请输入单位名称" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="单位统一信用代码" prop="creditCode" class="bcli-lh18 pd-60">
                  <el-input v-model="registrationInfo.creditCode" clearable class="e-input" placeholder="请输入单位统一信用代码" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="注册资金" prop="registeredCapital">
                  <el-input v-model="registrationInfo.registeredCapital" clearable class="e-input" placeholder="请输入注册资金" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="注册地址" prop="registeredAddress">
                  <el-input v-model="registrationInfo.registeredAddress" clearable class="e-input" placeholder="请输入注册地址" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="成立日期" prop="registeredDate">
                  <el-date-picker
                    v-model="registrationInfo.registeredDate"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="block-content-line-item"></div>
            </div>

            <div class="block-content-line">
              <div class="block-content-line-item">
                <el-form-item label="营业执照" prop="licensePic">
                  <el-upload
                    :class="{hideUpload: licensePicHideUpload}"
                    action="/api/system/auth/upload"
                    :limit="1"
                    list-type="picture-card"
                    :before-upload="licensePicUploadBefore"
                    :on-success="licensePicUploadSuccess"
                    :on-remove="licensePicRemove"
                    :on-preview="licensePicPreview"
                    :on-exceed="exceedImage"
                    :file-list="licensePicList"
                    ref="dmtLicensePic"
                    >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <!--弹框展示图片-->
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <!-- <hr style="width:95%;text-align:center;border-top:2px dotted #E60000;"> -->
        <div class="block">

          <div class="block-title">
            <!-- 登记相关 -->
          </div>
          <div class="block-content"  style="padding:0px 40px 10px 0px">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="纳税信用等级" prop="companyTaxRate">
                  <el-input v-model="registrationInfo.companyTaxRate" clearable class="e-input" placeholder="请输入纳税信用等级" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="所属市州" prop="companyCity">
                  <el-input v-model="registrationInfo.companyCity" clearable class="e-input" placeholder="请输入所属市州" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="单位性质" prop="companyProperties">
                  <el-select v-model="registrationInfo.companyProperties" placeholder="请选择">
                    <el-option
                      v-for="item in unityTypeEnum"
                      :key="item.code"
                      :label="item.info"
                      :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="行业类型" prop="companyIndustryType">
                  <el-input v-model="registrationInfo.companyIndustryType" clearable class="e-input" placeholder="请输入行业类型" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="职工人数" prop="companyEmployees">
                  <el-input v-model="registrationInfo.companyEmployees" clearable class="e-input" placeholder="请输入行业类型" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="是否建立研发中心" prop="companyDevCentre" class="bcli-lh18 pd-60">
                  <el-select v-model="registrationInfo.companyDevCentre" placeholder="请选择">
                    <el-option
                      v-for="item in companyDevCentreEnum"
                      :key="item.code"
                      :label="item.info"
                      :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="是否成立党组织" prop="companySetParty">
                  <el-select v-model="registrationInfo.companySetParty" placeholder="请选择">
                    <el-option
                      v-for="item in companySetPartyEnum"
                      :key="item.code"
                      :label="item.info"
                      :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="党员数量" prop="companyPartyNum">
                  <el-input v-model="registrationInfo.companyPartyNum" clearable class="e-input" placeholder="请输入行业类型" />
                </el-form-item>
              </div>
            </div>
            <el-row>
            <div class="block-content-line">
              <div class="block-content-line-item">
                <el-form-item label="国家级品酒师人总数" prop="wineTasterLevelTotal" class="bcli-lh18 pd-40">
                  <el-input v-model="registrationInfo.wineTasterLevelTotal" clearable class="e-input" placeholder="请输入国家级品酒师人总数" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <label class="label1">国家级品酒师人数</label>
                <label class="label2">
                  <el-row class="pjsss">
                    <el-col :span="6">
                      <el-form-item label="一级" prop="wineTasterLevelOne">
                        <el-input v-model="registrationInfo.wineTasterLevelOne" clearable class="e-input" placeholder="" style="width:60px;margin-bottom:18px;"/> 人
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="二级" prop="wineTasterLevelTwo">
                        <el-input v-model="registrationInfo.wineTasterLevelTwo" clearable class="e-input" placeholder="" style="width:60px;margin-bottom:18px;"/> 人
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="三级" prop="wineTasterLevelThree">
                        <el-input v-model="registrationInfo.wineTasterLevelThree" clearable class="e-input" placeholder="" style="width:60px;margin-bottom:18px;"/> 人
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="四级" prop="wineTasterLevelFour">
                        <el-input v-model="registrationInfo.wineTasterLevelFour" clearable class="e-input" placeholder="" style="width:60px;margin-bottom:18px;"/> 人
                      </el-form-item>
                    </el-col>
                  </el-row>
                </label>
              </div>
            </div>
            </el-row>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="国家级白酒评委人数" prop="wineJudgesCountryNum" class="bcli-lh18">
                  <el-input v-model="registrationInfo.wineJudgesCountryNum" clearable class="e-input" placeholder="请输入国家级白酒评委人数" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="省级白酒评委人数" prop="wineJudgesProvinceNum" class="bcli-lh18 pd-40">
                  <el-input v-model="registrationInfo.wineJudgesProvinceNum" clearable class="e-input" placeholder="请输入省级白酒评委人数" />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- <hr style="width:95%;text-align:center;border-top:2px dotted #E60000;"> -->
        <div class="block">
          <div class="block-title">
            <!-- 企业代表信息 -->
          </div>
          <div class="block-content"  style="padding:0px 40px 10px 0px">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="单位法定代表人或委托代表人" prop="legalPersonName" class="bcli-lh18">
                  <el-input v-model="registrationInfo.legalPersonName" clearable class="e-input" placeholder="请输入单位法定代表人或委托代表人" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="性别" prop="legalPersonSex">
                    <el-select v-model="registrationInfo.legalPersonSex" placeholder="请选择">
                      <el-option
                        v-for="item in sexEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="证件类型" prop="legalPersonCertType">
                    <el-select v-model="registrationInfo.legalPersonCertType" placeholder="请选择">
                      <el-option
                        v-for="item in certTypeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="证件号码" prop="legalPersonCertNum">
                  <el-input v-model="registrationInfo.legalPersonCertNum" clearable class="e-input" placeholder="请输入证件号码" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="出生年月" prop="legalPersonBirthday">
                  <el-date-picker
                    v-model="registrationInfo.legalPersonBirthday"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="申请类别" prop="legalPersonCommunityJob">
                    <el-select v-model="registrationInfo.legalPersonCommunityJob" placeholder="请选择">
                      <el-option
                        v-for="item in communityJobEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="民族" prop="legalPersonNation">
                    <el-select v-model="registrationInfo.legalPersonNation" placeholder="请选择">
                      <el-option
                        v-for="item in nationEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="国籍" prop="legalPersonNationality">
                    <el-select v-model="registrationInfo.legalPersonNationality" placeholder="请选择">
                      <el-option
                        v-for="item in countryEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="邮政编码" prop="legalPersonPostCode">
                  <el-input v-model="registrationInfo.legalPersonPostCode" clearable class="e-input" placeholder="请输入邮政编码" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="通讯地址" prop="legalPersonAddress">
                  <el-input v-model="registrationInfo.legalPersonAddress" clearable class="e-input" placeholder="请输入通讯地址" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="法人或指定代表手机号码" prop="legalPersonPhone" class="bcli-lh18">
                  <el-input v-model="registrationInfo.legalPersonPhone" clearable class="e-input" placeholder="请输入手机号" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="政治面貌" prop="legalPersonPolitical">
                    <el-select v-model="registrationInfo.legalPersonPolitical" placeholder="请选择">
                      <el-option
                        v-for="item in politicalEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="本组织党内职务" prop="legalPersonPartyJob">
                  <el-input v-model="registrationInfo.legalPersonPartyJob" clearable class="e-input" placeholder="请输入本组织党内职务" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="党组织关系所在单位" prop="legalPersonPartyUnity" class="bcli-lh18 pd-50">
                  <el-input v-model="registrationInfo.legalPersonPartyUnity" clearable class="e-input" placeholder="请输入党组织关系所在单位" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="其他社会职务" prop="legalPersonOtherJob">
                  <el-input v-model="registrationInfo.legalPersonOtherJob" clearable class="e-input" placeholder="请输入其他社会职务" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="文化程度" prop="legalPersonEduDegree">
                    <el-select v-model="registrationInfo.legalPersonEduDegree" placeholder="请选择">
                      <el-option
                        v-for="item in degreeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              
              <div class="block-content-line-item">
                <el-form-item label="是否社工师" prop="legalPersonSocialWorker">
                    <el-select v-model="registrationInfo.legalPersonSocialWorker" placeholder="请选择">
                      <el-option
                        v-for="item in socialWorkerEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="职业资格水平" prop="legalPersonProfessionLevel">
                    <el-select v-model="registrationInfo.legalPersonProfessionLevel" placeholder="请选择">
                      <el-option
                        v-for="item in professionLevelEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="从业单位类别" prop="legalPersonUnityType">
                    <el-select v-model="registrationInfo.legalPersonUnityType" placeholder="请选择">
                      <el-option
                        v-for="item in unityTypeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
              </div>
            </div>
          </div>
        </div>
        <!-- <hr style="width:95%;text-align:center;border-top:2px dotted #E60000;"> -->
        <div class="block">
          <div class="block-title">
            <!-- 企业联络员 -->
          </div>
          <div class="block-content"   style="padding:0px 40px 10px 0px">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="姓名" prop="contactPerson">
                  <el-input v-model="registrationInfo.contactPerson" clearable class="e-input" placeholder="请输入姓名" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="职务" prop="contactJob">
                  <el-input v-model="registrationInfo.contactJob" clearable class="e-input" placeholder="请输入职位" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="手机号码" prop="contactPhone">
                  <el-input v-model="registrationInfo.contactPhone" clearable class="e-input" placeholder="请输入手机号" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="电子邮箱" prop="contactMail">
                  <el-input v-model="registrationInfo.contactMail" clearable class="e-input" placeholder="请输入电子邮箱" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="微信号" prop="contactWechat">
                  <el-input v-model="registrationInfo.contactWechat" clearable class="e-input" placeholder="请输入微信号" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
              </div>
            </div>

          </div>
        </div>
        <!-- <hr style="width:95%;text-align:center;border-top:2px dotted #E60000;"> -->
        <div class="block">
          <div class="block-title">
            <!-- 企业信息 -->
          </div>
          <div class="block-content"   style="padding:0px 40px 10px 0px">
            <div class="block-content-line">
              <div class="block-content-line-item">
                <el-form-item label="企业简介" prop="introduction">
                  <el-input type="textarea" v-model="registrationInfo.introduction" :autosize="{ minRows: 2}" placeholder="请输入企业简介" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="注册商标品牌" prop="companyBrand">
                  <el-input v-model="registrationInfo.companyBrand" clearable class="e-input" placeholder="请输入注册商标品牌" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="上年度企业生产经营状况" prop="companyBusinessStatus" class="bcli-lh18">
                  <el-input v-model="registrationInfo.companyBusinessStatus" clearable class="e-input" placeholder="请输入上年度企业生产经营状况" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="企业总资产(万元)" prop="companyAssets" class="bcli-lh18 pd-40">
                  <el-input v-model="registrationInfo.companyAssets" clearable class="e-input" placeholder="请输入企业总资产(万元)" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="资产负债率(%0)" prop="companyDebtRatio" >
                  <el-input v-model="registrationInfo.companyDebtRatio" clearable class="e-input" placeholder="请输入资产负债率(%0)" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="销售收入(万元)" prop="companySales">
                  <el-input v-model="registrationInfo.companySales" clearable class="e-input" placeholder="请输入销售收入(万元)" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="利润(万元)" prop="companyProfit">
                  <el-input v-model="registrationInfo.companyProfit" clearable class="e-input" placeholder="请输入利润(万元)" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="税收（万元）" prop="companyTax">
                  <el-input v-model="registrationInfo.companyTax" clearable class="e-input" placeholder="请输入税收（万元）" />
                </el-form-item>
              </div>
              <div class="block-content-line-item">
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="进出口贸易资质" prop="companyTrade">
                    <el-select v-model="registrationInfo.companyTrade" placeholder="请选择进出口贸易资质">
                      <el-option
                        v-for="item in companyTradeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="自主知识产权" prop="companyRight">
                    <el-select v-model="registrationInfo.companyRight" placeholder="请选择自主知识产权">
                      <el-option
                        v-for="item in companyRightEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="银行贷款" prop="companyLoan">
                    <el-select v-model="registrationInfo.companyLoan" placeholder="请选择银行贷款">
                      <el-option
                        v-for="item in companyLoanEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="银行贷款金额（万元）" prop="companyLoanAmount" class="bcli-lh18">
                  <el-input v-model="registrationInfo.companyLoanAmount" clearable class="e-input" placeholder="请输入银行贷款金额（万元）" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="企业征信信用档案" prop="companyLoanStatus" class="bcli-lh18 pd-60">
                    <el-select v-model="registrationInfo.companyLoanStatus" placeholder="请选择">
                      <el-option
                        v-for="item in loanStatusEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                      </el-option>
                    </el-select>
                </el-form-item>
              </div>
              <div class="block-content-line-item">
                <el-form-item label="品名" prop="companyProductName">
                  <el-input v-model="registrationInfo.companyProductName" clearable class="e-input" placeholder="请输入品名" />
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line">
              <div class="block-content-line-item">
                <el-form-item label="主要产品\工艺技术\产能情况介绍" prop="companyProductIntroduction" class="bcli-lh18">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入内容"
                    v-model="registrationInfo.companyProductIntroduction">
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <div class="block-content-line">
              <div class="block-content-line-item">
                <el-form-item label="服务需求及建议" prop="companyAdvise">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入内容"
                    v-model="registrationInfo.companyAdvise">
                  </el-input>
                </el-form-item>
              </div>
              
            </div>
            <div class="block-content-line">
              <div class="block-content-line-item">
                <el-form-item label="申请单位公章及法人签字" prop="signOfficeFile">
                  <el-upload
                    class="upload-demo"
                    action="/api/system/auth/upload"
                    :before-upload="signOfficeUploadBefore"
                    :on-success="signOfficeUploadSuccess"
                    :on-preview="signOfficePreview"
                    :on-remove="signOfficeRemove"
                    :on-exceed="signOfficeExceed"
                    multiple
                    :limit="3"
                    ref="dmtSignOfficeFile"
                    :file-list="signOfficeFileList">
                    <el-button size="small" type="primary">点击上传文件</el-button>
                    <div slot="tip" class="el-upload__tip">
                      只能上传doc/docx文件，且不超过10M
                      <el-link type="success" href="/template/sign_office_template.docx" target="_blank">下载模版</el-link>
                    </div>
                  </el-upload>
              </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align:center">
          <el-button type="default" class="register-btn" @click="confirmRegister" >确认提交</el-button>
        </div>
      </el-form>
    </div>
    </div>
  </div>
</template>

<script>

import { getCodeImg,sendMsg } from "@/api/login";
import { getSystemEnum,customerRegister,getCustomerById,customerEdit } from "@/api/register";
import {isEmpty, validatePhone} from '@/utils/validator'
export default {
  name: "customer_edit",
  components: {},
  data() {
    let _this = this
    let validateLicensePic = function(rule, value, callback){
        if(_this.licensePicList.length==0){
          callback(new Error('请上传营业执照'))
        }
        callback()
    }
    let validateSignOfficeFile = function(rule, value, callback){
        if(_this.signOfficeFileList.length==0){
          callback(new Error('请上传申请单位公章及法人签字'))
        }
        callback()
    }
    return {
      codeUrl: "",
      sendMessageState:'0',
      sendBtnMessage:"发送验证码",
      certTypeEnum:[],
      sexEnum:[{code:0,info:"男"},{code:1,info:"女"}],
      communityJobEnum:[],
      nationEnum:[],
      countryEnum:[],
      politicalEnum:[],
      degreeEnum:[],
      socialWorkerEnum:[{code:0,info:"否"},{code:1,info:"是"}],
      professionLevelEnum:[],
      unityTypeEnum:[],
      companyDevCentreEnum:[{code:0,info:"否"},{code:1,info:"是"}],
      companySetPartyEnum:[{code:0,info:"否"},{code:1,info:"是"}],
      companyTradeEnum:[{code:0,info:"否"},{code:1,info:"是"}],
      companyRightEnum:[{code:0,info:"否"},{code:1,info:"是"}],
      companyLoanEnum:[{code:0,info:"否"},{code:1,info:"是"}],
      loanStatusEnum:[],
      registrationInfo:{},
      dialogImageUrl: '',
      dialogVisible: false,
      licensePicHideUpload:false,
      licensePicList:[],
      signOfficeFileList: [],
      registRules: {
        loginPhone: [
            { required: true, message: "请输入登录手机号", trigger: "blur"}
          ],
        loginPwd: [
            { required: true, message: "请输入密码", trigger: "blur"}
          ], 
        rePassword: [
            { required: true, message: "请再次输入密码", trigger: "blur"}
          ], 
        picCode: [
            { required: true, message: "请输入图形码", trigger: "blur"}
          ], 
        smsCode: [
            { required: true, message: "请输入短信码", trigger: "blur"}
          ], 
        company: [
            { required: true, message: "请输入单位名称", trigger: "blur"}
          ],
        creditCode: [
          { required: true, message: "请输入单位统一信用代码", trigger: "blur"}
        ],
        licensePic: [
            { required: true,validator: validateLicensePic, trigger: "change"}
        ],
        registeredCapital: [
          { required: true, message: "请输入注册资金", trigger: "blur"}
        ],
        registeredAddress: [
          { required: true, message: "请输入注册地址", trigger: "blur"}
        ],
        registeredDate: [
          { required: true, message: "请输入成立日期", trigger: "blur"}
        ],
        companyTaxRate: [
          { required: true, message: "请输入纳税信用等级", trigger: "blur"}
        ],
        companyCity: [
          { required: true, message: "请输入所属市州", trigger: "blur"}
        ],
        companyProperties: [
          { required: true, message: "请输入单位性质", trigger: "blur"}
        ],
        companyIndustryType: [
          { required: true, message: "请输入行业类型", trigger: "blur"}
        ],
        companyEmployees: [
          { required: true, message: "请输入职工人数", trigger: "blur"}
        ],
        companyDevCentre: [
          { required: true, message: "请选择是否建立研发中心", trigger: "blur"}
        ],
        companySetParty: [
          { required: true, message: "请选择是否成立党组织", trigger: "blur"}
        ],
        companyPartyNum: [
          { required: true, message: "请输入党员数量", trigger: "blur"}
        ],
        wineTasterLevelTotal: [
          { required: true, message: "请输入国家级品酒师人总数", trigger: "blur"}
        ],
        wineTasterLevelOne: [
          { required: true, message: "请输入一级", trigger: "blur"}
        ],
        wineTasterLevelTwo: [
          { required: true, message: "请输入二级", trigger: "blur"}
        ],
        wineTasterLevelThree: [
          { required: true, message: "请输入三级", trigger: "blur"}
        ],
        wineTasterLevelFour: [
          { required: true, message: "请输入四级", trigger: "blur"}
        ],
        wineJudgesCountryNum: [
          { required: true, message: "请输入国家级白酒评委人数", trigger: "blur"}
        ],
        wineJudgesProvinceNum: [
          { required: true, message: "请输入省级白酒评委人数", trigger: "blur"}
        ],
        legalPersonName: [
          { required: true, message: "请输入单位法定代表人或委托代表人", trigger: "blur"}
        ],
        legalPersonSex: [
          { required: true, message: "请输入性别", trigger: "blur"}
        ],
        legalPersonCertType: [
          { required: true, message: "请输入证件类型", trigger: "blur"}
        ],
        legalPersonCertNum: [
          { required: true, message: "请输入证件号码", trigger: "blur"}
        ],
        legalPersonBirthday: [
          { required: true, message: "请输入出生年月", trigger: "blur"}
        ],
        legalPersonCommunityJob: [
          { required: true, message: "请输入申请类别", trigger: "blur"}
        ],
        legalPersonNation: [
          { required: true, message: "请选择民族", trigger: "blur"}
        ],
        legalPersonNationality: [
          { required: true, message: "请选择国籍", trigger: "blur"}
        ],
        legalPersonPostCode: [
          { required: true, message: "请选择邮政编码", trigger: "blur"}
        ],
        legalPersonAddress: [
          { required: true, message: "请选择通讯地址", trigger: "blur"}
        ],
        legalPersonPhone: [
          { required: true, message: "请输入法人或指定代表手机号码", trigger: "blur"}
        ],
        legalPersonPolitical: [
          { required: true, message: "请输入政治面貌", trigger: "blur"}
        ],
        legalPersonPartyJob: [
          { required: true, message: "请输入本组织党内职务", trigger: "blur"}
        ],
        legalPersonPartyUnity: [
          { required: true, message: "请输入党组织关系所在单位", trigger: "blur"}
        ],
        legalPersonOtherJob: [
          { required: true, message: "请输入其他社会职务", trigger: "blur"}
        ],
        legalPersonEduDegree: [
          { required: true, message: "请选择文化程度", trigger: "blur"}
        ],
        legalPersonSocialWorker: [
          { required: true, message: "请选择是否社工师", trigger: "blur"}
        ],
        legalPersonProfessionLevel: [
          { required: true, message: "请选择职业资格水平", trigger: "blur"}
        ],
        legalPersonUnityType: [
          { required: true, message: "请选择从业单位类别", trigger: "blur"}
        ],
        contactPerson: [
          { required: true, message: "请输入姓名", trigger: "blur"}
        ],
        contactJob: [
          { required: true, message: "请输入职务", trigger: "blur"}
        ],
        contactPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur"}
        ],
        contactMail: [
          { required: true, message: "请输入电子邮箱", trigger: "blur"}
        ],
        contactWechat: [
          { required: true, message: "请输入微信号", trigger: "blur"}
        ],
        introduction: [
          { required: true, message: "请输入企业简介", trigger: "blur"}
        ],
        companyBrand: [
          { required: true, message: "请输入注册商标品牌", trigger: "blur"}
        ],
        companyBusinessStatus: [
          { required: true, message: "请输入上年度企业生产经营状况", trigger: "blur"}
        ],
        companyAssets: [
          { required: true, message: "请输入企业总资产", trigger: "blur"}
        ],
        companyDebtRatio: [
          { required: true, message: "请输入资产负债率", trigger: "blur"}
        ],
        companySales: [
          { required: true, message: "请输入销售收入", trigger: "blur"}
        ],
        companyProfit: [
          { required: true, message: "请输入利润", trigger: "blur"}
        ],
        companyTax: [
          { required: true, message: "请输入税收", trigger: "blur"}
        ],
        companyTrade: [
          { required: true, message: "请选择进出口贸易资质", trigger: "blur"}
        ],
        companyRight: [
          { required: true, message: "请选择自主知识产权", trigger: "blur"}
        ],
        companyLoan: [
          { required: true, message: "请选择银行贷款", trigger: "blur"}
        ],
        companyLoanAmount: [
          { required: true, message: "请输入银行贷款金额", trigger: "blur"}
        ],
        companyLoanStatus: [
          { required: true, message: "请选择企业征信信用档案", trigger: "blur"}
        ],
        companyProductName: [
          { required: true, message: "请输入品牌", trigger: "blur"}
        ],
        companyProductIntroduction: [
          { required: true, message: "请输入主要产品、工艺技术、产能情况介绍  ", trigger: "blur"}
        ],
        signOfficeFile: [
            { required: true,validator: validateSignOfficeFile, trigger: "change"}
        ],

      }
    };
  },
  created(){

  },
  mounted() {
    const id = this.$route.query.id
    if(!isEmpty(id)){
      this.registrationInfo.id=id
      this.getCustomerById(id)
    }else{
      this.getCode();
      this.getSystemEnum();
    }
  },
  watch:{
  },
  methods: {
    // 获取详情
    getCustomerById(id) {
      getCustomerById(id).then(res => {
          console.log(res)
          this.registrationInfo=res.data
          this.licensePicList=res.licensePicList
          this.signOfficeFileList=res.signFileList
          if(this.licensePicList.length>=2){
            this.licensePicHideUpload=true
          }else{
            this.licensePicHideUpload=false
          }
          this.getCode();
          this.getSystemEnum();
      });
    },
    // 图形验证码
    getSystemEnum() {
      getSystemEnum().then(res => {
        this.certTypeEnum=res.certTypeEnum;
        this.communityJobEnum=res.communityJobEnum;
        this.nationEnum=res.nationEnum;
        this.countryEnum=res.countryEnum;
        this.politicalEnum=res.politicalEnum;
        this.degreeEnum=res.degreeEnum;
        this.professionLevelEnum=res.professionLevelEnum;
        this.unityTypeEnum=res.unityTypeEnum;
        this.loanStatusEnum=res.loanStatusEnum;
      });
    },
    // 图形验证码
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.registrationInfo.uuid = res.uuid;
        }
      });
    },
    // 短信验证码
    sendLoginCode(){
      const phone = this.registrationInfo.loginPhone
      if(phone==null || !validatePhone(phone)){
          this.$message.warning('手机号为空或不合法')
          return
      }
      if(this.registrationInfo.picCode == null){
        this.$message.warning('图形验证码未填写!')
        return
      }
      const data = {
        loginPhone:phone,
        picCode:this.registrationInfo.picCode,
        uuid:this.registrationInfo.uuid
      } 
      sendMsg(data).then(response => {
          console.log(response)
          this.$message.success('验证码已发送')
          this.getCode();
          this.registrationInfo.smsUuid=response.smsUuid
          this.sendMessageState='1'
          let count = 60
          const secondCount = setInterval(()=>{
              if(count>0){
                  this.sendMessageState='1'
                  this.sendBtnMessage = count + 's'
                  count--
              }else{
                  this.sendMessageState='0'
                  this.sendBtnMessage = '发送验证码'
                  window.clearInterval(secondCount)
              }
          },1000)
      })
    },
    confirmRegister(){
      this.$refs["registrationInfo"].validate(valid => {
        if (valid) {
          //上传图片
          this.registrationInfo.uploadPicList=this.licensePicList;
          this.registrationInfo.uploadFileList=this.signOfficeFileList;
          if(isEmpty(this.registrationInfo.id)){
            customerRegister(this.registrationInfo).then(response => {
                console.log(response)
                this.$message.success('注册成功')
                // 跳转到登录页
                this.$router.push({
                  path:'/menu_page/sys/login'
                })
            })
          }else{
            customerEdit(this.registrationInfo).then(response => {
                console.log(response)
                this.$message.success('修改成功')
                // 跳转到登录页
                this.$router.push({
                  path:'/menu_page/my/my_index'
                })
            })
          }
        }
      });
    },
    // 上传前
    licensePicUploadBefore(file){
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt2M;
    },
    exceedImage(){
        this.licensePicHideUpload=true
        this.$message.warning('上传文件数量超过限制')
    },
    // 上传成功
    licensePicUploadSuccess(res, file) {
      if(res.code==200){
        this.licensePicList.push(res.file)
        if(this.licensePicList.length>=1){
          this.licensePicHideUpload=true
        }else{
          this.licensePicHideUpload=false
        }
      }else{
         this.$message.error('上传失败');
        this.$refs.dmtLicensePic.uploadFiles.splice(this.$refs.dmtLicensePic.uploadFiles.indexOf(res),1)
      }
    },
    // 删除图片
    licensePicRemove(file, fileList) {
      console.log(file, fileList);
      for(let i=0;i<this.licensePicList.length;i++){
        if (this.licensePicList[i].id === file.id) {
          this.licensePicList.splice(i,1)
          break
        }
      }
      if(this.licensePicList.length>=1){
        this.licensePicHideUpload=true
      }else{
        this.licensePicHideUpload=false
      }
    },
    // 预览图片
    licensePicPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 文件上传前
    signOfficeUploadBefore(file){
      const isDoc = (file.type ==='application/msword'||file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isDoc) {
        this.$message.error('上传文件只能是 doc/docx 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isDoc && isLt2M;
    },
    // 文件上传成功
    signOfficeUploadSuccess(res, file){
      if(res.code==200){
        this.signOfficeFileList.push(res.file)
      }else{
         this.$message.error('上传失败');
        this.$refs.dmtSignOfficeFile.uploadFiles.splice(this.$refs.dmtSignOfficeFile.uploadFiles.indexOf(res),1)
      }
      
    },
    // 文件删除
    signOfficeRemove(file, fileList) {
        console.log(file, fileList);
        for(let i=0;i<this.signOfficeFileList.length;i++){
          if (this.signOfficeFileList[i].id === file.id) {
            this.signOfficeFileList.splice(i,1)
            break
          }
        }
    },
    signOfficePreview(file) {
      window.location.href=file.url;
    },
    // 文件删除
    signOfficeExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    }
  },
};
</script>

<!-- <style scoped lang="scss"> -->
<style scoped>

.flex{
  display:flex;
}
.block{
  background-color: #fff;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;padding-top:20px;padding-bottom:15px;margin-bottom:20px
}
.block-title{
  margin-top:50px;
}
.block-content-line-item{flex:4}
/deep/ .block-content-line-item .el-select{width:100%;}
/deep/ .block-content-line .el-form-item__label{padding-left: 20px;}
/deep/ .bcli-lh18  .el-form-item__label, .lh18{line-height: 18px;}
/* /deep/ .block-content-line-item .el-form-item__content{margin-left: 120px !important;} */
/deep/ .label-center .el-form-item__label{text-align: center;}
.label1{
  width: 150px;padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2{
  padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2 span{
  margin:0px 20px;
}
/deep/ .label2 .el-input__inner{line-height:32px;height:32px;padding-left: 5px;padding-right:5px;text-align: center;}

/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  
.login-code{
  width: 60px;
  height: 38px;
  img {
    cursor: pointer;
    vertical-align: middle; 
  }
 }
.login-code-img {
  height: 32px;
  margin-left: 10px;
}
.login-btn{
  height: 32px;
  margin-left: 10px;
}

.register-btn{
  margin-top: 30px;
  margin-bottom: 50px;
  /* margin-left: 50%; */
}
/** 隐藏上传图标*/
/deep/ .hideUpload .el-upload--picture-card{
  visibility: hidden;
}


/deep/ .pjsss .el-form-item__label{padding-left: 20px; width:inherit !important}
/deep/ .pjsss .el-form-item__content{margin-left:72px !important;margin-right:40px}

/deep/ .pd-60 .el-form-item__label{padding-left: 60px;}
/deep/ .pd-50 .el-form-item__label{padding-left: 50px;}
/deep/ .pd-40 .el-form-item__label{padding-left: 40px;}
</style>