<template>
  <div class="main-body">
    <div class="c-1200" style="padding-bottom:50px">
      <div class="nav-box">
        <i class="fa fa-map-pin nav-icon" aria-hidden="true"></i>
        <span class="nav-c">首页</span>
        <span  class="nav-g">/</span>
        <span  class="nav-z">我的</span>
      </div>
    <div style="margin:auto;padding:0px 0px;">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane :label="loginType=='1'?'个人信息':'企业信息'" name="myInfo">
          <div>
          <!-- <div style="padding:0px 20px"> -->
          <user_detail v-if="loginType=='1'"></user_detail>
          <customer_detail v-if="loginType=='2'"></customer_detail>
          </div>
        </el-tab-pane>
        <el-tab-pane label="我的报名" name="signRecord" v-if="loginType=='1'">
          <div>
          <!-- <div style="padding:0px 20px"> -->
          <my_record></my_record>
        </div>
        </el-tab-pane>
        <el-tab-pane label="我的证书" name="cert" v-if="loginType=='1'">
          <div style="background-color:#ffffff;">
          <!-- <div style="padding:0px 20px"> -->
            <my_cert></my_cert>
          </div>
        </el-tab-pane>
      </el-tabs>  
    </div>
    </div>
  </div>
</template>

<script>

import user_detail from '@/views/routerpage/menu_page/my/my_detail_user'
import customer_detail from '@/views/routerpage/menu_page/my/my_detail_customer'
import my_cert from '@/views/routerpage/menu_page/my/my_cert'
import my_record from '@/views/routerpage/menu_page/my/my_record'
export default {
  name: "routerpage-index",
  dicts: ['certificate_type','course_type','sign_status','sys_user_sex','needs','edu'],
  components: {user_detail,customer_detail,my_record,my_cert},
  data() {
    return {
      activeName: 'myInfo',
      loginType:""
    };
  },
  mounted() {
    this.loginType=this.$store.getters.loginType
  },
  watch:{
  },
  methods: {
   
    handleClick(tab, event) {
      console.log(tab, event);
      if(tab.name==='signRecord'){

      }
      if(tab.name==='cert'){

      }
      if(tab.name==='myInfo'){

      }
    }
  },
};
</script>
<style rel="stylesheet/scss" lang="css" scoped>
 .login-code{
  width: 100%;
  height: 38px;
  img {
    cursor: pointer;
    vertical-align: middle; 
  }
 }
 .login-code-img {
    height: 38px;
    width:100%;
  }
  .login-btn{
    height: 38px;
    width:120px;
    width:100%;
  }
  .el-link{
    font-size: 12px !important;
    line-height:14px;
    float:right;
    vertical-align: middle;
    margin-top:15px;
  }
  /deep/.el-tabs__content{
    background-color: #fff;
    border:1px solid #E4E7ED;
    border-top:none;
  }
  
  /deep/.el-tabs__item{
    /* border:none !important; */
    border-bottom:1px solid #E4E7ED !important;
  }
  /deep/.el-tabs__nav{
    /* border:none !important; */
    /* border:1px solid #E4E7ED !important; */
    background-color: #fff;
  }
  /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
    /* border-left:1px solid #E4E7ED !important;
    border-right:1px solid #E4E7ED !important;
    border-top:1px solid #E4E7ED !important; */
    border-bottom: none !important;
    background-color: #fff;
  }
  /deep/.el-tabs__header{
    margin:0px;
  }
</style>