<!-- 作者: 关佳琪 -->
<!-- 页面功能: 公共页面底部 -->
<!-- 创建时间: 2020年05月24日 15:43:37 -->
<template>
  <div id="footer" style="background-color:#b70000;color: #fff;">
    <div style="background-color:#c10000;">
      <div class="c-1200" style="text-align:center;line-height: 35px;">
        <el-row :gutter="10">
          <el-col :span="4">&nbsp;</el-col>
          <!-- <el-col :span="3">新闻资讯</el-col>
          <el-col :span="3">技术服务</el-col>
          <el-col :span="3">酿酒科技</el-col>
          <el-col :span="3">服务专栏</el-col>
          <el-col :span="3">协会介绍</el-col> -->
          <el-col :span="4">&nbsp;</el-col>
        </el-row>
      </div>
    </div>
    <div class="c-1200" style="line-height:100px;padding: 10px 0px;">
      <el-row :gutter="10">
        <el-col :span="2">
          <img src="../../assets/img/logo-foot.png" style="height:76px; vertical-align: middle;"/>
        </el-col>
        <el-col :span="15">
          &nbsp;
        </el-col>
        <el-col :span="5" style="font-size:12px;color:#fff">
          <p style="line-height:18px"><i class="fa fa-phone" aria-hidden="true"></i> 联系电话：0851-88888888</p>
          <p style="line-height:18px"><i class="fa fa-envelope-open-o" aria-hidden="true"></i> 客服邮箱：123@123.123</p>
          <p style="line-height:18px"><i class="fa fa-building" aria-hidden="true"></i> 制作单位：贵州酿酒协会</p>
        </el-col>
        <el-col :span="2" style="text-align:right;">
          <img src="../../assets/img/logo-foot.png" style="height:76px; vertical-align: middle;"/>
        </el-col>
      </el-row>
    </div>

    <div style="background-color:#aa0000;">
      <div class="c-1200" style="text-align:center;line-height: 35px;font-size: 12px;color:#fff">
        Copyright©2023-2027&nbsp;贵州省酿酒工业协会官网&nbsp;&nbsp;<a href="https://beian.miit.gov.cn" style="color:#fff !important">黔ICP备2023000518号-1</a>&nbsp;&nbsp;&nbsp;
        <!-- 京公网安备11010802012622号 -->
        <div style="width:300px;margin:0 auto; padding:0px 0;color: #fff;">
		 		<img src="../../../src/assets/img/gaba.png"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52010202003403" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#ffffff"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#ffffff;">贵公网安备 52010202003403号</p></a>
		 	</div>
		 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  data() {
    return {

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  //生命周期 - 方法
  methods: {

  }
};
</script>
<style scoped>
  /* @import url(); 引入css类 */
</style>