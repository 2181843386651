import request from '@/utils/request'


// 查询课程报名详细
export function judgeSigned(id) {
  return request({
    url: '/courses/sign/judge',
    method: 'get',
    params: {courseId: id}
  })
}

export function signCourses() {
  return request({
    url: '/courses/sign/signCourses',
    method: 'get'
  })
}

// 新增课程报名
export function addSignRecord(data) {
  return request({
    url: '/courses/sign/signRecord',
    method: 'post',
    data: data
  })
}

export function updateSignRecord(data) {
  return request({
    url: '/courses/sign/record/update',
    method: 'post',
    data: data
  })
}


export function listSignRecord(query) {
  return request({
    url: '/courses/sign/list',
    method: 'get',
    params: query
  })
}

// 查询课程报名详细
export function getSignRecord(id) {
  return request({
    url: '/courses/sign/' + id,
    method: 'get'
  })
}


// 查询课程报名详细
export function getSignEditRecord(id) {
  return request({
    url: '/courses/sign/edit/' + id,
    method: 'get'
  })
}