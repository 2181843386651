import axios from 'axios'
import store from '@/store'
import { getToken,removeToken } from '@/utils/auth'
import { Notification,Loading } from 'element-ui';

// create an axios instance
let loadingOption = {
  lock: true,
  text: '正在加载中',
  spinner: 'el-icon-loading',
  background: 'rgba(0, 0, 0, 0.7)'
}
let loadingInstance
const service = axios.create({
  baseURL: "/api", // url = base url + request url
  timeout: 45000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.headers["hideLoading"]!=true) {
      loadingInstance = Loading.service(loadingOption);
    }
    return config
  },
  error => {
    // do something with request error
    if (loadingInstance){
      loadingInstance.close()
    }
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (loadingInstance){
      loadingInstance.close()
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401 || res.code === 403) {
        store.dispatch("user/logout")
        removeToken()
        localStorage.removeItem("loginInfo")
        window.location.href = "/menu_page/sys/login"
      }
      Notification.warning({
        title: '错误',
        message: res.msg || '服务器执行异常'
      });
      return Promise.reject( res.msg || '服务器执行异常')
    } else {
      const fileName = response.headers['content-disposition']
      if(fileName!=null){
        res['fileName'] = fileName.split("=")[1]
      }
      return res
    }
  },
  error => {
    if (loadingInstance){
      loadingInstance.close()
    }
    Notification.error({
      title: '错误',
      message: '网络访问错误'
    });
    return Promise.reject('网络访问错误')
  }
)

export default service
