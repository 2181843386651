<!-- 作者:  -->
<!-- 页面功能:  -->
<!-- 创建时间: 2020年05月26日 17:58:58 -->
<template>
  <router-view></router-view>
</template>

<script>
export default {
  name:'layout-default',
  components: { },
  data() {
    return {

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  //生命周期 - 方法
  methods: {

  }
};
</script>
<style scoped>
  /* @import url(); 引入css类 */

</style>