<template>
    <div class="main-body" >
    <div class="c-1200" style="position:relative;border-radius: 6px;margin-bottom: 50px;">
      <div class="block" style="position:relative;background-color: #fff;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
          <div class="block-content-line flex">
            <div class="block-content-line-item">
              <label class="label1">注册手机号:</label>
              <label class="label2">{{registrationInfo.loginPhone}}</label>
            </div>
          </div>
          <div class="block-content"  >
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">单位名称:</label><label class="label2">{{registrationInfo.company}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">单位统一信用代码:</label><label class="label2">{{registrationInfo.creditCode}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
              <label class="label1">营业执照:</label>
                <label class="label2">
              <el-image v-for="pic in licensePicList" style="width: 100px; height: 100px; margin-left: 10px;"
                :src="pic.url" 
                :preview-src-list="pic.preList">
              </el-image>
              </label>
            </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">注册资金:</label>
                <label class="label2">{{registrationInfo.registeredCapital}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">注册地址:</label>
                  <label class="label2"> {{registrationInfo.registeredAddress}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">成立日期:</label>
                  <label class="label2">{{registrationInfo.registeredDate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <!-- <div class="block-title">
            登记相关
          </div> -->
          <div class="block-content">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">纳税信用等级:</label>
                <label class="label2">{{registrationInfo.companyTaxRate}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">所属市州:</label>
                <label class="label2">{{registrationInfo.companyCity}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">单位性质:</label>
                <label class="label2">{{registrationInfo.companyPropertiesInfo}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">行业类型:</label>
                <label class="label2">{{registrationInfo.companyIndustryType}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">职工人数:</label>
                <label class="label2">{{registrationInfo.companyEmployees}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">是否建立研发中心:</label>
                <label class="label2">
                  <dict-tag :options="dict.type.consumer_yes_no" :value="registrationInfo.companyDevCentre"/>
                </label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否成立党组织:</label>
                <label class="label2">
                  <dict-tag :options="dict.type.consumer_yes_no" :value="registrationInfo.companySetParty"/>
                </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">党员数量:</label>
                <label class="label2">{{registrationInfo.companyPartyNum}}</label>
              </div>
            </div>
            <el-row>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">国家级品酒师人总数:</label>
                <label class="label2">{{registrationInfo.wineTasterLevelTotal}}</label>
              </div>
              </div>

            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">国家级品酒师人数:</label>
                <label class="label2">
                  <span style="margin-left:20px;">一级: {{registrationInfo.wineTasterLevelOne}} 人</span>
                  <span style="margin-left:20px;">二级: {{registrationInfo.wineTasterLevelTwo}} 人</span>
                  <span style="margin-left:20px;">三级: {{registrationInfo.wineTasterLevelThree}} 人</span>
                  <span style="margin-left:20px;">四级: {{registrationInfo.wineTasterLevelFour}} 人</span>
                  </label>
                <!-- <label class="label1">国家级品酒师人数</label>
                <label class="label2">
                  一级:{{registrationInfo.wineTasterLevelOne}} 
                  二级:{{registrationInfo.wineTasterLevelTwo}} 
                  三级:{{registrationInfo.wineTasterLevelThree}} 
                  四级:{{registrationInfo.wineTasterLevelFour}} 
                </label> -->
              </div>
            </div>
            </el-row>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">国家级白酒评委人数:</label>
                  <label class="label2">{{registrationInfo.wineJudgesCountryNum}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">省级白酒评委人数:</label>
                  <label class="label2">{{registrationInfo.wineJudgesProvinceNum}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <!-- <div class="block-title">
            企业代表信息
          </div> -->
          <div class="block-content">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">单位法定代表人或委托代表人:</label>
                <label class="label2">{{registrationInfo.legalPersonName}} </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">性别:</label>
                <label class="label2"><dict-tag :options="dict.type.sys_user_sex" :value="registrationInfo.legalPersonSex"/></label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">证件类型:</label>
                <label class="label2">{{registrationInfo.legalPersonCertTypeInfo}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">证件号码:</label>
                <label class="label2">{{registrationInfo.legalPersonCertNum}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">出生年月:</label>
                <label class="label2">{{registrationInfo.legalPersonBirthday}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">申请类别:</label>
                <label class="label2">{{registrationInfo.legalPersonCommunityJobInfo}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">民族:</label>
                <label class="label2">{{registrationInfo.legalPersonNationInfo}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">国籍:</label>
                <label class="label2">{{registrationInfo.legalPersonNationalityInfo}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">邮政编码:</label>
                <label class="label2">{{registrationInfo.legalPersonPostCode}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">通讯地址:</label>
                <label class="label2">{{registrationInfo.legalPersonAddress}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">法人或指定代表手机号码:</label>
                <label class="label2">{{registrationInfo.legalPersonPhone}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">政治面貌:</label>
                <label class="label2">{{registrationInfo.legalPersonPoliticalInfo}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">本组织党内职务:</label>
                <label class="label2">{{registrationInfo.legalPersonPartyJob}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">党组织关系所在单位:</label>
                <label class="label2">{{registrationInfo.legalPersonPartyUnity}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">其他社会职务:</label>
                <label class="label2">{{registrationInfo.legalPersonOtherJob}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">文化程度:</label>
                <label class="label2">{{registrationInfo.legalPersonEduDegreeInfo}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否社工师:</label>
                <label class="label2">
                  <dict-tag :options="dict.type.consumer_yes_no" :value="registrationInfo.legalPersonSocialWorker"/>
                </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">职业资格水平:</label>
                <label class="label2">{{registrationInfo.legalPersonProfessionLevelInfo}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">从业单位类别:</label>
                <label class="label2">{{registrationInfo.legalPersonUnityTypeInfo}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <!-- <div class="block-title">
            企业联络员
          </div> -->
          <div class="block-content" >
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">姓名:</label>
                <label class="label2">{{registrationInfo.contactPerson}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">职务:</label>
                <label class="label2">{{registrationInfo.contactJob}} </label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">手机号码:</label>
                <label class="label2">{{registrationInfo.contactPhone}} </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">电子邮箱:</label>
                <label class="label2">{{registrationInfo.contactMail}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">微信号:</label>
                <label class="label2">{{registrationInfo.contactWechat}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <!-- <div class="block-title">
            企业信息
          </div> -->
          <div class="block-content" >
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">企业简介:</label>
                <label class="label2">{{registrationInfo.introduction}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">注册商标品牌:</label>
                <label class="label2">{{registrationInfo.companyBrand}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">上年度企业生产经营状况:</label>
                <label class="label2">{{registrationInfo.companyBusinessStatus}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">企业总资产(万元):</label>
                <label class="label2">{{registrationInfo.companyAssets}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">资产负债率(%0):</label>
                <label class="label2">{{registrationInfo.companyDebtRatio}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">销售收入(万元):</label>
                <label class="label2">{{registrationInfo.companySales}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">利润(万元):</label>
                <label class="label2">{{registrationInfo.companyProfit}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">税收（万元）:</label>
                <label class="label2">{{registrationInfo.companyTax}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否有进出口贸易资质:</label>
                <label class="label2">
                  <dict-tag :options="dict.type.consumer_yes_no" :value="registrationInfo.companyTrade"/>
                </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">是否有自主知识产权:</label>
                <label class="label2">
                  <dict-tag :options="dict.type.consumer_yes_no" :value="registrationInfo.companyRight"/>
                </label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否银行贷款:</label>
                <label class="label2">
                  <dict-tag :options="dict.type.consumer_yes_no" :value="registrationInfo.companyLoan"/>
                </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">银行贷款金额（万元）:</label>
                <label class="label2">{{registrationInfo.companyLoanAmount}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">企业征信信用档案:</label>
                <label class="label2">{{registrationInfo.companyLoanStatusInfo}}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">品名:</label>
                <label class="label2">{{registrationInfo.companyProductName}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">主要产品\工艺技术\产能情况介绍:</label>
                <label class="label2">{{registrationInfo.companyProductIntroduction}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">服务需求及建议:</label>
                <label class="label2">{{registrationInfo.companyAdvise}}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">申请单位公章及法人签字:</label>
                <label class="label2">
                  <el-link icon="el-icon-document" v-for="file in signOfficeFileList" :key="file.id" :href="file.url" style="width: 100%;justify-content: left;">
                    {{file.name}}
                  </el-link>
                </label>
              </div>
            </div>
            
          </div>
        </div>
        <div class="block">
          <div class="block-content-line flex">
            <div class="block-content-line-item" style="margin-bottom: 0px;">
              <label class="label1">企业状态:</label>
              <label class="label2">
                <dict-tag :options="dict.type.customer_state" :value="registrationInfo.state"/>
              </label>
            </div>
          </div>
          <div class="block-content-line flex" v-if="registrationInfo.state!='-1'">
            <div class="block-content-line-item">
              <label class="label1">审核意见:</label>
              <label class="label2">{{registrationInfo.comments}}</label>
            </div>
          </div>
        </div>
        <div>
          <el-button type="default" class="register-btn" @click="goEdit" >修改信息</el-button>
        </div>
    </div>

  </div>
<!-- </div> -->
  <!-- </div> -->
</template>

<script>
import {getCustomerById } from "@/api/register";
import {isEmpty} from '@/utils/validator'
export default {
  name: "customer_detail",
  dicts: ['news_pub_state','sys_user_sex','customer_state','consumer_yes_no'],
  components: {},
  data() {
    return {
      registrationInfo:{},
      licensePicList:[],
      signOfficeFileList:[],
    };
  },
  created(){

  },
  mounted() {
    let id = this.$route.query.id
    if(isEmpty(id)){
      id = this.$store.getters.loginId
    }
    this.registrationInfo.id=id
    this.getCustomerById(id)
  },
  watch:{
  },
  methods: {
    // 获取详情
    getCustomerById(id) {
      getCustomerById(id).then(res => {
          console.log(res)
          this.registrationInfo=res.data
          this.licensePicList=res.licensePicList
          this.signOfficeFileList=res.signFileList
          this.licensePicList.forEach(pic => {
            const srcList=[]
            srcList.push(pic.url);
            pic.preList=srcList
         })
      });
    },
    goEdit(){
      // 跳转到登录页
      this.$router.push({
        path:'/menu_page/customer/customer_edit?id='+this.registrationInfo.id
      })
    }
  },
};
</script>

<style scoped>
.flex{
  display:flex;
}
.block{
  padding:30px;background-color: #fff;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;padding-top:20px;padding-bottom:15px;margin-bottom:20px
}
.block-title{
  margin-top:50px;
}
.block-content-line-item{margin:20px 0px;flex:4;display: flex;}
/deep/ .block-content-line-item .el-select{width:100%;}
/deep/ .block-content-line .el-form-item__label{padding-left: 30px;}
/deep/ .bcli-lh18  .el-form-item__label, .lh18{line-height: 18px;}
/* /deep/ .block-content-line-item .el-form-item__content{margin-left: 120px !important;} */
/deep/ .label-center .el-form-item__label{text-align: center;}
.label1{
  line-height: 24px;min-width: 150px;padding-left: 30px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #333333; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2{
  word-break:break-all;line-height: 24px;padding-left: 30px;text-align: left;vertical-align: middle;float: left;font-size: 14px;color: #666666; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2 span{
  margin:0px 20px;
}
/deep/ .label2 .el-input__inner{line-height:32px;height:32px;padding-left: 5px;padding-right:5px;text-align: center;}

/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  
.login-code{
  width: 60px;
  height: 38px;
  img {
    cursor: pointer;
    vertical-align: middle; 
  }
 }
.login-code-img {
  height: 32px;
  margin-left: 10px;
}
.login-btn{
  height: 32px;
  margin-left: 10px;
}

.register-btn{
  margin-bottom: 10px;
  margin-left: 50%;
}
</style>