import request from '@/utils/request'
import md5 from '@/utils/md5'
import { isEmpty } from '@/utils/validator'
// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 获取短信码
export function sendMsg(data){
  return request({
    url: '/system/auth/send/msg',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}
// web登录
export function webLogin(data){
  if(!isEmpty(data.inputPwd)){
    data.password=md5.hex_md5(data.inputPwd)
  }
  return request({
    url: '/system/auth/web/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 自动登录
export function autoLogin(data){
  if(!isEmpty(data.inputPwd)){
    data.password=md5.hex_md5(data.inputPwd)
  }
  return request({
    url: '/system/auth/auto/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// web登出
export function webLogout(){
  return request({
    url: '/system/login/web/logout',
    method: 'post'
  })
}

// 当前登录信息
export function getCurrentLoginInfo(){
  return request({
    url: '/system/login/current',
    method: 'get'
  })
}

// 我的证书列表
export function getMyCertList(query){
  return request({
    url: '/system/login/cert/list',
    method: 'get',
    params: query
  })
}

// 我的报名列表
export function getSignRecordList(query){
  return request({
    url: '/system/login/sign/list',
    method: 'get',
    params: query
  })
}

// 找回密码
export function findPassword(data){
  return request({
    url: '/system/auth/find/password',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 重置密码
export function resetPassword(data){
  if(!isEmpty(data.password)){
    data.password=md5.hex_md5(data.password)
  }
  if(!isEmpty(data.oldPassword)){
    data.oldPassword=md5.hex_md5(data.oldPassword)
  }
  return request({
    url: '/system/login/reset/password',
    method: 'post',
    data
  })
}