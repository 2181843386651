
<template>
  <div class="main-body" >
    <div class="c-1200" style="position:relative;border-radius: 6px;margin-bottom: 50px;background-color: #fff;">
      <div class="block" style="position:relative;margin-top:0px;">
        <div class="block-content" style="padding:10px">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">姓名:</label>
                <label class="label2">{{ registrationInfo.userName }}</label>
              </div>
              <div class="block-content-line-item" > 
                <label class="label1">手机号码:</label>
                <label class="label2">{{ registrationInfo.phone }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">证件类型:</label>
                <label class="label2">{{ registrationInfo.certTypeInfo }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">证件号码:</label>
                <label class="label2">{{ registrationInfo.certNum }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">身份证正面（人像面）:</label>
                <label class="label2">
                  <el-image v-for="pic in certPicList" style="width: 100px; height: 100px; margin-left: 10px;"
                    :src="pic.url" 
                    :preview-src-list="pic.preList">
                  </el-image>
                </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">身份证反面（国徽面）:</label>
                <label class="label2">
                  <el-image v-for="pic in certPicEmblemList" style="width: 100px; height: 100px; margin-left: 10px;"
                    :src="pic.url" 
                    :preview-src-list="pic.preList">
                  </el-image>
                </label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">性别:</label>
                <label class="label2"><dict-tag :options="dict.type.sys_user_sex" :value="registrationInfo.sex"/></label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">出生年月:</label>
                <label class="label2">{{ registrationInfo.birthday }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">申请类别:</label>
                <label class="label2">{{ registrationInfo.communityJobInfo }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">民族:</label>
                <label class="label2">{{ registrationInfo.nationInfo }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">国籍:</label>
                <label class="label2">{{ registrationInfo.nationalityInfo }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">邮政编码:</label>
                <label class="label2">{{ registrationInfo.postCode }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">通讯地址:</label>
                <label class="label2">{{ registrationInfo.address }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">其他社会职务:</label>
                <label class="label2">{{ registrationInfo.otherJob }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">政治面貌:</label>
                <label class="label2">{{ registrationInfo.politicalInfo }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">本组织党内职务:</label>
                <label class="label2">{{ registrationInfo.partyJob }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">党组织关系所在单位:</label>
                <label class="label2">{{ registrationInfo.partyUnity }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">文化程度:</label>
                <label class="label2">{{ registrationInfo.eduDegreeInfo }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否社工师:</label>
                <label class="label2">
                  <dict-tag :options="dict.type.consumer_yes_no" :value="registrationInfo.socialWorker"/>
                </label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">职业资格水平:</label>
                <label class="label2">{{ registrationInfo.professionLevelInfo }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">从业单位名称及职务:</label>
                <label class="label2">{{ registrationInfo.unityJob }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">从业单位类别:</label>
                <label class="label2">{{ registrationInfo.unityTypeInfo }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">所属市州:</label>
                <label class="label2">{{ registrationInfo.city }}</label>
              </div>
              <div class="block-content-line-item">
                <label class="label1">所属行业:</label>
                <label class="label2">{{ registrationInfo.industryType }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">个人简历:</label>
                <label class="label2">{{ registrationInfo.resume }}</label>
              </div>
            </div>
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">获得的荣誉及发表的论文:</label>
                <label class="label2">{{ registrationInfo.honor }}</label>
              </div>
            </div>
          </div>
      </div>
      <div class="block" style="position:relative;margin-top:20px;background-color: #fff;">
        <div class="block-content-line flex">
          <div class="block-content-line-item" style="margin-bottom: 0px;">
            <label class="label1">个人状态:</label>
            <label class="label2">
              <dict-tag :options="dict.type.customer_state" :value="registrationInfo.state"/>
            </label>
          </div>
        </div>
        <div class="block-content-line flex" v-if="registrationInfo.state!='-1'" >
          <div class="block-content-line-item">
            <label class="label1">审核意见:</label>
            <label class="label2">{{ registrationInfo.comments }}</label>
          </div>
        </div>
      </div>
      <div style="margin-top:20px;">
        <el-button type="default" class="register-btn" @click="goEdit" >修改信息</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import {getCustomerUserById} from "@/api/register";
import {isEmpty} from '@/utils/validator'
export default {
  name: "user_detail",
  dicts: ['news_pub_state','sys_user_sex','customer_state','consumer_yes_no'],
  components: {},
  data() {
    return {
      registrationInfo:{},
      certPicList:[],
      certPicEmblemList:[]
    }
  },
  mounted() {
    let id = this.$route.query.id
    if(isEmpty(id)){
      id = this.$store.getters.loginId
    }
    this.registrationInfo.id=id
    this.getCustomerUserById(id)
  },
  watch:{
  },
  methods: {
   // 获取详情
   getCustomerUserById(id) {
      getCustomerUserById(id).then(res => {
          this.registrationInfo=res.data
          this.certPicList=res.certPicList
          this.certPicList.forEach(pic => {
            const srcList=[]
            srcList.push(pic.url);
            pic.preList=srcList
         })
         this.certPicEmblemList=res.certPicEmblemList
          this.certPicEmblemList.forEach(pic => {
            const srcList=[]
            srcList.push(pic.url);
            pic.preList=srcList
         })
      });
    },
    goEdit(){
      // 跳转到登录页
      this.$router.push({
        path:'/menu_page/customer/user_edit?id='+ this.registrationInfo.id
      })
    }
  }
};
</script>

<!-- <style scoped lang="scss"> -->
<style scoped>
/deep/.header .main-menu{
  display: none !important;
}
.flex{
  display:flex;
}
.block-title{
  margin-top:50px;
}
.block-content{
  line-height: 50px;
}
.block-content-line-item{margin:20px 0px;flex:4;display: flex;}
/deep/ .block-content-line-item .el-select{width:100%;}
/deep/ .block-content-line .el-form-item__label{padding-left: 30px;}
/deep/ .bcli-lh18  .el-form-item__label, .lh18{line-height: 18px;}
/deep/ .label-center .el-form-item__label{text-align: center;}
.label1{
  line-height: 24px;min-width: 150px;padding-left: 30px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #333333; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2{
  word-break:break-all;line-height: 24px;padding-left: 30px;text-align: left;vertical-align: middle;float: left;font-size: 14px;color: #666666; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2 span{
  margin:0px 20px;
}

.login-code{
  width: 60px;
  height: 38px;
  img {
    cursor: pointer;
    vertical-align: middle; 
  }
 }
.login-code-img {
  height: 32px;
  margin-left: 10px;
}
.login-btn{
  height: 32px;
  margin-left: 10px;
}

.register-btn{
  margin-bottom: 10px;
  margin-left: 50%;
}
</style>