<template>
  <div class="main-body">
    <div class="title-img">
      <img src="@/assets/img/banner-xwzx.png" height="" width="100%" style="min-width:1200px">
    </div>
    <div class="c-1200" style="margin:0 auto;margin-bottom:30px;">
      <div class="nav-box">
        <i class="fa fa-map-pin nav-icon" aria-hidden="true"></i>
        <span class="nav-c">{{ getModuleName() }}</span>
        <span class="nav-g">/</span>
        <span class="nav-z">{{ getTypeName() }}</span>
      </div>
      <div>
        <el-row :gutter="30">
          <el-col :span="5">
            <div class="menu1">
              <img src="@/assets/img/news.png" height="" width="100%" v-if="moduleId==1">
              <img src="@/assets/img/sevice.png" height="" width="100%" v-if="moduleId==2">
              <img src="@/assets/img/tech.png" height="" width="100%" v-if="moduleId==3">
              <img src="@/assets/img/hyfc.png" height="" width="100%" v-if="moduleId==7">
              <img src="@/assets/img/tzgg.png" height="" width="100%" v-if="moduleId == 8">
            </div>
            <!-- <div class="menu2">
              <div class="menu2-item">
                焦点新闻
              </div>
              <div class="menu2-item cative">
                行业动态
              </div>
              <div class="menu2-item">
                工作动态
              </div>
              <div class="menu2-item">
                政策法规
              </div>
              <div class="menu2-item">
                酒业文化
              </div>
              <div class="menu2-item">
                党建风彩
              </div>
              <div class="menu2-item">
                通知公告
              </div>
              <div class="menu2-item">
                会员风彩
              </div>
            </div> -->
            <div  class="menu2">
              <el-menu
                :default-active="typeId"
                class="el-menu-vertical-demo"
                @select="selectMenu"
                background-color="#E5E5E5"
                text-color="#333333"
                text-align="center"
                active-text-color="#ffffff"
                font-size="18px" v-if="moduleId==1">
                <el-menu-item index="1" @click="toNews(1,1)">
                  <span slot="title" >焦点新闻</span>
                </el-menu-item>
                <el-menu-item index="2" @click="toNews(1,2)">
                  <span slot="title" >行业动态</span>
                </el-menu-item>
                <el-menu-item index="3" @click="toNews(1,3)">
                  <span slot="title" >工作动态</span>
                </el-menu-item>
                <el-menu-item index="4" @click="toNews(1,4)">
                  <span slot="title" >政策法规</span>
                </el-menu-item>
                <el-menu-item index="5" @click="toNews(1,5)">
                  <span slot="title" >酒业文化</span>
                </el-menu-item>
                <el-menu-item index="6" @click="toNews(1,6)">
                  <span slot="title" >党建风彩</span>
                </el-menu-item>
                <el-menu-item index="9" @click="toNews(1, 9)">
                    <span slot="title" >品牌营销</span>
                  </el-menu-item>
              </el-menu>
              <el-menu :default-active="typeId" class="el-menu-vertical-demo" @select="selectMenu" background-color="#E5E5E5"
                text-color="#333333" text-align="center" active-text-color="#ffffff" font-size="18px" v-if="moduleId==2">
                <el-menu-item index="1" @click="toNews(2,1)">
                  <span slot="title">人才培训</span>
                </el-menu-item>
                <el-menu-item index="2" @click="toNews(2,2)">
                  <span slot="title">品评酿造</span>
                </el-menu-item>
                <el-menu-item index="3" @click="toNews(2,3)">
                  <span slot="title">设备器皿</span>
                </el-menu-item>
                <el-menu-item index="4" @click="toNews(2, 4)">
                    <span slot="title">标准</span>
                  </el-menu-item>
              </el-menu>
              <el-menu :default-active="typeId" class="el-menu-vertical-demo" @select="selectMenu" background-color="#E5E5E5"
                text-color="#333333" text-align="center" active-text-color="#ffffff" font-size="18px" v-if="moduleId==3">
                <el-menu-item index="1" @click="toNews(3,1)">
                  <span slot="title">酿酒科技</span>
                </el-menu-item>
              </el-menu>
              <el-menu :default-active="typeId" class="el-menu-vertical-demo" @select="selectMenu" background-color="#E5E5E5"
                text-color="#333333" text-align="center" active-text-color="#ffffff" font-size="18px" v-if="moduleId==7">
                <el-menu-item index="1" @click="toNews(7,1)">
                  <span slot="title">会员风采</span>
                </el-menu-item>
              </el-menu>
              <el-menu :default-active="typeId" class="el-menu-vertical-demo" @select="selectMenu" background-color="#E5E5E5"
                  text-color="#333333" text-align="center" active-text-color="#ffffff" font-size="18px" v-if="moduleId == 8">
                  <el-menu-item index="1" @click="toNews(8, 1)">
                    <span slot="title">通知公告</span>
                  </el-menu-item>
                </el-menu>
            </div>
          </el-col>
          <el-col :span="19">
            <div style="background-color:#fff;padding:20px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
              <!-- <div>
                <el-row :gutter="20">
                  <el-col :span="16">
                    <img :src="hotNews[0] == null ? noData : hotNews[0].coverPath" height="360px" width="600pc" >
                  </el-col>
                  <el-col :span="8">
                    <img :src="hotNews[1] == null ? noData : hotNews[1].coverPath" height="172px" width="100%" style="margin-bottom:10px">
                    <img :src="hotNews[2] == null ? noData : hotNews[2].coverPath" height="172px" width="100%">
                  </el-col>
                </el-row>
              </div> -->
              <div v-for="record in news" :key="record.id">
                <div class="article-item">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <img src="@/assets/img/default-logo.png" v-if="record.coverPath==null">
                      <img :src="record.coverPath" v-else>
                    </el-col>
                    <el-col :span="18" >
                      <div class="title" @click="toNewsDetail(record.id, typeId)" style="cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:670px;">{{record.title}}</div>
                      <div class="content" style="height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;">{{ record.description }}</div>
                      <div class="time">{{record.addTime}}</div>
                    </el-col>
                  </el-row>
                </div>
                <hr data-v-15cb1fda="" style="width: 100%; text-align: center; border-top: 2px dotted #ccc;margin:20px 0px">
              </div>
              <div style="text-align:right;color:#999">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page.sync="queryParams.pageNum"
                  :page-size="10"
                  layout="prev, pager, next, jumper"
                  :total="total">
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { listRecord } from '@/api/news'
import noData from '@/assets/img/no-data.png'
export default {
  name: "news_list",
  components: {},
  data() {
    return {
      total:0,
      noData: noData,
      dataHint: {
        module1: {
          name: '新闻资讯',
          types: {
            type1: { name: '焦点新闻' },
            type2: { name: '行业动态' },
            type3: { name: '政策法规' },
            type4: { name: '工作动态' },
            type5: { name: '酒业文化' },
            type6: { name: '党建工作' },
            type7: { name: '通知公告' },
            type8: { name: '会员风采' },
            type9: { name: '品牌营销' }
          }
        },
        module2: {
          name: '技术服务',
          types: {
            type1: { name: '人才培育' },
            type2: { name: '品评酿造' },
            type3: { name: '设备器皿' },
            type4: { name: '标准' }
          }
        },
        module3: {
          name: '酿酒科技',
          types: {
            type1: { name: '酿酒科技' },
          }
        },
        module7: {
          name: '会员风采',
          types: {
            type1: { name: '会员风采' },
          }
        },
        module8: {
          name: '通知公告',
          types: {
            type1: { name: '通知公告' },
          }
        }
      },
      moduleId: 1,
      type: 1,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        moduleId: 1,
        typeId: null,
        orderByColumn: 'addTime_',
        isAsc: null
      },
      hotNews: [],
      news:[]
    };
  },
  created() {
    const moduleId = this.$route.query.module;
    const typeId = this.$route.query.type;
    this.moduleId = moduleId
    this.typeId = typeId
    this.loadNews()
  },
  watch: {
    $route() {
      const moduleId = this.$route.query.module;
      const typeId = this.$route.query.type;
      this.moduleId = moduleId
      this.typeId = typeId
      this.loadNews()
    }
  },
  methods: {
    selectMenu() { 

    },
    loadNews() { 
      const queryParams = { pageNum: this.queryParams.pageNum, pageSize: 10, moduleId: this.moduleId, typeId: this.typeId, orderByColumn: 'addTime_', isAsc: 'desc' };
      const queryParamsHot = { pageNum: this.queryParams.pageNum, pageSize: 3, moduleId: this.moduleId, typeId: this.typeId, orderByColumn: 'addTime_', isAsc: 'desc' ,isHot:1};
      listRecord(queryParams).then(res => { 
        this.news = res.rows
        this.total = res.total;
      })
      listRecord(queryParamsHot).then(res => {
        this.hotNews = res.rows
      })
    },
    toNewsDetail(id,from) { 
      this.$router.push({
        path: '/menu_page/news_detail',
        query: { id: id,from:from}
      })
    },
    toNews(module, type) {
      //window.location.href = '/menu_page/news_list?module=' + module + '&type=' + type
      this.$router.push({
        path: '/menu_page/news_list',
        query: { module: module, type: type }
      })
    },
    handleCurrentChange(page) { 
      this.queryParams.pageNum = page
      this.loadNews();
    },
    toPage(url, param) {
      this.$router.push({
        path: url,
        query: param
      })
    },
    getModuleName() { 
      const moduleInfo = this.dataHint['module' + this.moduleId];
      return moduleInfo.name;
    },
    getTypeName() { 
      const moduleInfo = this.dataHint['module' + this.moduleId];
      const typeInfo = moduleInfo.types['type' + this.typeId];
      return typeInfo.name
    }
  }
};
</script>
<!-- <style scoped lang="scss"> -->
<style scoped >
.menu2 {
  background-color: #E5E5E5;
}
/* .menu2-item{
  line-height: 50px;
  color:#333333;
  text-align: center;
}

.menu2 .cative{
  background-color: #FE0000;
  color: #ffffff;
  position: relative;
  }
.menu2 .cative:after {
    position: absolute;
    right:0;
    top: 0;
    margin-right: -25px;
    display: block;
    content: '';
    border-width: 25px 0px 25px 0px;
    border-style: solid;
    position: absolute;
    border-left: 25px solid #FE0000;
    } */
/deep/.menu2 .el-menu-item{
  text-align: center;
  font-size: 18px;
}
/* /deep/.menu2 .el-menu-item:hover{
  background-color: #E5E5E5 !important;
  color:#FE0000 !important;
} */
/deep/.menu2 .el-menu-item.is-active{
  background-color:#FE0000 !important;
}
/deep/.menu2 .el-menu-item.is-active:after {
    position: absolute;
    right:0;
    top: 0;
    margin-right: -25px;
    display: block;
    content: '';
    border-width: 28px 0px 28px 0px;
    border-style: solid;
    position: absolute;
    border-left: 25px solid #FE0000;
    background-color: #ffffff;
    }


    .article-item{
      margin-bottom:20px;
    }
    .article-item img{
    height:120px;
    width:100%;
    border:1px solid #eee
    }
    .article-item .title{
      color:#333333;
      font-size: 16px;
    }
    .article-item .content{
      color:#555555;
      font-size: 14px;
      margin-top:5px;
    }
    .article-item .time{
      color:#999999;
      font-size: 14px;
      position: absolute;
      bottom: 10px;
    }

    /deep/.el-pagination,/deep/.el-icon-more:before,/deep/.el-pagination .btn-next,/deep/.el-pagination .btn-prev{
      color: #999999 !important;
    }
</style>