<template>
    <div class="main-body" >
      <div class="c-1200" style="margin-bottom:30px;">

        <div class="nav-box">
          <i class="fa fa-map-pin nav-icon" style="color:#999;padding:0px 10px;" aria-hidden="true"></i>
          <span class="nav-c">
            <!-- {{ getModuleName() }} -->
            我的
          </span>
          <span class="nav-g">/</span>
          <span class="nav-c">
            <!-- {{getTypeName()}} -->
            我的报名
          </span>
          <span class="nav-g">/</span>
          <span class="nav-z">
            {{ signInfo.courseInfo.courseName}}
            <!-- 白酒二级评酒师2023年课程 -->
          </span>
        </div>
        <div style="background-color:#fff;padding:50px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
          <div style="text-align:center;padding-top: 20px;font-size: 20px;">
            <span>{{signInfo.courseInfo.courseName}}</span>
          </div>
          <div>
              <div class="block">
                <div class="block-title">
                  报名信息
                </div>
                <div class="block-content">
                  <div class="block-content-line">
                    <div class="block-content-line-item">
                      <el-row>
                        <el-col>

                          <label class="label1">课程:</label>
                            <label class="label2"><span v-text="signInfo.courseInfo.courseName"></span></label>
                        </el-col>
                      </el-row>
                    </div>
                    <!-- <div class="block-content-line-item">
                    </div> -->
                  </div>
                  
                  <div v-if="signInfo.courseInfo.courseService.lodgings== '1'" class="block-content-line">
                    <div class="block-content-line-item">
                      <el-row>
                        <el-col>
                          <label class="label1">是否需要住宿:</label>
                      <label class="label2"><span>{{ parseTime(signInfo.courseInfo.courseService.lodgingStartTime, '{y}-{m}-{d}') }}</span> 至 <span>{{ parseTime(signInfo.courseInfo.courseService.lodgingEndTime, '{y}-{m}-{d}') }}</span>，住宿费需自费<span v-if="signInfo.courseInfo.courseService.lodgingPrice">,标间价格 {{ signInfo.courseInfo.courseService.lodgingPrice }}（元/天）</span><span v-if="signInfo.courseInfo.courseService.lodgingPrice2">,大床房价格 {{ signInfo.courseInfo.courseService.lodgingPrice2 }}（元/天）</span></label>
                      <label class="label3"> <dict-tag  :options="dict.type.needs" :value="signInfo.lodgings"/></label>
                        </el-col>
                      </el-row>
                      
                    </div>
                  </div>
                  
                  <div v-if="signInfo.courseInfo.courseService.lunchs== '1'" class="block-content-line">
                    <div class="block-content-line-item">
                      <el-row>
                        <el-col></el-col>
                      </el-row>
                      <label class="label1">是否需午餐:</label>
                      <label class="label2"><span>{{ parseTime(signInfo.courseInfo.courseService.lunchStartTime, '{y}-{m}-{d}') }}</span> 至 <span>{{ parseTime(signInfo.courseInfo.courseService.lunchEndTime, '{y}-{m}-{d}') }}</span>，餐费<span v-text="signInfo.courseInfo.courseService.lunchPrice"></span>元/人/餐</label>
                      <label class="label3"> <dict-tag :options="dict.type.needs" :value="signInfo.lunchs"/></label>
                    </div>
                  </div>
                  <div  v-if="signInfo.lunchs == '1' && signInfo.courseInfo.courseService.lunchs== '1'" class="block-content-line">
                    <div class="block-content-line-item">
                      <el-row>
                        <el-col>
                          <label class="label1">午餐时间:</label>
                      <label class="label2"><span>{{ parseTime(signInfo.lunchStartTime, '{h}:{m}') }}</span> 至 <span>{{ parseTime(signInfo.lunchEndTime, '{h}:{m}') }}</span></label>
                        </el-col>
                      </el-row>
                      
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseService.dinners== '1'" class="block-content-line">
                    <div class="block-content-line-item">
                      <el-row>
                        <el-col>
                          <label class="label1">是否需要晚餐:</label>
                      <label class="label2"><span>{{ parseTime(signInfo.courseInfo.courseService.dinnerStartTime, '{y}-{m}-{d}') }}</span> 至 <span>{{ parseTime(signInfo.courseInfo.courseService.dinnerEndTime, '{y}-{m}-{d}') }}</span>，餐费<span v-text="signInfo.courseInfo.courseService.dinnerPrice"></span>元/人/餐</label>
                      <label class="label3"> <dict-tag :options="dict.type.needs" :value="signInfo.dinners"/></label>
                        </el-col>
                      </el-row>
                      
                    </div>
                  </div>
                  <div v-if="signInfo.dinners == '1' && signInfo.courseInfo.courseService.dinners== '1'" class="block-content-line">
                    <div class="block-content-line-item">
                      <el-row>
                        <el-col>
                          <label class="label1">晚餐时间:</label>
                          <label class="label2"><span>{{ parseTime(signInfo.dinnerStartTime, '{h}:{m}') }}</span> 至 <span>{{ parseTime(signInfo.dinnerEndTime, '{h}:{m}') }}</span></label>
                        </el-col>
                      </el-row>
                      </div>
                  </div>
                </div>
              </div>
              <div class="block">
                <div class="block-title">
                  基础信息
                </div>
                <div class="block-content">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <div>
                      <div v-if="signInfo.courseInfo.courseConfig.name == 1"  class="block-content-line flex">
                        <div class="block-content-line-item">
                        <label class="label1">姓名:</label>
                        <label class="label2"><span v-text="signInfo.name"></span></label>
                        </div>
                        <!-- <div class="block-content-line-item">
                        </div> -->
                    </div>
                      <div v-if="signInfo.courseInfo.courseConfig.gender == 1" class="block-content-line flex">
                          <div class="block-content-line-item">
                            <label class="label1">性别:</label>
                            <label class="label2"><dict-tag :options="dict.type.sys_user_sex" :value="signInfo.sex"/></label>
                          </div>
                          <!-- <div class="block-content-line-item">
                          </div> -->
                      </div>
                      <div  v-if="signInfo.courseInfo.courseConfig.phone == 1" class="block-content-line flex">
                        <div class="block-content-line-item">
                            <label class="label1">手机号码:</label>
                            <label class="label2"><span v-text="signInfo.phone"></span></label>
                          </div>
                          <!-- <div class="block-content-line-item">
                          </div> -->
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.edu == 1" class="block-content-line flex">
                        
                        <div class="block-content-line-item">
                            <label class="label1">文化程度:</label>
                        <label class="label2"><dict-tag :options="dict.type.edu" :value="signInfo.edu"/></label>
                          </div>
                          <!-- <div class="block-content-line-item">
                          </div> -->
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.idCard == 1" class="block-content-line flex">
                        
                        <div class="block-content-line-item">
                            <label class="label1">身份证号:</label>
                        <label class="label2"><span v-text="signInfo.idCard"></span></label>
                          </div>
                          <!-- <div class="block-content-line-item">
                          </div> -->
                      </div>
                      <div  v-if="signInfo.courseInfo.courseConfig.address == 1"  class="block-content-line flex">
                        
                        <div class="block-content-line-item">
                            <label class="label1">联系地址:</label>
                        <label class="label2"><span v-text="signInfo.address"></span></label>
                          </div>
                          <!-- <div class="block-content-line-item">
                          </div> -->
                      </div>
                    </div>
                    </el-col>
                    <el-col :span="12">
                      <div v-if="signInfo.courseInfo.courseConfig.photo == 1"  style="flex:5">
                      <div>
                        <div style="padding:0px;">
                          <img v-if="signInfo.photo" :src="signInfo.photo" class="avatar">
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </div>
                      </div>
                    </div>
                    </el-col>
                  </el-row>
                    
                    
                </div>
              </div>
              <div class="block">
                <div class="block-title">
                  工作&证书
                </div>
                <div class="block-content" >
                    <div class="block-content-line">
                      <el-row :gutter="20">
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.company == 1" >
                          <label class="label1">工作单位</label>
                          <label class="label2"><span v-text="signInfo.company"></span></label>
                        </el-col>
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.job == 1">
                          <label class="label1">职位</label>
                          <label class="label2"><span v-text="signInfo.job"></span></label>
                        </el-col>
                      </el-row>
                      <!-- <div v-if="signInfo.courseInfo.courseConfig.company == 1"  class="block-content-line-item">
                        <label class="label1">工作单位</label>
                        <label class="label2"><span v-text="signInfo.company"></span></label>
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.job == 1" class="block-content-line-item">
                        <label class="label1">职位</label>
                        <label class="label2"><span v-text="signInfo.job"></span></label>
                      </div> -->
                    </div>
                    <div  class="block-content-line">
                      <el-row :gutter="20">
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.jobTime == 1">
                          <label class="label1">参加工作时间</label>
                          <label class="label2"><span>{{ parseTime(signInfo.jobTime, '{y}-{m}-{d}') }}</span></label>
                        </el-col>
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.post == 1">
                          <label class="label1">现工作岗位</label>
                          <label class="label2"><span v-text="signInfo.post"></span></label>
                        </el-col>
                      </el-row>

                      <!-- <div v-if="signInfo.courseInfo.courseConfig.jobTime == 1" class="block-content-line-item">
                        <label class="label1">参加工作时间</label>
                        <label class="label2"><span>{{ parseTime(signInfo.jobTime, '{y}-{m}-{d}') }}</span></label>
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.post == 1" class="block-content-line-item">
                          <label class="label1">现工作岗位</label>
                          <label class="label2"><span v-text="signInfo.post"></span></label>
                      </div> -->
                    </div>
                    <div class="block-content-line">
                      <el-row :gutter="20">
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.workYear == 1">
                          <label class="label1">从事本职业(工种)技术工作年限</label>
                          <label class="label2"><span v-text="signInfo.workYear"></span></label>
                        </el-col>
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.workAge == 1">
                          <label class="label1">从事本职业(工种)工龄</label>
                          <label class="label2"><span v-text="signInfo.workAge"></span></label>
                        </el-col>
                      </el-row>

                      <!-- <div v-if="signInfo.courseInfo.courseConfig.workYear == 1" class="block-content-line-item">
                        <label class="label1">从事本职业(工种)技术工作年限</label>
                          <label class="label2"><span v-text="signInfo.workYear"></span></label>
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.workAge == 1" class="block-content-line-item">
                        <label class="label1">从事本职业(工种)工龄</label>
                          <label class="label2"><span v-text="signInfo.workAge"></span></label>
                      </div> -->
                    </div>
                    <div class="block-content-line">
                      <el-row :gutter="20">
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.grade == 1">
                          <label class="label1">原证书职业名称及等级</label>
                          <label class="label2"><span v-text="signInfo.grade"></span></label>
                        </el-col>
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.certificateNum == 1">
                          <label class="label1">原证书编号</label>
                          <label class="label2"><span v-text="signInfo.certificateNum"></span></label>
                        </el-col>
                      </el-row>
                      <!-- <div v-if="signInfo.courseInfo.courseConfig.grade == 1" class="block-content-line-item">
                          <label class="label1">原证书职业名称及等级</label>
                          <label class="label2"><span v-text="signInfo.grade"></span></label>
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.certificateNum == 1" class="block-content-line-item">
                        <label class="label1">原证书编号</label>
                          <label class="label2"><span v-text="signInfo.certificateNum"></span></label>
                      </div> -->
                    </div>
                    <div class="block-content-line">
                      <el-row :gutter="20">
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.declarationApplyJob == 1">
                          <label class="label1">申报职业</label>
                          <label class="label2"><span v-text="signInfo.declarationApplyJob"></span></label>
                        </el-col>
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.declarationApplyGrade == 1">
                          <label class="label1">申报等级</label>
                          <label class="label2"><span v-text="signInfo.declarationApplyGrade"></span></label>
                        </el-col>
                      </el-row>
                      <!-- <div v-if="signInfo.courseInfo.courseConfig.declarationApplyJob == 1" class="block-content-line-item">
                    
                        <label class="label1">申报职业</label>
                          <label class="label2"><span v-text="signInfo.declarationApplyJob"></span></label>
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.declarationApplyGrade == 1" class="block-content-line-item">
                      
                        <label class="label1">申报等级</label>
                          <label class="label2"><span v-text="signInfo.declarationApplyGrade"></span></label>
                      </div> -->
                    </div>
                    <div class="block-content-line">
                      <el-row :gutter="20">
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.certificateDate == 1">
                          <label class="label1">原证书时间</label>
                          <label class="label2"><span>{{ parseTime(signInfo.certificateDate, '{y}-{m}-{d}') }}</span></label>
                        </el-col>
                        <el-col :span="12" v-if="signInfo.courseInfo.courseConfig.awards == 1">
                          <label class="label1">获奖情况</label>
                          <label class="label2"><span v-text="signInfo.awards"></span></label>
                        </el-col>
                      </el-row>
                      <!-- <div v-if="signInfo.courseInfo.courseConfig.certificateDate == 1" class="block-content-line-item">
                        <label class="label1">原证书时间</label>
                        <label class="label2"><span>{{ parseTime(signInfo.certificateDate, '{y}-{m}-{d}') }}</span></label>
                      </div>
                      <div v-if="signInfo.courseInfo.courseConfig.awards == 1" class="block-content-line-item">
                        <label class="label1">获奖情况</label>
                          <label class="label2"><span v-text="signInfo.awards"></span></label>
                      </div> -->
                    </div>
                  <div v-if="signInfo.courseInfo.courseConfig.workExperience == 1" class="block-content-line">
                    <el-row>
                      <el-col>
                    <label class="label1">工作经历</label>
                    <label class="label2" style="width:calc(100% - 150px);border:1px solid #eeeeee;margin-top:10px">
                      <div class="block-content-line-item">
                        <el-table
                          :data="signInfo.workExperienceList"
                          style="width: 100%">
                          <el-table-column
                            prop="dateRane"
                            label="起止时间">
                            <template slot-scope="scope">
                              <span>{{ parseTime(scope.row.startTime, '{y}-{m}-{d}') }}</span> 至 <span>{{ parseTime(scope.row.endTime, '{y}-{m}-{d}') }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="company"
                            label="何地何部门">
                          </el-table-column>
                          <el-table-column
                            prop="job"
                            label="职业(工种)">
                          </el-table-column>
                          <el-table-column
                            prop="witness"
                            label="证明人">
                          </el-table-column>
                  
                        </el-table>
                      
                      </div>
                    </label>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <div class="block">
                <el-row>
                  <el-col>
                    <div class="block-title">
                      附件
                    </div>
                  </el-col>
                </el-row>
                <div class="block-content" >
                  <div v-if="signInfo.courseInfo.courseConfig.appendixCertificate == 1 && signInfo.appendixMap.appendixCertificate" class="block-content-line" style="margin-bottom: 20px;">
                    <label class="label1">原证书正面</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixCertificate" :key="appendix">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="appendix"></el-image>
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseConfig.appendixCertificate == 1 && signInfo.appendixMap.appendixCertificate2" class="block-content-line" style="margin-bottom: 20px;">
                    <label class="label1">原证书背面</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixCertificate2" :key="appendix">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="appendix"></el-image>
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseConfig.appendixIdCard == 1  && signInfo.appendixMap.appendixIdCard"  class="block-content-line" style="margin-bottom: 20px;">
                    <label class="label1">身份证正面</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixIdCard" :key="appendix">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="appendix"></el-image>
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseConfig.appendixIdCard == 1  && signInfo.appendixMap.appendixIdCard2"  class="block-content-line" style="margin-bottom: 20px;">
                    <label class="label1">身份证反面</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixIdCard2" :key="appendix">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="appendix"></el-image>
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseConfig.appendixEdu == 1 && signInfo.appendixMap.appendixEdu"  class="block-content-line" style="margin-bottom: 20px;">
                    <label class="label1">学历证明</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixEdu" :key="appendix">
                        <el-image
                        style="width: 100px; height: 100px"
                        :src="appendix"></el-image>
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseConfig.appendixCompany == 1 && signInfo.appendixMap.appendixCompany"  class="block-content-line" style="margin-bottom: 20px;">
                    <label class="label1">单位证明</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixCompany" :key="appendix">
                        <el-link type="success" :href="appendix">{{ appendix }}</el-link>
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseConfig.appendixApply == 1 && signInfo.appendixMap.appendixApply"  class="block-content-line" style="margin-bottom: 20px;">
                    <label class="label1">报名申请</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixApply" :key="appendix">
                        <el-link type="success" :href="appendix">{{ appendix }}</el-link>
                    </div>
                  </div>
                  <div v-if="signInfo.courseInfo.courseConfig.appendixOther == 1 && signInfo.appendixMap.appendixOther"  class="block-content-line" style="margin-bottom: 20px;">
                    
                    <label class="label1">其他证明</label>
                    <div class="block" v-for="appendix in signInfo.appendixMap.appendixOther" :key="appendix">
                        <el-link type="success" :href="appendix">{{ appendix }}</el-link>
                    </div>
                      
                  </div>
                </div>
              </div>
          </div>

          <el-dialog :visible.sync="open" width="500px" append-to-body title="报名审核">
            <el-form ref="formJudge" :model="formJudge" :rules="rulesJudge" size="medium" label-width="100px">
              <el-form-item label="是否通过" prop="status">
                <el-radio-group v-model="formJudge.status" size="medium">
                  <el-radio v-for="(item, index) in statusOptions" :key="index" :label="item.value"
                    :disabled="item.disabled">{{item.label}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="审核备注" prop="remark">
                <el-input v-model="formJudge.remark" type="textarea" placeholder="请输入审核备注"
                  :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer">
              <el-button @click="close">取消</el-button>
              <el-button type="primary" @click="handleConfirm">确定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getSignRecord } from "@/api/courses/signRecord"
  
  export default {
    name: "train_exam_registration",
    components: {},
    dicts: ['needs','sys_user_sex','edu'],
    data() {
  
      return {
        open: false,
        recordId: null,
        statusOptions: [{
            "label": "通过",
            "value": 2
          }, {
            "label": "退回",
            "value": 3
          }],
        formJudge: {
          recordId: null,
          status: 2,
          remark: null,
        },
        rulesJudge: {
        status: [{
          required: true,
          message: '是否通过不能为空',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入审核备注',
          trigger: 'blur'
        }],
      },
        signInfo:{
        id: null,
        userId: null,
        courseId: null,
        courseName: null,
        courseType: null,
        name: 1,
        sex: 0,
        phone: null,
        edu: null,
        idCard: null,
        address: null,
        photo: null,
        company: null,
        job: null,
        jobTime: null,
        post: null,
        workYear: null,
        workAge: null,
        grade: null,
        certificateNum: null,
        declarationApplyJob: null,
        declarationApplyGrade: null,
        certificateDate: null,
        awards: null,
        lodgings: null,
        lunchs: null,
        lunchStartTime: null,
        lunchEndTime: null,
        dinners: null,
        dinnerStartTime: null,
        dinnerEndTime: null,
        status: null,
        courseInfo: {
            id: null,
            courseName: null,
            courseType: null,
            teacher: null,
            startTime: null,
            endTime: null,
            courseService: {
            params: {},
            id: null,
            courseId: null,
            lodgingPrice: null,
            lodgingStartTime: null,
            lodgingEndTime: null,
            lunchPrice: 55,
            lunchStartTime: null,
            lunchEndTime: null,
            dinnerPrice: null,
            dinnerStartTime: null,
            dinnerEndTime: null
            },
            courseConfig: {
            courseId: 1,
            name: 1,
            gender: 1,
            phone: 1,
            edu: 1,
            idCard: 1,
            address: 1,
            photo: 1,
            company: 1,
            job: 1,
            jobTime: 1,
            post: 1,
            workYear: 1,
            workAge: 1,
            grade: 1,
            certificateNum: 1,
            declarationApplyJob: 1,
            declarationApplyGrade: 1,
            certificateDate: 1,
            awards: 2,
            workExperience: 1,
            appendixCertificate: 1,
            appendixIdCard: 1,
            appendixEdu: 1,
            appendixCompany: 1,
            appendixApply: 1,
            appendixOther: 1
            }
        },
        appendixMap: {
            
        },
        workExperienceList: [
            {
            recordId: null,
            startTime: null,
            endTime: null,
            company: null,
            job: null,
            witness: null
            }
        ]
        },  
       };
    },
    created(){
      this.recordId =  this.$route.query.recordId;
      this.getDetail(this.recordId);
    },
    watch:{
    },
    methods: {
      getDetail(recordId){
        getSignRecord(recordId).then(response => {
            this.signInfo = response.data;
          });
      } ,
      judge(){
        this.open = true
      },
    onClose() {
      this.$refs['formJudge'].resetFields()
      this.open = false
    },
    close() {
      this.open = false
    },
    handleConfirm() {
      this.$refs['formJudge'].validate(valid => {
        if (valid){
          this.formJudge.recordId = this.recordId;
          auditSign(this.formJudge).then(response => {
            console.log(response.data);
            this.$message({
              message: `处理成功`,
              type: 'success'
            });
            this.signInfo.status = 2;
          });
        }
        this.close()
      })
    },
    },
  };
  </script>
  <!-- <style scoped lang="scss"> -->
  <style scoped >
  
  .flex{
    display:flex;
  }
  .block-title{
    margin-top:50px;
  }
  /* .block-content-line-item{flex:4} */
  /deep/ .block-content-line-item .el-select{width:100%;}
  /deep/ .block-content-line .el-form-item__label{padding-left: 30px;}
  /deep/ .bcli-lh18  .el-form-item__label{line-height: 18px;}
  /* /deep/ .block-content-line-item .el-form-item__content{margin-left: 120px !important;} */
  /deep/ .label-center .el-form-item__label{text-align: center;}
  .label1{
    width: 150px;padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
  }
  .label2{
    padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
  }
  .label2 span{
    padding-left:5px;padding-right: 5px;text-decoration: underline;
  }
  .label3{
    line-height: 32px;
  }
  /*******************/
  /deep/ .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  /deep/  .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 175px;
      height: 225px;
      line-height: 225px;
      text-align: center;
    }
    .avatar {
      width: 175px;
      height: 228px;
      display: block;
    }
  
  </style>