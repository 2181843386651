import { render, staticRenderFns } from "./my_index.vue?vue&type=template&id=19ae5912&scoped=true&"
import script from "./my_index.vue?vue&type=script&lang=js&"
export * from "./my_index.vue?vue&type=script&lang=js&"
import style0 from "./my_index.vue?vue&type=style&index=0&id=19ae5912&prod&rel=stylesheet%2Fscss&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ae5912",
  null
  
)

export default component.exports