<template>
  <div class="main-body" >
    <div class="c-1200" style="margin-bottom:30px;">

      <div class="nav-box">
        <i class="fa fa-map-pin nav-icon" aria-hidden="true"></i>
        <span class="nav-c">
          <!-- {{ getModuleName() }} -->
          我的
        </span>
        <span class="nav-g">/</span>
        <span class="nav-c">
          <!-- {{getTypeName()}} -->
          我的报名
        </span>
        <span class="nav-g">/</span>
        <span class="nav-z">
          《{{ courseInfo.courseName}}》报名
          <!-- 培训考试列表 -->
        </span>
      </div>
    <div  style="background-color:#fff;padding:50px;box-shadow:0px 0px 8px 0px #ddd;border-radius: 3px;">
      <el-form ref="signInfo" :model="signInfo" :rules="rules" label-width="150px" size="small">
        <div class="block">
          <div class="block-title">
            基础信息
          </div>
          <div class="block-content" style="display:flex;">
              <div style="flex:6">
                <div v-if="courseInfo.courseConfig.name == 1" class="block-content-line">
                  <el-form-item label="姓名（必填）" prop="name">
                    <el-input v-model="signInfo.name" clearable class="e-input" placeholder="请输入姓名" />
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.gender == 1" class="block-content-line">
                  <el-form-item label="性别（必填）" prop="sex">
                      <el-select v-model="signInfo.sex" placeholder="请选择" clearable>
                        <el-option
                          v-for="dict in dict.type.sys_user_sex"
                          :key="dict.value"
                          :label="dict.label"
                          :value="dict.value"
                        />
                      </el-select>
                  </el-form-item>
                </div>
                <div  v-if="courseInfo.courseConfig.phone == 1" class="block-content-line">
                  <el-form-item label="手机号码" prop="phone">
                    <el-input v-model="signInfo.phone" clearable class="e-input" placeholder="请输入手机号码" />
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.edu == 1" class="block-content-line">
                  <el-form-item label="文化程度" prop="edu">
                    <el-select v-model="signInfo.edu" placeholder="请选择" clearable>
                        <el-option
                        v-for="item in degreeEnum"
                        :key="item.code"
                        :label="item.info"
                        :value="item.code">
                        </el-option>
                      </el-select>
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.idCard == 1" class="block-content-line">
                  <el-form-item label="身份证号" prop="idCard">
                    <el-input v-model="signInfo.idCard" clearable class="e-input" placeholder="请输入身份证号" />
                  </el-form-item>
                </div>
                <div  v-if="courseInfo.courseConfig.address == 1"  class="block-content-line">
                  <el-form-item label="联系地址" prop="address">
                    <el-input v-model="signInfo.address" clearable class="e-input" placeholder="请输入联系地址" />
                  </el-form-item>
                </div>
              </div>
              <div v-if="courseInfo.courseConfig.photo == 1"  style="flex:4">
                <div>
                  <div style="padding:0px 100px;">
                    <!-- <img src="" alt="" style="width:175px;height:225px;"> -->
                    <el-upload
                      class="avatar-uploader"
                      action="/api/system/commonfile/upload"
                      :show-file-list="false"
                      :headers="headers"
                      :on-success="handleAvatarSuccess">
                      <img v-if="signInfo.photo" :src="signInfo.photo" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="block">
          <div class="block-title">
            报名信息
          </div>
          <div class="block-content">
            <div class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">课程</label>
                <label class="label2"><span v-text="courseInfo.courseName"></span></label>
              </div>
              <div class="block-content-line-item">
              </div>
            </div>
            
            <div v-if="courseInfo.courseService.lodgings =='1'" class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否需要住宿</label>
                <label class="label2"><span>{{ parseTime(courseInfo.courseService.lodgingStartTime, '{y}-{m}-{d}') }}</span> 至 <span>{{ parseTime(courseInfo.courseService.lodgingEndTime, '{y}-{m}-{d}') }}</span>，住宿费需自费<span v-if="courseInfo.courseService.lodgingPrice">,标间价格 {{ courseInfo.courseService.lodgingPrice }}（元/天）</span><span v-if="courseInfo.courseService.lodgingPrice2">,大床房价格 {{ courseInfo.courseService.lodgingPrice2 }}（元/天）</span></label>
                <el-form-item label="请选择" prop="lodgings">
                  <el-select v-model="signInfo.lodgings" placeholder="是否需要住宿" clearable style="width:100px !important">
                    <el-option
                      v-for="dict in dict.type.needs"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
            
            <div v-if="courseInfo.courseService.lunchs =='1'" class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否需午餐</label>
                <label class="label2"><span>{{ parseTime(courseInfo.courseService.lunchStartTime, '{y}-{m}-{d}') }}</span> 至 <span>{{ parseTime(courseInfo.courseService.lunchEndTime, '{y}-{m}-{d}') }}</span>，餐费<span v-text="courseInfo.courseService.lunchPrice"></span>元/人/餐</label>
                <el-form-item label="请选择" prop="lunchs">
                  <el-select v-model="signInfo.lunchs" placeholder="是否需要晚餐" clearable style="width:100px !important">
                    <el-option
                      v-for="dict in dict.type.needs"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div  v-if="signInfo.lunchs == '1'" class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="午餐时间" prop="lunchRange">
                    <el-time-picker
                      is-range
                      v-model="signInfo.lunchRange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围">
                    </el-time-picker>
                </el-form-item>
              </div>
              <div class="block-content-line-item label-center">
                <!-- <el-form-item label="至" prop="name">
                  <el-input v-model="signInfo.name" clearable class="e-input" placeholder="" />
                </el-form-item> -->
              </div>
            </div>
            <div v-if="courseInfo.courseService.dinners =='1'" class="block-content-line flex">
              <div class="block-content-line-item">
                <label class="label1">是否需要晚餐</label>
                <label class="label2"><span>{{ parseTime(courseInfo.courseService.dinnerStartTime, '{y}-{m}-{d}') }}</span> 至 <span>{{ parseTime(courseInfo.courseService.dinnerEndTime, '{y}-{m}-{d}') }}</span>，餐费<span v-text="courseInfo.courseService.dinnerPrice"></span>元/人/餐</label>
                <el-form-item label="请选择" prop="dinners">
                  <el-select v-model="signInfo.dinners" placeholder="是否需要晚餐" clearable style="width:100px !important">
                    <el-option
                      v-for="dict in dict.type.needs"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div v-if="signInfo.dinners == '1'" class="block-content-line flex">
              <div class="block-content-line-item">
                <el-form-item label="晚餐时间" prop="dinnerRange">
                    <el-time-picker
                      is-range
                      v-model="signInfo.dinnerRange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围">
                    </el-time-picker>
                </el-form-item>
              </div>
              <div class="block-content-line-item label-center">
                <!-- <el-form-item label="至" prop="name">
                  <el-input v-model="signInfo.name" clearable class="e-input" placeholder="" />
                </el-form-item> -->
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="block-title">
            工作&证书
          </div>
          <div class="block-content" >
              <div class="block-content-line flex">
                <div v-if="courseInfo.courseConfig.company == 1"  class="block-content-line-item">
                  <el-form-item label="工作单位" prop="company">
                    <el-input v-model="signInfo.company" clearable class="e-input" placeholder="请输入工作单位" />
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.job == 1" class="block-content-line-item">
                  <el-form-item label="职位" prop="job">
                    <el-input v-model="signInfo.job" clearable class="e-input" placeholder="请输入职位" />
                  </el-form-item>
                </div>
              </div>
              <div  class="block-content-line flex">
                <div v-if="courseInfo.courseConfig.jobTime == 1" class="block-content-line-item">
                  <el-form-item label="参加工作时间" prop="jobTime">
                    <el-date-picker
                      v-model="signInfo.jobTime"
                      type="date"
                      placeholder="参加工作时间">
                    </el-date-picker>
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.post == 1" class="block-content-line-item">
                  <el-form-item label="现工作岗位" prop="post">
                    <el-input v-model="signInfo.post" clearable class="e-input" placeholder="请输入职位" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div v-if="courseInfo.courseConfig.workYear == 1" class="block-content-line-item">
                  <el-form-item label="从事本职业(工种)技术工作年限" prop="workYear" class="bcli-lh18">
                    <el-input-number v-model="signInfo.workYear" clearable :min="1" :max="100" label="从事本职业(工种)技术工作年限"></el-input-number>
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.workAge == 1" class="block-content-line-item">
                  <el-form-item label=" 从事本职业(工种)工龄" prop="workAge" class="bcli-lh18">
                    <el-input-number v-model="signInfo.workAge" clearable :min="1" :max="100" label="从事本职业(工种)工龄"></el-input-number>
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div v-if="courseInfo.courseConfig.grade == 1" class="block-content-line-item">
                  <el-form-item label="原证书职业名称及等级" prop="grade" class="bcli-lh18">
                    <el-input v-model="signInfo.grade" clearable class="e-input" placeholder="原证书职业名称及等级" />
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.certificateNum == 1" class="block-content-line-item">
                  <el-form-item label="原证书编号" prop="certificateNum">
                    <el-input v-model="signInfo.certificateNum" clearable class="e-input" placeholder="原证书编号" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div v-if="courseInfo.courseConfig.declarationApplyJob == 1" class="block-content-line-item">
                  <el-form-item label="申报职业" prop="declarationApplyJob">
                    <el-input v-model="signInfo.declarationApplyJob" clearable class="e-input" placeholder="申报职业" />
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.declarationApplyGrade == 1" class="block-content-line-item">
                  <el-form-item label="申报等级" prop="declarationApplyGrade">
                    <el-input v-model="signInfo.declarationApplyGrade" clearable class="e-input" placeholder="申报等级" />
                  </el-form-item>
                </div>
              </div>
              <div class="block-content-line flex">
                <div v-if="courseInfo.courseConfig.certificateDate == 1" class="block-content-line-item">
                  <el-form-item label="原证书时间" prop="certificateDate">
                    <el-date-picker
                      v-model="signInfo.certificateDate"
                      type="date"
                      placeholder="原证书时间">
                    </el-date-picker>
                  </el-form-item>
                </div>
                <div v-if="courseInfo.courseConfig.awards == 1" class="block-content-line-item">
                  <el-form-item label="获奖情况" prop="awards">
                    <el-input v-model="signInfo.awards" clearable class="e-input" placeholder="获奖情况" />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div v-if="courseInfo.courseConfig.workExperience == 1" class="block-content-line flex">
              <div class="block-content-line-item" >
                <el-form-item label="本人工作经历" prop="workExperienceList">
                  <div style="border:1px solid #eeeeee;">
                    <el-table 
                  :data="signInfo.workExperienceList"
                  style="width: 100%">
                  <el-table-column
                    prop="dateRane"
                    label="起止时间">
                    <template slot-scope="scope">
                    <el-date-picker
                      style="width: 100%" 
                      v-model="scope.row.dateRange"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="company"
                    label="何地何部门">
                    <template slot-scope="scope">
                    <el-input v-model="scope.row.company" clearable class="e-input" placeholder="何地何部门" />
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="job"
                    label="职业(工种)">
                    <template slot-scope="scope">
                    <el-input v-model="scope.row.job" clearable class="e-input" placeholder="请输入职业(工种)" />
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="witness"
                    label="证明人">
                    <template slot-scope="scope">
                    <el-input v-model="scope.row.witness" clearable class="e-input" placeholder="请输入证明人" />
                    </template>
                  </el-table-column>
           
                </el-table>
                  </div>
                
                </el-form-item>
                <div style="line-height:48px;margin-top:-18px;margin-left: 150px;font-size:16 px;text-align: center;color:#606266;cursor: pointer;" @click="addWE()">✚ 增加一条数据</div>
              </div>
            </div>
        </div>
        <div class="block">
          <div class="block-title">
            附件上传
          </div>
          <div class="block-content" >
            <div v-if="courseInfo.courseConfig.appendixCertificate == 1" class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="原证书正面" prop="appendixCertificate">
                <el-upload
                  class="upload-demo"
                  action="/api/system/commonfile/upload"
                  :on-success="successAppendixCertificate"
                  :on-remove="removeAppendixCertificate"
                  :before-upload= "handleBeforeUpload"
                  :file-list="appendixCertificateList"
                  multiple
                  :limit="1"
                  :headers="headers"
                  :on-exceed="handleExceed">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            </div>
            <div v-if="courseInfo.courseConfig.appendixCertificate == 1" class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="原证书背面" prop="appendixCertificate2">
                <el-upload
                  class="upload-demo"
                  action="/api/system/commonfile/upload"
                  :on-success="successAppendixCertificate2"
                  :on-remove="removeAppendixCertificate2"
                  :before-upload= "handleBeforeUpload"
                  :file-list="appendixCertificateList2"
                  multiple
                  :limit="1"
                  :headers="headers"
                  :on-exceed="handleExceed">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
            </el-form-item>
            </div>
            <div v-if="courseInfo.courseConfig.appendixIdCard == 1"  class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="身份证正面" prop="appendixIdCard">

                <el-upload
                  class="upload-demo"
                  action="/api/system/commonfile/upload"
                  :on-success="successAppendixIdCard"
                  :on-remove="removeAppendixIdCard"
                  :before-upload= "handleBeforeUpload"
                  :file-list="appendixIdCardList"
                  multiple
                  :limit="1"
                  :headers="headers"
                  :on-exceed="handleExceed">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>

              </el-form-item>
            </div>
            <div v-if="courseInfo.courseConfig.appendixIdCard == 1"  class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="身份证反面" prop="appendixIdCard2">

                <el-upload
                  class="upload-demo"
                  action="/api/system/commonfile/upload"
                  :on-success="successAppendixIdCard2"
                  :on-remove="removeAppendixIdCard2"
                  :before-upload= "handleBeforeUpload"
                  :file-list="appendixIdCardList2"
                  multiple
                  :limit="1"
                  :headers="headers"
                  :on-exceed="handleExceed">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>

              </el-form-item>
            </div>
            <div v-if="courseInfo.courseConfig.appendixEdu == 1"  class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="学历证明" prop="appendixEdu" class="bcli-lh18">
                <el-upload
                  class="upload-demo"
                  action="/api/system/commonfile/upload"
                  :on-success="successAppendixEdu"
                  :on-remove="removeAppendixEdu"
                  :before-upload= "handleBeforeUpload"
                  :file-list="appendixEduList"
                  multiple
                  :limit="2"
                  :headers="headers"
                  :on-exceed="handleExceed">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>
              </el-form-item>
            </div>
            <div v-if="courseInfo.courseConfig.appendixCompany == 1"  class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="单位证明" prop="appendixCompany">
                <el-upload
                  class="upload-demo"
                  action="/api/system/commonfile/upload"
                  :on-success="successAppendixCompany"
                  :on-remove="removeAppendixCompany"
                  :before-upload= "handleBeforeUploadFile"
                  :file-list="appendixCompanyList"
                  multiple
                  :limit="2"
                  :headers="headers"
                  :on-exceed="handleExceed">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传doc/pdf/ppt文件，且不超过5M</div>
                </el-upload>
              </el-form-item>
            </div>
            <div v-if="courseInfo.courseConfig.appendixApply == 1"  class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="报名申请" prop="appendixApply">
                <el-upload
                  class="upload-demo"
                  action="/api/system/commonfile/upload"
                  :on-success="successAppendixApply"
                  :on-remove="removeAppendixApply"
                  :before-upload= "handleBeforeUploadFile"
                  :file-list="appendixApplyList"
                  multiple
                  :limit="1"
                  :headers="headers"
                  :on-exceed="handleExceed">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传doc/pdf文件，且不超过5M</div>
                </el-upload>
              </el-form-item>
            </div>
            <div v-if="courseInfo.courseConfig.appendixOther == 1"  class="block-content-line" style="margin-bottom: 20px;">
              <el-form-item label="其他证明" prop="appendixOther">
                <el-upload
                    class="upload-demo"
                    action="/api/system/commonfile/upload"
                    :on-success="successAppendixOther"
                    :on-remove="removeAppendixOther"
                    :before-upload= "handleBeforeUploadFile"
                    :file-list="appendixOtherList"
                    multiple
                    :limit="5"
                    :headers="headers"
                    :on-exceed="handleExceed">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传doc/pdf文件，且不超过5M,最多5个</div>
                  </el-upload>
                  </el-form-item>
                
            </div>
          </div>
        </div>

        <div class="block" style="text-align:center;">
        <el-button v-if="signInfo.id == null" type="primary"  @click="sign" style="width:200px;">提交</el-button>
        <el-button v-else type="primary"  @click="signUpdate" style="width:200px;">重新提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
  </div>
</template>

<script>
import { getAllRecord } from "@/api/courses/record"
import { addSignRecord, getSignEditRecord , updateSignRecord} from "@/api/courses/signRecord"
import { getCurrentLoginInfo } from "@/api/login"
import { getToken } from "@/utils/auth";
import { getSystemEnum} from "@/api/register";

export default {
  name: "train_exam_registration",
  components: {},
  dicts: ['needs','sys_user_sex','edu'],
  data() {
    var validateArray = (rule, value, callback) => {
        if (value == undefined || value.length == 0) {
          callback(new Error('请选择'));
        } else {
          callback();
        }
      };

    var validateUpload  = (rule, value, callback) => {
        if (value == undefined || value.length == 0) {
          callback(new Error('请上传文件'));
        } else {
          callback();
        }
      }; 

    const checkIdNum = (rule, value, callback) => {
        if (!value) {
          callback(new Error('身份证不能为空'))
        }
        if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
          callback(new Error('请输入正确的二代身份证号码'))
        } else {
          callback()
        }
      };

      var validateWorkExperience = (rule, value, callback) => {
        if (value == undefined || value.length == 0) {
          callback(new Error('请至少填写一条工作经历'));
        } else if(value.length >=1){
          var experience = value[0];
          if(experience.dateRange == undefined || experience.dateRange == null || experience.dateRange.length == 0){
            callback(new Error('请至少填写一条完整的工作经历'));
          }
          if(experience.company == undefined || experience.company == ''){
            callback(new Error('请至少填写一条完整的工作经历'));
          }
          if(experience.job == undefined || experience.job == ''){
            callback(new Error('请至少填写一条完整的工作经历'));
          }
          if(experience.witness == undefined || experience.witness == ''){
            callback(new Error('请至少填写一条完整的工作经历'));
          }
          callback();
        }else {
          callback();
        }
      }; 

    return {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      courseId: null,
      loginInfo: {},
      degreeEnum:[],
      appendixCertificateList: [],
      appendixCertificateList2: [],
      appendixCompanyList: [],
      appendixIdCardList: [],
      appendixIdCardList2: [],
      appendixEduList: [],
      appendixApplyList: [],
      appendixOtherList: [],
      fileType: ["png", "jpg", "jpeg"],
      courseInfo: {
        name: null,
        courseService: {
            lodgingPrice: null,
            lodgingStartTime: null,
            lodgingEndTime: null,
            lunchPrice: null,
            lunchStartTime: null,
            lunchEndTime: null,
            dinnerPrice: null,
            dinnerStartTime: null,
            dinnerEndTime: null,
          },
        courseConfig: {
            name: 1,
            gender: 1,
            phone: 1,
            edu: 1,
            idCard: 1,
            address: 1,
            photo: 1,
            company: 1,
            job: 1,
            jobTime: 1,
            post: 1,
            workYear: 1,
            workAge: 1,
            grade: 1,
            certificateNum: 1,
            declarationApplyJob: 1,
            declarationApplyGrade: 1,
            certificateDate: 1,
            awards: 1,
            workExperience: 1,
            appendixCertificate: 1,
            appendixIdCard: 1,
            appendixEdu: 1,
            appendixCompany: 1,
            appendixApply: 1,
            appendixOther: 1
          }

      },
      signInfo:{
        photo: null,
        dinners: '0',
        lunchs: '0',
        lodgings: '0',
        sex: '0',
        workExperienceList: [{
            dateRange: [],
            company: '',
            job: '',
            witness: ''
          }
        ],
        appendixList: [
          {
            appendixType: null,
            appendixUrls: [
              {
                name: null,
                url: null,
              }
            ],
          }
        ],
        appendixCertificate: [],
        appendixCertificate2: [],
        appendixIdCard: [],
        appendixIdCard2: [],
        appendixEdu: [],
        appendixCompany: [],
        appendixApply: [],
        appendixOther: [
          {
            name: null,
            url: null,
          }
        ],
      },
      signDetailInfo:{

      },
      rules: {
        userId: [
          { required: true, message: "用户ID不能为空", trigger: "blur" }
        ],
        name: [
          { required: true, message: "报名人姓名不能为空", trigger: "blur" }
        ],
        sex: [
          { required: true, message: "报名人性别不能为空", trigger: "change" }
        ],
        phone: [
        {
            required: true,
            message: "手机号不能为空",
            trigger: "blur"
          }, {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        edu: [
          { required: true, message: "报名人文化程度不能为空", trigger: "blur" }
        ],
        idCard: [
          { validator: checkIdNum, trigger: "blur" }
        ],
        address: [
        { required: true, message: "地址不能为空", trigger: "blur" }
        ],
        photo: [
          { required: true, message: "报名人照片地址不能为空", trigger: "blur" }
        ],
        company: [
          { required: true, message: "工作单位不能为空", trigger: "blur" }
        ],
        job: [
          { required: true, message: "职位不能为空", trigger: "blur" }
        ],
        jobTime: [
          { required: true, message: "参加工作时间不能为空", trigger: "blur" }
        ],
        post: [
          { required: true, message: "现工作岗位不能为空", trigger: "blur" }
        ],
        workYear: [
          { required: true, message: "从事本职业(工种)技术工作年限不能为空", trigger: "blur" }
        ],
        workAge: [
          { required: true, message: "从事本职业(工种)工龄不能为空", trigger: "blur" }
        ],
        grade: [
          { required: true, message: "原证书职业名称及等级不能为空", trigger: "blur" }
        ],
        certificateNum: [
          { required: true, message: "原证书编号不能为空", trigger: "blur" }
        ],
        declarationApplyJob: [
          { required: true, message: "申报职业不能为空", trigger: "blur" }
        ],
        declarationApplyGrade: [
          { required: true, message: "申报等级不能为空", trigger: "blur" }
        ],
        certificateDate: [
          { required: true, message: "原证书时间不能为空", trigger: "blur" }
        ],
        awards: [
          { required: true, message: "获奖情况不能为空", trigger: "blur" }
        ],
        lodgings: [
          { required: true, message: "是否需要住宿不能为空", trigger: "blur" }
        ],
        lunchs: [
          { required: true, message: "是否需要午餐不能为空", trigger: "blur" }
        ],
        lunchRange: [
          {validator: validateArray, trigger: "blur"}
        ],
        dinners: [
          { required: true, message: "是否需要晚餐不能为空", trigger: "blur" }
        ],
        appendixCertificate: [
        {required: true, validator: validateUpload, trigger: "blur"}
        ],
        appendixCertificate2: [
        {required: true, validator: validateUpload, trigger: "blur"}
        ],
        appendixIdCard: [
        {required: true, validator: validateUpload, trigger: "blur"}
        ],
        appendixIdCard2: [
        {required: true, validator: validateUpload, trigger: "blur"}
        ],
        appendixEdu: [
        {required: true, validator: validateUpload, trigger: "blur"}
        ],
        appendixCompany: [
        {required: true, validator: validateUpload, trigger: "blur"}
        ],
        appendixApply: [
        {required: true, validator: validateUpload, trigger: "blur"}
        ],
        workExperienceList: [
        {validator: validateWorkExperience, trigger: "blur"}
        ],
      }    
     };
  },
  created(){
    this.courseId = this.$route.query.courseId
    this.signInfo.courseId =  this.courseId
    this.getDetail();

    if(this.$route.query.recordId){
      this.getSignDetail(this.$route.query.recordId);
    }else{
      this.getLoginInfo();
    }

  },
  watch:{
  },
  methods: {
    getSystemEnum() {
      getSystemEnum().then(res => {
        this.degreeEnum=res.degreeEnum;
      });
    },
    sign(){
      this.$refs["signInfo"].validate(valid => {
          if (valid) {
            var appendixList = []

            if(this.signInfo.appendixCertificate.length > 0){
              appendixList.push({appendixType: 'appendixCertificate',appendixUrls: this.signInfo.appendixCertificate})
            }

            if(this.signInfo.appendixCertificate2.length > 0){
              appendixList.push({appendixType: 'appendixCertificate2',appendixUrls: this.signInfo.appendixCertificate2})
            }

            if(this.signInfo.appendixIdCard.length > 0){
              appendixList.push({appendixType: 'appendixIdCard', appendixUrls: this.signInfo.appendixIdCard})
            }

            if(this.signInfo.appendixIdCard2.length > 0){
              appendixList.push({appendixType: 'appendixIdCard2', appendixUrls: this.signInfo.appendixIdCard2})
            }

            if(this.signInfo.appendixEdu.length > 0){
              appendixList.push({appendixType: 'appendixEdu', appendixUrls: this.signInfo.appendixEdu})
            }

            if(this.signInfo.appendixCompany.length > 0){
              appendixList.push({appendixType: 'appendixCompany', appendixUrls: this.signInfo.appendixCompany})
            }

            if(this.signInfo.appendixApply.length > 0){
              appendixList.push({appendixType: 'appendixApply', appendixUrls: this.signInfo.appendixApply})
            }

            if(this.signInfo.appendixOther.length > 0){
              appendixList.push({appendixType: 'appendixOther', appendixUrls: this.signInfo.appendixOther})
            }

            this.signInfo.appendixList = appendixList

            addSignRecord(this.signInfo).then(response => {
                console.log(response)
                this.$alert('报名成功,请3天内查看审核结果！', '成功', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (action) {
                      this.$router.push({path: '/menu_page/train_sign_list'});
                    }
                  }
                });
                
              });
          }
        });
    },
    signUpdate(){

      this.$refs["signInfo"].validate(valid => {
          if (valid) {
            var appendixList = []

            if(this.signInfo.appendixCertificate.length > 0){
              appendixList.push({appendixType: 'appendixCertificate',appendixUrls: this.signInfo.appendixCertificate})
            }

            if(this.signInfo.appendixCertificate2.length > 0){
              appendixList.push({appendixType: 'appendixCertificate2',appendixUrls: this.signInfo.appendixCertificate2})
            }

            if(this.signInfo.appendixIdCard.length > 0){
              appendixList.push({appendixType: 'appendixIdCard', appendixUrls: this.signInfo.appendixIdCard})
            }

            if(this.signInfo.appendixIdCard2.length > 0){
              appendixList.push({appendixType: 'appendixIdCard2', appendixUrls: this.signInfo.appendixIdCard2})
            }

            if(this.signInfo.appendixEdu.length > 0){
              appendixList.push({appendixType: 'appendixEdu', appendixUrls: this.signInfo.appendixEdu})
            }

            if(this.signInfo.appendixCompany.length > 0){
              appendixList.push({appendixType: 'appendixCompany', appendixUrls: this.signInfo.appendixCompany})
            }

            if(this.signInfo.appendixApply.length > 0){
              appendixList.push({appendixType: 'appendixApply', appendixUrls: this.signInfo.appendixApply})
            }

            if(this.signInfo.appendixOther.length > 0){
              appendixList.push({appendixType: 'appendixOther', appendixUrls: this.signInfo.appendixOther})
            }

            this.signInfo.appendixList = appendixList

            updateSignRecord(this.signInfo).then(response => {
                console.log(response)
                this.$alert('重新提交成功,请3天内查看审核结果！', '成功', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (action) {
                      this.$router.push({path: '/menu_page/train_sign_list'});
                    }
                  }
                });
                
              });
          }
        });
    },
    getDetail(){
      getAllRecord(this.courseId).then(response => {
          this.courseInfo = response.data;
        });
    },
    getSignDetail(recordId){
      getSignEditRecord(recordId).then(response => {
            this.signInfo = response.data;
            var alIST =   this.signInfo.appendixList;

            var i =0;
            var temp = 1;
            for (i = 0; i < alIST.length; i++) { 
               var  item = alIST[i];

               if(item.appendixType == "appendixCertificate"){
                  this.signInfo.appendixCertificate = item.appendixUrls;
                  this.appendixCertificateList = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                    });
                }

                if(item.appendixType == "appendixCertificate2"){
                  this.signInfo.appendixCertificate2 = item.appendixUrls;
                  this.appendixCertificateList2 = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                    });
                }

                if(item.appendixType == "appendixIdCard"){
                  this.signInfo.appendixIdCard = item.appendixUrls;
                  this.appendixIdCardList = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                    });
                }

                if(item.appendixType == "appendixIdCard2"){
                  this.signInfo.appendixIdCard2 = item.appendixUrls;
                  this.appendixIdCardList2 = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                    });
                }

                if(item.appendixType == "appendixEdu"){
                  this.signInfo.appendixEdu = item.appendixUrls;
                  this.appendixEduList = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                    });
                }

                if(item.appendixType == "appendixCompany"){
                  this.signInfo.appendixCompany = item.appendixUrls;
                  this.appendixCompanyList = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                    });
                }

                if(item.appendixType == "appendixApply"){
                  this.signInfo.appendixApply = item.appendixUrls;
                  this.appendixApplyList = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                    });
                }

                if(item.appendixType == "appendixOther"){
                  //alert(typeof item.appendixUrls)
                  this.signInfo.appendixOther = item.appendixUrls;
                  this.appendixOtherList = item.appendixUrls.map(item => {
                      item = { name: item.name, url: item.url, uid: new Date().getTime() + temp++};
                      return item;
                  });
                }


            }

            });
      },
    getLoginInfo(){
      getCurrentLoginInfo().then(res => {
        this.loginInfo = res.data;
        this.signInfo.name = this.loginInfo.userName;
        this.signInfo.sex =  this.loginInfo.sex+"";
        this.signInfo.address = this.loginInfo.address;
        this.signInfo.idCard = this.loginInfo.certNum;
        this.signInfo.phone = this.loginInfo.phone;
        this.signInfo.edu = this.loginInfo.eduDegree;
        this.getSystemEnum();
      })
    },
    addWE(){
      this.signInfo.workExperienceList.push({
        dateRange: [],
        company: '',
        job: '',
        witness: ''
      });
    },
      handleAvatarSuccess(res) {
        this.signInfo.photo = res.path;
      },
      handleExceed(files, fileList) {
        this.$message.warning(`文件上传已被限制（超过最大上传数量），已上传 ${fileList.length} 个文件`);
      },
      successAppendixCertificate(response, file, fileList) {
        this.signInfo.appendixCertificate.push({ name: file.name, url: response.path });
      },
      removeAppendixCertificate(file, fileList) {
        const findex = this.signInfo.appendixCertificate.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixCertificate.splice(findex, 1);
        }
      },
      successAppendixCertificate2(response, file, fileList) {
        this.signInfo.appendixCertificate2.push({ name: file.name, url: response.path });
      },
      removeAppendixCertificate2(file, fileList) {
        const findex = this.signInfo.appendixCertificate2.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixCertificate2.splice(findex, 1);
        }
      },
      successAppendixIdCard(response, file, fileList) {
        this.signInfo.appendixIdCard.push({ name: file.name, url: response.path });
      },
      removeAppendixIdCard(file, fileList) {
        const findex = this.signInfo.appendixIdCard.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixIdCard.splice(findex, 1);
        }
      },
      successAppendixIdCard2(response, file, fileList) {
        this.signInfo.appendixIdCard2.push({ name: file.name, url: response.path });
      },
      removeAppendixIdCard2(file, fileList) {
        const findex = this.signInfo.appendixIdCard2.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixIdCard2.splice(findex, 1);
        }
      },
      successAppendixEdu(response, file, fileList) {
        this.signInfo.appendixEdu.push({ name: file.name, url: response.path });
      },
      removeAppendixEdu(file, fileList) {
        const findex = this.signInfo.appendixEdu.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixEdu.splice(findex, 1);
        }
      },
      successAppendixCompany(response, file, fileList) {
        this.signInfo.appendixCompany.push({ name: file.name, url: response.path });
      },
      removeAppendixCompany(file, fileList) {
        const findex = this.signInfo.appendixCompany.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixCompany.splice(findex, 1);
        }
      },
      successAppendixApply(response, file, fileList) {
        this.signInfo.appendixApply.push({ name: file.name, url: response.path });
      },
      removeAppendixApply(file, fileList) {
        const findex = this.signInfo.appendixApply.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixApply.splice(findex, 1);
        }
      },
      successAppendixOther(response, file, fileList) {
        this.signInfo.appendixOther.push({ name: file.name, url: response.path });
      },
      removeAppendixOther(file, fileList) {
        const findex = this.signInfo.appendixOther.map(f => f.name).indexOf(file.name);
        if(findex > -1) {
          this.signInfo.appendixOther.splice(findex, 1);
        }
      },
      handleBeforeUpload(file) {
      let isImg = false;
      var fileType= ["png", "jpg", "jpeg"]
      if (fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$message({
          message: `文件格式不正确, 请上传${fileType.join("/")}图片格式文件!`,
          type: 'warning'
        });
        return false;
      }
      var fileSize = 5;
      if (fileSize) {
        const isLt = file.size / 1024 / 1024 < fileSize;
        if (!isLt) {
          this.$message({
          message: `上传头像图片大小不能超过 ${this.fileSize} MB!`,
          type: 'warning'
        });
          return false;
        }
      }
    },
    handleBeforeUploadFile(file) {
      var fileType = ["doc", "xls", "ppt", "txt", "pdf"];
      if (fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$message({
          message: `文件格式不正确, 请上传${fileType.join("/")}格式文件!`,
          type: 'warning'
        });
          return false;
        }
      }
      var fileSize = 5;
      // 校检文件大小
      if (fileSize) {
        const isLt = file.size / 1024 / 1024 < fileSize;
        if (!isLt) {
          this.$message({
          message: `上传文件大小不能超过 ${fileSize} MB!`,
          type: 'warning'
        });
          return false;
        }
      }
      return true;
    }
  },
};
</script>
<!-- <style scoped lang="scss"> -->
<style scoped >

.flex{
  display:flex;
}
.block{
  margin-bottom:50px;
}
.block-content-line-item{flex:4}
/deep/ .block-content-line-item .el-select{width:100%;}
/deep/ .block-content-line .el-form-item__label{padding-left: 30px;}
/deep/ .bcli-lh18  .el-form-item__label{line-height: 18px;}
/* /deep/ .block-content-line-item .el-form-item__content{margin-left: 120px !important;} */
/deep/ .label-center .el-form-item__label{text-align: center;}
.label1{
  width: 150px;padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2{
  padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2 span{
  padding-left:5px;padding-right: 5px;text-decoration: underline;
}

/*******************/
/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
/deep/  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 175px;
    height: 225px;
    line-height: 225px;
    text-align: center;
  }
  .avatar {
    width: 175px;
    height: 228px;
    display: block;
  }

</style>