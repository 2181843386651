import Cookies from 'js-cookie'

const state = {
  loginName:'',//登录名
  loginPhone:'',//登录手机号
  loginType:'1'
}

const mutations = {
  LOGIN:(state,loginData) => {
    state.loginId = loginData.loginId
    state.loginName = loginData.loginName
    state.loginPhone = loginData.loginPhone
    state.loginType = loginData.loginType
    state.loginState = loginData.loginState
  },
  LOGOUT:(state) => {
    state.loginId = ''
    state.loginName = ''
    state.loginPhone = ''
    state.loginType = ''
    state.loginState = 0
  }
}

const actions = {
  login:function({ commit },loginData){
    let loginId = loginData.loginId
    let loginName = loginData.loginName
    let loginPhone = loginData.loginPhone
    let loginType = loginData.type
    let loginState = loginData.state
    commit('LOGIN',{loginId:loginId,loginName:loginName,loginPhone:loginPhone,loginType:loginType,loginState:loginState})
  },
  logout:function({commit}){
    commit('LOGOUT')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}