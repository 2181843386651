<template>
  <div class="main-body">
    <div class="title-img">
      <img src="@/assets/img/banner-xwzx.png" height="" width="100%" style="min-width:1200px">
    </div>
    <div class="c-1200" style="margin-bottom:30px;">
      <div class="nav-box">
        <i class="fa fa-map-pin nav-icon" aria-hidden="true"></i>
        <span class="nav-c">{{ getModuleName() }}</span>
        <span  class="nav-g">/</span>
        <span  class="nav-c">{{getTypeName()}}</span>
        <span  class="nav-g">/</span>
        <span  class="nav-z">{{ this.news.title}}</span>
      </div>
      <div>
        <el-row :gutter="20">
          <el-col :span="18">
            <div style="box-shadow:0px 0px 8px 0px #ddd;padding:30px;background-color: #fff;">
              <div class="title">
                {{news.title}}
              </div>
              <div class="time">
                <span>发布时间：{{ news.addTime}}</span> <span style="float:right">点击次数：{{ news.viewNum }}</span>
              </div>
              <div class="content">
                <div v-if="news.coverPath != null"><span style="vertical-align: top;margin-right:50px">封面图：</span><img :src="news.coverPath" height="200px" style="max-width:700px"></div>
                <div v-html="news.content"></div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div style="box-shadow:0px 0px 8px 0px #ddd; background-color:#fff;">
              <div style="padding:20px;">
                <div style="line-height:40px;">通知公告</div>
                <div v-if="newsList7.length==0">
                  <img src="@/assets/img/no-data.png" height="" width="100%" style="width:100%">
                </div>
                <div style="font-size:12px;color:#999999;line-height:30px;" v-if="newsList7.length>0">
                  <div v-for="record in newsList7" :key="record.id">
                    <span class="link" @click="toNewsDetail(record.id,1)">{{ record.title }}</span>
                  </div>
                </div>
              </div>
              <hr style="width:90%;text-align:center;border: 0;background-color:#E5E5E5;height: 1px;">
              <div style="padding:20px;">
                <div style="line-height:40px;">焦点新闻</div>
                <div v-if="newsList1.length==0">
                  <img src="@/assets/img/no-data.png" height="" width="100%" style="width:100%">
                </div>
                <div style="font-size:12px;color:#999999;line-height:30px;" v-if="newsList1.length>0">
                  <div v-for="record in newsList1" :key="record.id">
                    <span class="link" @click="toNewsDetail(record.id,1)">{{ record.title }}</span>
                  </div>
                </div>
              </div>
              <hr style="width:90%;text-align:center;border: 0;background-color:#E5E5E5;height: 1px;">
              <div style="padding:20px;">
                <div style="line-height:40px;">酒业文化</div>
                <div v-if="newsList2.length==0">
                  <img src="@/assets/img/no-data.png" height="" width="100%" style="width:100%">
                </div>
                <div style="font-size:12px;color:#999999;line-height:30px;" v-if="newsList2.length>0">
                  <div v-for="record in newsList2" :key="record.id">
                    <span class="link" @click="toNewsDetail(record.id,5)">{{ record.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>  
import { getInfo, listRecord } from '@/api/news'
export default {
  name: "news_detail",
  components: {},
  data() {
    return {
      news: {},
      newsList1: [],
      newsList2: [],
      newsList7:[],
      dataHint: {
        module1: {
          name: '新闻资讯',
          types: {
            type1: { name: '焦点新闻' },
            type2: { name: '行业动态' },
            type3: { name: '政策法规' },
            type4: { name: '工作动态' },
            type5: { name: '酒业文化' },
            type6: { name: '党建工作' },
            type7: { name: '通知公告' },
            type8: { name: '会员风采' },
            type9: { name: '品牌营销' }
          }
        },
        module2: {
          name: '技术服务',
          types: {
            type1: { name: '人才培育' },
            type2: { name: '品评酿造' },
            type3: { name: '设备器皿' }
          }
        },
        module3: {
          name: '酿酒科技',
          types: {
            type1: { name: '酿酒科技' },
          }
        },
        module4: {
          name: '协会介绍',
          types: {
            type1: { name: '关于我们' },
            type2: { name: '组织架构' }
          }
        },
        module7: {
          name: '会员风采',
          types: {
            type1: { name: '会员风采' }
          }
        },
        module8: {
          name: '通知公告',
          types: {
            type1: { name: '通知公告' },
          }
        }
      }
    };
  },
  created() {
    const id = this.$route.query.id;
    this.loadNewsDetail(id)
    this.loadNews()
  },
  watch:{
  },
  methods: {
    loadNews() {
      const queryParams1 = { pageNum: 1, pageSize: 10, moduleId: 1, typeId: 1, orderByColumn: 'addTime_', isAsc: 'desc' };
      const queryParams2 = { pageNum: 1, pageSize: 5, moduleId: 1, typeId: 5, orderByColumn: 'addTime_', isAsc: 'desc' };
      const queryParams3 = { pageNum: 1, pageSize: 5, moduleId: 8, typeId:1, orderByColumn: 'addTime_', isAsc: 'desc'};
      listRecord(queryParams1).then(res => {
        this.newsList1 = res.rows
      })
      listRecord(queryParams2).then(res => {
        this.newsList2 = res.rows
      })
      listRecord(queryParams3).then(res => {
        this.newsList7 = res.rows
      })
    },
    loadNewsDetail(id) { 
      getInfo(id).then(res => { 
        const data = res.data
        this.news = data
        this.moduleId = data.moduleId
        const from = this.$route.query.from;
        if (from == null || from == 'undefined') {
          const types = JSON.parse(data.typeId)
          if (types != null && types.length > 0) {
            this.typeId = types[0]
          }
        } else { 
          this.typeId = from
        }
        this.$parent.$refs.header.updateModule(this.moduleId)
      })
    },
    getModuleName() {
      const moduleInfo = this.dataHint['module' + this.moduleId];
      if (moduleInfo != null) {
        return moduleInfo.name;
      }
      return '未知'
      
    },
    toNewsDetail(id,from) { 
      window.location.href = '/menu_page/news_detail?id=' + id + '&from=' + from
    },
    getTypeName() {
      const moduleInfo = this.dataHint['module' + this.moduleId];
      if (moduleInfo != null) {
        const typeInfo = moduleInfo.types['type' + this.typeId];
        return typeInfo.name
      }
      return '未知'
    }
  },
};
</script>
<!-- <style scoped lang="scss"> -->

<style scoped >
@import url('../../../assets/css/chunk-libs1.css');
.title{
  color:#333333;
  font-size: 18px;
  text-align: center;
}
.time{
  color:#999999;
  font-size: 12px;
  bottom: 10px;
  line-height: 50px;
  border-bottom: 1px dashed #E5E5E5;
}
.content{
  color:#444444;
  font-size: 14px;
  padding-top:20px;
  line-height:32px
}
.link{
    width: 245px;
    display: block;
    overflow: hidden;        /*内容会被修剪，并且其余内容是不可见的*/
    text-overflow:ellipsis;  /*显示省略符号来代表被修剪的文本。*/
    white-space: nowrap;
    cursor: pointer;
    margin:5px 0px;
}

/deep/.ql-align-justify img{width:100% !important}
/deep/.ql-align-center img{width:100% !important;text-align: center;}
/deep/p img{width:100% !important}
</style>
