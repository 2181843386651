<!-- 作者:  -->
<!-- 页面功能:  -->
<!-- 创建时间: 2020年05月25日 13:28:17 -->
<template>
  <div>
        <CommonHeader ref="header"></CommonHeader>
          <router-view></router-view>
        <CommonFooter></CommonFooter>
  </div>
</template>

<script>
import CommonHeader from '@/views/common/common_header'
import CommonFooter from '@/views/common/common_footer'
export default {
    components: {CommonHeader,CommonFooter},
  data() {
    return {

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  //生命周期 - 方法
  methods: {
      
  }
};
</script>
<style scoped>
  /* @import url(); 引入css类 */
    .darkback-ground{
      background: #f3f3f3;
    }
    .left-content{
        width: 160px;
        min-height: 400px;
        float: left;
        margin-top: 50px;
    }
    .right-content{
        width: 1020px;
        min-height: 400px;
        float: right;
        margin-top: 50px;
        margin-bottom: 50px;
    }
</style>