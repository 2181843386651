<template>
  <div class="main-body">
    <div class="banner-box">
      <div class="">
        <div id="mask">
          <div class="banner">
            <el-carousel :interval="5000" indicator-position="none">
              <el-carousel-item v-for="(item, index) in carouselList" :key="index" width="100%">
                <img :src="item.path" alt="" width="100%"  @click="toPath(item)" :title="item.title">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    
    <div>
      <div class="c-1200 xwzx" style="padding:20px 0px">
        <div>
          <div class="block-title ">
            <img src="@/assets/img/index/title-img-xwzx.png" style="width:100%">
          </div>
        </div>

        <div style="padding:20px;border: 1px solid #E5E5E5;position:relative;margin-top: 10px;background-color: #fff;">
              <el-menu
                :default-active="xwzxActiveIndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="xwzxHandleSelect"
                background-color="#fff"
                text-color="#FE0000"
                active-text-color="#fff">
                <el-menu-item index="1" @click="xwzxActiveIndex='1'">焦点新闻</el-menu-item>
                <el-menu-item index="2" @click="xwzxActiveIndex='2'">行业动态</el-menu-item>
                <el-menu-item index="3" @click="xwzxActiveIndex='3'">工作动态</el-menu-item>
                <el-menu-item index="4" @click="xwzxActiveIndex='4'">政策法规</el-menu-item>
              </el-menu>
              <el-button type="text" style="position:absolute;right: 0;margin-top: -40px;margin-right: 20px;color: #666666;" @click="tonewslist1()">更多　></el-button>
              <!--焦点新闻-->
              <div style="margin-top: 15px;" v-show="xwzxActiveIndex =='1'">
                <el-row :gutter="20">
                  <el-col :span="11">
                    <div v-if="(newsData['focus-hot'][0]==null)&&(newsData['focus-hot'][1]==null)&&(newsData['focus-hot'][2]==null)" >
                      <img  :src="noData">
                    </div>
                    <div v-else>
                      <img v-if="newsData['focus-hot'][0]!=null" class="link" @click="toNewsDetail(newsData['focus-hot'][0].id,1)" :src="newsData['focus-hot'][0].coverPath" height="280" width="520" style="margin-bottom:5px;">
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <img v-if="newsData['focus-hot'][1]!=null" class="link" @click="toNewsDetail(newsData['focus-hot'][1].id,1)" :src="newsData['focus-hot'][1].coverPath" height="130" width="100%">
                        </el-col>
                        <el-col :span="12">
                          <img v-if="newsData['focus-hot'][2]!=null" class="link" @click="toNewsDetail(newsData['focus-hot'][2].id, 1)" :src="newsData['focus-hot'][2].coverPath" height="130" width="100%">
                        </el-col>
                      </el-row>
                    </div>
                    <!-- <div>
                      <img v-if="newsData['focus-hot'][0]==null" :src="noData">
                      <img v-else class="link" @click="toNewsDetail(newsData['focus-hot'][0].id)" :src="newsData['focus-hot'][0].coverPath" height="280" width="520" style="margin-bottom:5px;">
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <img v-if="newsData['focus-hot'][1]==null" :src="noData">
                          <img v-else class="link" @click="toNewsDetail(newsData['focus-hot'][1].id)" :src="newsData['focus-hot'][1].coverPath" height="130" width="100%">
                        </el-col>
                        <el-col :span="12">
                          <img v-if="newsData['focus-hot'][2]==null" :src="noData">
                          <img v-else class="link" @click="toNewsDetail(newsData['focus-hot'][2].id)" :src="newsData['focus-hot'][2].coverPath" height="130" width="100%">
                        </el-col>
                      </el-row>
                    </div> -->
                  </el-col> 
                  <el-col :span="13">
                    <div>
                      <!-- <div style="height:190px;border-bottom: 1px solid #E5E5E5;"> -->
                      <div v-for="(record,index) in newsData['focus']" :key="record.id">
                          <template v-if="index==0">
                            <div style="border-bottom: 1px solid #E5E5E5;padding-bottom: 20px;">
                              <div class="xwzx-title" style="color:#333">
                                <p class="link" @click="toNewsDetail(record.id, 1)">{{record.title}}</p>
                              </div>
                              <div class="xwzx-content" style="color:#999;font-size:14px;">
                                {{ record.description }}
                               </div>
                            </div>
                          </template>
                          <template v-else>
                            <div style="padding-top: 20px;line-height: 28px;color:#333">
                              <div class="link" @click="toNewsDetail(record.id, 1)">
                                <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:500px;display: inline-block;">{{record.title}}</span>
                                <span style="float:right;color: #999;">{{record.addTime}}</span>
                              </div>
                            </div>
                          </template>
                        </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!--行业动态-->
              <div style="margin-top: 15px;" v-show="xwzxActiveIndex =='2'">
                <el-row :gutter="20">
                  <el-col :span="11">
                    <div v-if="(newsData['industry-hot'][0]==null)&&(newsData['industry-hot'][1]==null)&&(newsData['industry-hot'][2]==null)" style="text-align:center;">
                      <img  :src="noData">
                    </div>
                    <div v-else>
                      <img  v-if="newsData['industry-hot'][0]!=null"  class="link" @click="toNewsDetail(newsData['industry-hot'][0].id,2)" :src="newsData['industry-hot'][0].coverPath"
                        height="280" width="520" style="margin-bottom:5px;">
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <img  v-if="newsData['industry-hot'][1]!=null"  class="link" @click="toNewsDetail(newsData['industry-hot'][1].id, 2)"
                            :src="newsData['industry-hot'][1].coverPath" height="130" width="100%">
                        </el-col>
                        <el-col :span="12">
                          <img  v-if="newsData['industry-hot'][2]!=null"  class="link" @click="toNewsDetail(newsData['industry-hot'][2].id, 2)" :src="newsData['industry-hot'][2].coverPath"
                            height="130" width="100%">
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="13">
                    <div>
                      <!-- <div style="height:190px;border-bottom: 1px solid #E5E5E5;"> -->
                      <div v-for="(record,index) in newsData['industry']" :key="record.id">
                        <template v-if="index==0">
                          <div style="border-bottom: 1px solid #E5E5E5;padding-bottom: 20px;">
                            <div class="xwzx-title" style="color:#333">
                              <p class="link" @click="toNewsDetail(record.id, 2)">{{record.title}}</p>
                            </div>
                            <div class="xwzx-content" style="color:#999;font-size:14px;">
                              {{ record.description }}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div style="padding-top: 20px;line-height: 28px;color:#333">
                            <div class="link" @click="toNewsDetail(record.id, 2)">
                              <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:500px;display: inline-block;">{{record.title}}</span>
                              <span style="float:right;color: #999;">{{record.addTime}}</span>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!--工作动态-->
              <div style="margin-top: 15px;" v-show="xwzxActiveIndex =='3'">
                <el-row :gutter="20">
                  <el-col :span="11">
                    <div v-if="(newsData['word-hot'][0]==null)&&(newsData['word-hot'][1]==null)&&(newsData['word-hot'][2]==null)">
                      <img  :src="noData">
                    </div>
                    <div>
                      <img  v-if="newsData['word-hot'][0]!=null"  class="link" @click="toNewsDetail(newsData['word-hot'][0].id,3)"
                        :src="newsData['word-hot'][0].coverPath" height="280" width="520" style="margin-bottom:5px;">
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <img  v-if="newsData['word-hot'][1]!=null"  class="link" @click="toNewsDetail(newsData['word-hot'][1].id, 3)"
                            :src="newsData['word-hot'][1].coverPath" height="130" width="100%">
                        </el-col>
                        <el-col :span="12">
                          <img  v-if="newsData['word-hot'][2]!=null"  class="link" @click="toNewsDetail(newsData['word-hot'][2].id, 3)" :src="newsData['word-hot'][2].coverPath"
                            height="130" width="100%">
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="13">
                    <div>
                      <!-- <div style="height:190px;border-bottom: 1px solid #E5E5E5;"> -->
                      <div v-for="(record,index) in newsData['word']" :key="record.id">
                        <template v-if="index==0">
                          <div style="border-bottom: 1px solid #E5E5E5;padding-bottom: 20px;">
                            <div class="xwzx-title" style="color:#333">
                              <p class="link" @click="toNewsDetail(record.id, 3)">{{record.title}}</p>
                            </div>
                            <div class="xwzx-content" style="color:#999;font-size:14px;">
                              {{ record.description }}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div style="padding-top: 20px;line-height: 28px;color:#333">
                            <div class="link" @click="toNewsDetail(record.id, 3)">
                              <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:500px;display: inline-block;">{{record.title}}</span>
                              <span style="float:right;color: #999;">{{record.addTime}}</span>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <!--政策法规-->
              <div style="margin-top: 15px;" v-show="xwzxActiveIndex =='4'">
                <el-row :gutter="20">
                  <el-col :span="11">
                    <div v-if="(newsData['law-hot'][0]==null)&&(newsData['law-hot'][1]==null)&&(newsData['law-hot'][2]==null)">
                      <img  :src="noData">
                    </div>
                    <div>
                      <img v-if="newsData['law-hot'][0]!=null"  class="link" @click="toNewsDetail(newsData['law-hot'][0].id,4)" :src="newsData['law-hot'][0].coverPath"
                        height="280" width="520" style="margin-bottom:5px;">
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <img  v-if="newsData['law-hot'][1]!=null"  class="link" @click="toNewsDetail(newsData['law-hot'][1].id,4)" :src="newsData['law-hot'][1].coverPath"
                            height="130" width="100%">
                        </el-col>
                        <el-col :span="12">
                          <img  v-if="newsData['law-hot'][2]!=null"  class="link" @click="toNewsDetail(newsData['law-hot'][2].id, 4)" :src="newsData['law-hot'][2].coverPath"
                            height="130" width="100%">
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="13">
                    <div>
                      <!-- <div style="height:190px;border-bottom: 1px solid #E5E5E5;"> -->
                      <div v-for="(record,index) in newsData['law']" :key="record.id">
                        <template v-if="index==0">
                          <div style="border-bottom: 1px solid #E5E5E5;padding-bottom: 20px;">
                            <div class="xwzx-title" style="color:#333">
                              <p class="link" @click="toNewsDetail(record.id, 4)">{{record.title}}</p>
                            </div>
                            <div class="xwzx-content" style="color:#999;font-size:14px;">
                              {{ record.description }}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div style="padding-top: 20px;line-height: 28px;color:#333">
                            <div class="link" @click="toNewsDetail(record.id, 4)">
                              <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:500px;display: inline-block;">{{record.title}}</span>
                              <span style="float:right;color: #999;">{{record.addTime}}</span>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>

                <!-- <img src="@/assets/img/index/xwhf.png" width="100%" > -->
            <div :style="{background:'url('+imgbgXwhf+') 100% 100%','background-size': '100% 100%',height:'123px',width:'100%','line-height':'123px'}">
                <p style="color:#FFFECF;font-size:54px;text-align:center;margin-left:-100px;margin:20px 0px 0px 0px;">引领行业发展，提供卓越服务</p>
            </div>

            <el-row :gutter="20" style="margin-top:20px;">
              <el-col :span="12">
                <div style="padding:20px;border: 1px solid #E5E5E5;position:relative;background-color: #fff;">
                  <el-menu
                    :default-active="'1'"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="xwzxHandleSelect"
                    background-color="#fff"
                    text-color="#FE0000"
                    active-text-color="#fff">
                    <el-menu-item index="1">酒业文创</el-menu-item>
                  </el-menu>
                  <el-button type="text" style="position:absolute;right: 0;margin-top: -40px;margin-right: 20px;color: #666666;" @click="tonewslist2()">更多　></el-button>
                  <div style="padding-top: 20px;line-height: 28px;color:#333">
                        <div  v-for="record in newsData['calture']" :key="record.id" class="link" @click="toNewsDetail(record.id,5)">
                          {{record.title}}
                          <span style="float:right;color: #999;">{{record.addTime}}</span>
                        </div>
                      </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div style="padding:20px;border: 1px solid #E5E5E5;position:relative;background-color: #fff;">
                  <el-menu
                    :default-active="'1'"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="xwzxHandleSelect"
                    background-color="#fff"
                    text-color="#FE0000"
                    active-text-color="#fff">
                    <el-menu-item index="1">党建工作</el-menu-item>
                  </el-menu>
                  <el-button type="text" style="position:absolute;right: 0;margin-top: -40px;margin-right: 20px;color: #666666;" @click="tonewslist3()">更多　></el-button>
                  <div style="padding-top: 20px;line-height: 28px;color:#333">
                        <div v-for="record in newsData['party']" :key="record.id" class="link" @click="toNewsDetail(record.id,6)">
                          {{record.title}}
                          <span style="float:right;color: #999;">{{record.addTime}}</span>
                        </div>
                      </div>
                </div>
              </el-col>
            </el-row>
      </div>
    </div>
    <div  :style="{background:'url('+imgFwzl.url+') 100% 100%','background-size': '100% 100%'}">
      <div class="c-1200 fwzl" style="padding:20px 0px;height:420px;">
        <div>
          <div class="block ">
            <img src="@/assets/img/index/title-img-fwzl.png" style="width:100%">
          </div>
        </div>
        <div style="margin:20px 0px;">
          <el-row :gutter="15">
            <el-col :span="19">
              <div style="padding:15px 20px;border:1px solid #FFD0D0">
                <el-row :gutter="10">
                  <!-- <el-col :span="6">
                    <el-button @click="queryCertificate" style="width:100%;line-height: 30px;background-color: #FF8649;border: 1px solid #FF8649;color:#ffffff;font-size:22px;">
                      证书校验
                    </el-button>
                  </el-col> -->
                  <el-col :span="12">
                    <el-button style="width:100%;line-height: 30px;background-color: #FF66A3;border: 1px solid #FF66A3;color:#ffffff;font-size:22px;" @click="trainExamList">培训报名</el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button style="width:100%;line-height: 30px;background-color: #68B6FF;border: 1px solid #68B6FF;color:#ffffff;font-size:22px;" @click="queryCertificate">证书校验</el-button>
                  </el-col>
                  <!-- <el-col :span="8">
                    <el-button style="width:100%;line-height: 30px;background-color: #FFB649;border: 1px solid #FFB649;color:#ffffff;font-size:22px;" @click="hygl">会员管理</el-button>
                  </el-col> -->
                </el-row>
              </div>
            </el-col>
            <el-col :span="5">
              <el-button style="width:100%;line-height: 62px;background-color: #FF8649;border: 1px solid #FF8649;color:#ffffff;font-size:22px;" @click="myInfo" >
                我的信息
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div>
          <img src="@/assets/img/index/fwhf.png" width="100%" >
        </div>
      </div>
    </div>
    <div>
      <div class="c-1200 jsfw" style="padding:20px 0px">
        <div>
          <div class="block-title">
            <img src="@/assets/img/index/title-img-jsfw.png" style="width:100%">
          </div>
        </div>
        <el-row :gutter="20" style="margin-top:20px;">
          <el-col :span="12">
            <div style="padding:20px;border: 1px solid #E5E5E5;position:relative;background-color: #fff;">
              <el-menu
              :default-active="'1'"
                class="el-menu-demo"
                mode="horizontal"
                @select="xwzxHandleSelect"
                background-color="#fff"
                text-color="#FE0000"
                active-text-color="#fff">
                <el-menu-item index="1">人才培育</el-menu-item>
              </el-menu>
              <el-button type="text" style="position:absolute;right: 0;margin-top: -40px;margin-right: 20px;color: #666666;" @click="tonewslist4()">更多　></el-button>
              <div style="padding-top: 20px;line-height: 28px;color:#333">
                <div v-for="record in newsData['human']" :key="record.id" class="link" @click="toNewsDetail(record.id,1)">
                  {{record.title}}
                  <span style="float:right;color: #999;">{{record.addTime}}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="padding:20px;border: 1px solid #E5E5E5;position:relative;background-color: #fff;">
              <el-menu
                :default-active="xwzxActiveIndex3"
                
                class="el-menu-demo"
                mode="horizontal"
                @select="xwzxHandleSelect"
                background-color="#fff"
                text-color="#FE0000"
                active-text-color="#fff">
                <el-menu-item index="1" @click="xwzxActiveIndex3='1'">品评酿造</el-menu-item>
                <el-menu-item index="2" @click="xwzxActiveIndex3='2'">设备器皿</el-menu-item>
              </el-menu>
              <el-button type="text" style="position:absolute;right: 0;margin-top: -40px;margin-right: 20px;color: #666666;" @click="tonewslist5()">更多　></el-button>
              <div style="padding-top: 20px;line-height: 28px;color:#333" v-show="xwzxActiveIndex3=='1'">
                <div v-for="record in newsData['tast-tech']" :key="record.id" class="link" @click="toNewsDetail(record.id,2)">
                  {{record.title}}
                  <span style="float:right;color: #999;">{{record.addTime}}</span>
                </div>
              </div>

              <div style="padding-top: 20px;line-height: 28px;color:#333" v-show="xwzxActiveIndex3=='2'">
                <div v-for="record in newsData['equipment']" :key="record.id" class="link" @click="toNewsDetail(record.id,3)">
                  {{record.title}}
                  <span style="float:right;color: #999;">{{record.addTime}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div>
      <div class="c-1200 njkj" style="padding:20px 0px;">
        <div>
          <div class="block-title">
            <img src="@/assets/img/index/title-img-njkj.png" style="width:100%">
          </div>
        </div>
        <div style="margin-top:50px">
          <el-row :gutter="20">
            <el-col :span="13">
              <div style="padding-bottom: 20px;" v-for="record in newsData['wine-tech']" :key="record.id" >
                <div class="njkj-title link" style="color:#333;border-bottom: 1px solid #E5E5E5;line-height: 40px;"  @click="toNewsDetail(record.id)">
                  {{record.title}}
                </div>
                <div class="njkj-content" style="color:#999;font-size:14px;padding:10px 0px">
                  {{ record.description }}
                </div>
              </div>
            </el-col>
            <el-col :span="11">
              <div v-if="(newsData['wine-tech-hot'][0]==null)&&(newsData['wine-tech-hot'][1]==null)&&(newsData['wine-tech-hot'][2]==null)&&(newsData['wine-tech-hot'][3]==null)" >
                <img  :src="noData" height="350" width="100%">
              </div>
              <div v-else>
                <div style="padding:5px 5px 0px 5px;box-shadow: 0px 0px 6px 0px rgba(189, 193, 213, 0.64);">
                  <!-- <img v-if="newsData['wine-tech-hot'][0]==null" :src="noData" height="350" width="100%"> -->
                  <img class="link"  @click="toNewsDetail(newsData['wine-tech-hot'][0].id,1)" :src="newsData['wine-tech-hot'][0].coverPath" height="350" width="100%">
                </div>
              <div style="margin-top:10px">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <div v-if="(newsData['wine-tech-hot'][1]!=null)" style="padding:5px 5px 0px 5px;box-shadow: 0px 0px 6px 0px rgba(189, 193, 213, 0.64);">
                      <!-- <img v-if="newsData['wine-tech-hot'][1]==null" :src="noData" height="110" width="100%"> -->
                      <img class="link"  @click="toNewsDetail(newsData['wine-tech-hot'][1].id, 1)"   :src="newsData['wine-tech-hot'][1].coverPath" height="110" width="100%">
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div v-if="(newsData['wine-tech-hot'][2]!=null)" style="padding:5px 5px 0px 5px;box-shadow: 0px 0px 6px 0px rgba(189, 193, 213, 0.64);">
                      <!-- <img v-if="newsData['wine-tech-hot'][2]==null" :src="noData" height="110" width="100%"> -->
                      <img class="link"  @click="toNewsDetail(newsData['wine-tech-hot'][2].id, 1)" :src="newsData['wine-tech-hot'][2].coverPath"
                      height="110" width="100%">
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div v-if="(newsData['wine-tech-hot'][3]!=null)" style="padding:5px 5px 0px 5px;box-shadow: 0px 0px 6px 0px rgba(189, 193, 213, 0.64);">
                      <!-- <img v-if="newsData['wine-tech-hot'][3]==null" :src="noData" height="110" width="100%"> -->
                      <img class="link"  @click="toNewsDetail(newsData['wine-tech-hot'][3].id, 1)" :src="newsData['wine-tech-hot'][3].coverPath"
                      height="110" width="100%">
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div>
      <div class="c-1200 gyxh" style="padding:20px 0px">
        <div>
          <div class="block-title">
            <img src="@/assets/img/index/title-img-gyxh.png" style="width:100%">
          </div>
        </div>
        <div style="margin-top:50px">
          <div>
            <el-row :gutter="20">
              <el-col :span="17">
                <div style="padding: 20px 40px;color:#333;border: 1px solid #E5E5E5;position:relative;height:300px;background-color: #fff;">
                  <div style="text-align:center">
                    <hr style="width: calc(50% - 62px); text-align: center; border-bottom: 1px dashed #999;display: inline-block;border-top:none;">
                    <span style="color:#FE0000;color: rgb(254, 0, 0);margin: 0px 20px;font-size: 20px;">关于我们</span>
                    <hr style="width: calc(50% - 62px); text-align: center; border-bottom: 1px dashed #999;display: inline-block;border-top:none;">
                  </div>
                  <div class="gyxh1">
                    <el-row :gutter="30">
                      <el-col :span="8">
                        <div class="gyxh-item link" style="background-color:#FFF7DC;" @click="toNewsDetail('3ef429e2425f47e7b0f3667d584f2d24',1)">
                          <img src="@/assets/img/index/gyxh/xhls.png" >
                          <span>协会历史</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="gyxh-item link" style="background-color:#FEEBEB;" @click="toNewsDetail('07a007fea6344defb1fbb7e6a86c738d',1)">
                          <img src="@/assets/img/index/gyxh/xhjj.png" >
                          <span>协会简介</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="gyxh-item link" style="background-color:#EBF6FF;" @click="toNewsDetail('f36ee0dec00c4d548046b1e511f416a2', 1)">
                          <img src="@/assets/img/index/gyxh/fwnr.png" style="">
                          <span>服务内容</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div style="text-align:center">
                    <hr style="width: calc(50% - 62px); text-align: center; border-bottom: 1px dashed #999;display: inline-block;border-top:none;">
                    <span style="color:#FE0000;color: rgb(254, 0, 0);margin: 0px 20px;font-size: 20px;">组织架构</span>
                    <hr style="width: calc(50% - 62px); text-align: center; border-bottom: 1px dashed #999;display: inline-block;border-top:none;">
                  </div>
                  <div class="gyxh2">
                    <el-row :gutter="30">
                      <el-col :span="4">
                        <div class="gyxh-item link" @click="toNewsDetail('bfd5104346014abcb684bd05f5f12cd4', 2)">
                            <img src="@/assets/img/index/gyxh/xh.png" style="margin-left:-12px">
                            <p>协会</p>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div class="gyxh-item link" @click="toNewsDetail('0787149a49e34c40a553952a7fdb9cb4', 2)">
                            <img src="@/assets/img/index/gyxh/msc.png" style="margin-left:-18px">
                            <p>秘书处</p>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div class="gyxh-item link" @click="toNewsDetail('504c28f15e19429d87038ffee087d827', 2)">
                            <img src="@/assets/img/index/gyxh/bjzj.png" style="margin-left:-14px">
                            <p>白酒专家<br>委员会</p>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div class="gyxh-item link" @click="toNewsDetail('e8bf54900fa14fcfa00294106b822c59', 2)">
                        <img src="@/assets/img/index/gyxh/whzj.png" style="margin-left:-14px">
                        <p>文化专家<br>委员会</p>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div class="gyxh-item link" @click="toNewsDetail('1e8a97ff5427423aa9d1a997e1eb8b9a', 2)">
                        <img src="@/assets/img/index/gyxh/yxzj.png" style="margin-left:-18px">
                        <p>营销专家<br>委员会</p>
                        </div>
                      </el-col>
                      <el-col :span="4" >
                        <div class="gyxh-item link" @click="toNewsDetail('90ac0ef251b045fa86d4be984fd0490b', 2)">
                        <img src="@/assets/img/index/gyxh/gwwyh.png" style="margin-left:-16px">
                        <p>顾问委员会</p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
              <el-col :span="7">
                <!-- <div  style="padding: 0px;color:#333;border: 1px solid #E5E5E5;position:relative;height:300px;background-color: #fff;height:342px;"> -->
                  <div v-if="loginName==null || loginName==''" style="padding: 0px;color:#333;border: 1px solid #E5E5E5;position:relative;height:342px;background-color: #fff;">
                  <el-menu
                    :default-active="xwzxActiveIndex4"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="xwzxHandleSelect"
                    background-color="#fff"
                    text-color="#FE0000"
                    active-text-color="#fff">
                    <el-menu-item index="1" @click="choiceLoginType(1)">个人登陆</el-menu-item>
                    <el-menu-item index="2" @click="choiceLoginType(2)">企业登陆</el-menu-item>
                  </el-menu>
                  <div style="padding:10px">
                    <el-form ref="registrationInfo" :model="loginForm" label-width="70px" size="small">
                      <div style="margin-top: 40px;">
                        <el-form-item :label="loginTip" prop="loginPhone" :class="classLh16">
                          <el-input v-model="loginForm.loginPhone" clearable class="e-input" :placeholder="'请输入'+loginTip" />
                        </el-form-item>
                      </div>
                      <div style="margin-top: 20px;">
                        <el-form-item label="密码" prop="inputPwd">
                          <el-input v-model="loginForm.inputPwd" clearable class="e-input" placeholder="请输入密码" />
                        </el-form-item>
                      </div>
                      <div style="margin-top: 20px;">
                          <el-form-item label="验证码" prop="picCode">
                            <el-row :gutter="10">
                              <el-col :span="15" style="padding-left:0px;padding-right:0xp">
                                  <el-input v-model="loginForm.picCode" clearable class="e-input" placeholder="请输入验证码" ></el-input>
                              </el-col>
                              <el-col :span="8">
                                <div class="login-code">
                                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                                </div>
                              </el-col>
                            </el-row>
                          </el-form-item> 
                      </div>
                      <div style="text-align:center; margin-top: 20px;">
                        <el-button style="width:200px;background-color: #FE0000;color:#ffffff"  @click="login">登录</el-button>
                      </div>
                    </el-form>
                  </div>
                </div>
                <div v-if="loginName!=null && loginName!=''" style="padding: 20px;color:#333;border: 1px solid #E5E5E5;position:relative;height:300px;background-color: #fff;">
                  <div v-if="loginType==1">
                     <div style="line-height:50px;margin-top:20px">您好，尊贵的用户:</div>
                      <div style="line-height:80px;font-size:23px;text-align:center;">{{loginInfo.userName}}</div>
                      <div style="line-height:50px;color:#E60000;">欢迎登陆!</div>
                      <div style="text-align:center; margin-top: 20px;">
                        <el-button style="width:200px;background-color: #FE0000;color:#ffffff;margin-top:20px"   @click="myInfo" >我的主页</el-button>
                      </div>
                     <!-- 手机号：{{loginInfo.phone}} -->
                  </div>
                  <div v-if="loginType==2">
                     <div style="line-height:50px;margin-top:20px">您好，尊贵的企业用户:</div>
                      <div style="line-height:80px;font-size:23px;text-align:center;">{{loginInfo.company}}</div>
                      <div style="line-height:50px;color:#E60000;">欢迎登陆!</div>
                      <div style="text-align:center; margin-top: 20px;">
                        <el-button style="width:200px;background-color: #FE0000;color:#ffffff;margin-top:20px"   @click="myInfo" >我的主页</el-button>
                      </div>
                     <!-- 信用代码：{{loginInfo.creditCode}} -->
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- <div style="margin-top:20px;">
            <el-row :gutter="40">
              <el-col :span="8">
                <img :src="newsData['hispic'][0] == null ? noData : newsData['hispic'][0].coverPath" height="" width="100%">
              </el-col>
              <el-col :span="8">
                <img :src="newsData['hispic'][1] == null ? noData : newsData['hispic'][1].coverPath" height="" width="100%">
              </el-col>
              <el-col :span="8">
                <img :src="newsData['hispic'][2] == null ? noData : newsData['hispic'][2].coverPath" height="" width="100%">
              </el-col>
            </el-row>
            <div>
              <img src="@/assets/img/index/gyxh-fgx.png" height="" width="100%">
            </div>
            <div style="text-align:center;background-color:#D3704A;line-height:50px;color:#fff">
              <el-row>
                <el-col :span="8">{{ newsData['hispic'][0] == null ? '无' : newsData['hispic'][0].title }}</el-col>
                <el-col :span="8">{{ newsData['hispic'][1] == null ? '无' : newsData['hispic'][1].title }}</el-col>
                <el-col :span="8">{{ newsData['hispic'][2] == null ? '无' : newsData['hispic'][2].title }}</el-col>
              </el-row>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div>
      <div class="c-1200 hyfc" style="padding:20px 0px">
        <div>
          <div class="block-title ">
            <img src="@/assets/img/index/title-img-hyfc.png" style="width:100%">
          </div>
        </div>
        <div style="margin-top:50px">
          <!-- <el-row :gutter="10">
            <el-col :span="12">
              <img v-if="newsData['staff'][0] == null" :src="noData" style="width:100%;height:245px;">
              <img v-else class="link" @click="toNewsDetail(newsData['staff'][0].id)" :src="newsData['staff'][0].coverPath" style="width:100%;height:245px;">
            </el-col>
            <el-col :span="6">
              <img v-if="newsData['staff'][1] == null" :src="noData" style="width:100%;height:245px;">
              <img v-else class="link" @click="toNewsDetail(newsData['staff'][1].id)" :src="newsData['staff'][1].coverPath" style="width:100%;height:245px;">
            </el-col>
            <el-col :span="6">
              <img v-if="newsData['staff'][2] == null" :src="noData" style="width:100%;height:245px;">
              <img v-else class="link" @click="toNewsDetail(newsData['staff'][2].id)" :src="newsData['staff'][2].coverPath" style="width:100%;height:245px;">
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <div>
                <img v-if="newsData['staff'][3] == null" :src="noData" style="width:100%;">
                <img v-else class="link" @click="toNewsDetail(newsData['staff'][3].id)" :src="newsData['staff'][3].coverPath" style="width:100%;">
              </div>
              <div>
                <img v-if="newsData['staff'][4] == null" :src="noData" style="width:100%;">
                <img v-else class="link" @click="toNewsDetail(newsData['staff'][4].id)" :src="newsData['staff'][4].coverPath" style="width:100%;">
              </div>
            </el-col>
            <el-col :span="12">
              <img v-if="newsData['staff'][5] == null" :src="noData" style="width:100%;height:460.33px">
              <img v-else class="link" @click="toNewsDetail(newsData['staff'][5].id)" :src="newsData['staff'][5].coverPath" style="width:100%;height:460.33px">
            </el-col>
            <el-col :span="6">
              <div>
                <img v-if="newsData['staff'][6] == null" :src="noData" style="width:100%;">
                <img v-else class="link" @click="toNewsDetail(newsData['staff'][6].id)" :src="newsData['staff'][6].coverPath" style="width:100%;">
              </div>
            </el-col>
          </el-row>

           -->




          <div>

            <!-- <div v-masonry transition-duration="0.2s" class="container">
                <img v-masonry-tile
                  v-for="(item, index) in newsData.staff"
                  :class="`demo-${item}`"
                  :key="index" class="link" @click="toNewsDetail(item.id)" :src="item.coverPath" >
            </div> -->
            <div v-masonry >

              <!-- <div v-masonry-tile class="card" v-for="(item,index) in newsData.staff" :key="index" >
                <img class="link" @click="toNewsDetail(item.id)" :src="item.coverPath" >
              </div> -->



              <img v-masonry-tile width="280px" style="margin: 10px" v-for="(item,index) in newsData.staff" :key="index"  class="link card" @click="toNewsDetail(item.id,8)" :src="item.coverPath" >
            
            </div>
          </div>
          <div>
                <img @click="toList(7,1)" src="@/assets/img/index/more.png" style="width:100px;cursor: pointer;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { listRecord } from '@/api/news'
import { getCodeImg,webLogin,getCurrentLoginInfo } from "@/api/login";
import {setToken} from '@/utils/auth'
import noData from '@/assets/img/no-data.png'
import { isEmpty } from '@/utils/validator';

export default {
  name: "menue_page-index",
  components: {},
  data() {
    return {
      loginTip:"手机号码",
      codeUrl: "",
      // 验证码开关
      captchaOnOff: true,
      loginForm:{
          type:"1",
          loginMode:"1"
      },
      loginPhone:"",
      loginName:"",
      loginType:"",
      loginInfo:{},
      registrationInfo:{},
			xwzxActiveIndex: '1',
      xwzxActiveIndex2: '1',
      xwzxActiveIndex3: '1',
      xwzxActiveIndex4:'1',
      noData: noData,
      carouselList: [
          // {path: require('../../../assets/img/index/banner.png')},
          // {path: require('../../../assets/img/logo.png')},
          // {path: require('../../../assets/logo1.png')},
          // {path: require('../assets/banner3.jpg')}
        ],
      imgFwzl:{
        url: require('../../../assets/img/index/bg-fwzl.png'),
        name: 'bg'
      },
      imgbgXwhf: require('@/assets/img/index/xwhf.png'),
      newsCondition: {
        'focus': {module:1,type:1,size:10},
        'focus-hot': { module: 1, type: 1 ,size:3,hot:1},
        'industry': { module: 1, type: 2, size: 10 },
        'industry-hot': { module: 1, type: 2, size: 3, hot: 1 },
        'word': { module: 1, type: 3,size: 10 },
        'word-hot': { module: 1, type: 3, size: 3, hot: 1 },
        'law': { module: 1, type: 4,size: 10 },
        'law-hot': { module: 1, type: 4, size: 3, hot: 1 },
        'calture': { module: 1, type: 5 ,size: 10 },
        'party': { module: 1, type: 6, size: 10 },
        'human': { module: 2, type: 1, size: 10 },
        'equipment': { module: 2, type: 3, size: 10 },
        'tast-tech': { module: 2, type: 2, size: 10 },
        'wine-tech': { module: 3, type: 1, size: 3 },
        'wine-tech-hot': { module: 3, type: 1, size: 4, hot: 1 },
        'history': { module: 4, type: 1, size: 3 },
        'description': { module: 4, type: 2, size: 3 },
        'staff': { module: 7, type: 1, size: 7 ,hot: 1 },
        'hispic': { module: 5, type: 2, size: 3 }
      },
      newsData: {
        'focus': [],
        'focus-hot': [],
        'industry': [],
        'industry-hot': [],
        'word': [],
        'word-hot': [],
        'law': [],
        'law-hot': [],
        'calture': [],
        'party': [],
        'human': [],
        'equipment': [],
        'tast-tech': [],
        'wine-tech': [],
        'wine-tech-hot':[],
        'history': [],
        'description': [],
        'hispic': [],
        'staff': []
      },
      classLh16:""
    };
  },
  mounted() {
    this.loadNews()
      this.loginId=this.$store.getters.loginId
      if(!isEmpty(this.loginId)){
        this.loginPhone=this.$store.getters.loginPhone
        this.loginName=this.$store.getters.loginName
        this.loginType=this.$store.getters.loginType
        this.getCurrentLoginInfo();
      }else{
        this.getCode();
      }

      // if(window.location.pathname.substring(window.location.pathname.length - 5) == "index"){
      // }
      var urldest=this.changeURLArg(window.location.href,"module","0");
      history.pushState(null,null,urldest);  

    // if(window.location.href.substring(window.location.href.length - 5,window.location.href.length) == "index"){
    //  debugger
    //   this.$router.push({
    //     path:'/menu_page/index?module='+0
        
    //   })
    // }
  },
  watch:{
  },
  methods: {
    changeURLArg(url,arg,arg_val){ 
            var pattern=arg+'=([^&]*)'; 
            var replaceText=arg+'='+arg_val; 
            if(url.match(pattern)){ 
                var tmp='/('+ arg+'=)([^&]*)/gi'; 
                tmp=url.replace(eval(tmp),replaceText); 
                return tmp; 
            }else{ 
                if(url.match('[\?]')){ 
                    return url+'&'+replaceText; 
                }else{ 
                    return url+'?'+replaceText; 
                } 
            } 
            return url+'\n'+arg+'\n'+arg_val; 
        } ,
    xwzxHandleSelect(key, keyPath) {
        console.log(key, keyPath);
    },
    loadNews() { 
      const example = this.newsCondition
      for (let key in example) { 
        const value = example[key]
        const queryParams = { pageNum: 1, pageSize: value.size, moduleId: value.module, typeId: value.type, orderByColumn: 'addTime_', isAsc: 'desc' };
        if (value['hot'] != null) { 
          queryParams['isHot'] = 1
        }
        listRecord(queryParams).then(res => { 
          this.newsData[key] = res.rows
          console.log(this.newsData)
        })
      }
      const wallParam = { pageNum: 1, pageSize: 10, moduleId: 5, typeId: 3, orderByColumn: 'addTime_', isAsc: 'desc' };

      listRecord(wallParam).then(res => {
        const rows = res.rows
        const wall = []
        for (let i = 0; i < rows.length; i++){
          wall.push({ path: rows[i].coverPath, linkPath: rows[i].linkPath, title: rows[i].title, desc: rows[i].description });
        }
        this.carouselList = wall
      })
    },
    toList(module,type) { 
      this.$router.push({
        path: '/menu_page/news_list',
        query: { module: module, type: type }
      })
    },
    tonewslist1(){
      if(this.xwzxActiveIndex == 1){
        window.location.href = '/menu_page/news_list?module=1&type=1'
      }
      if(this.xwzxActiveIndex == 2){
        window.location.href = '/menu_page/news_list?module=1&type=2'
      }
      if(this.xwzxActiveIndex == 3){
        window.location.href = '/menu_page/news_list?module=1&type=3'
      }
      if(this.xwzxActiveIndex == 4){
        window.location.href = '/menu_page/news_list?module=1&type=4'
      }
    },
    tonewslist2(){
      window.location.href = '/menu_page/news_list?module=1&type=5'
    },
    tonewslist3(){
      window.location.href = '/menu_page/news_list?module=1&type=6'
    },
    tonewslist4(){
      window.location.href = '/menu_page/news_list?module=2&type=1'
    },
    tonewslist5(){
      if(this.xwzxActiveIndex3 == 1){
        window.location.href = '/menu_page/news_list?module=2&type=2'
      }
      if(this.xwzxActiveIndex3 == 2){
        window.location.href = '/menu_page/news_list?module=2&type=3'
      }
    },
    toNewsDetail(id, from) {
      window.location.href = '/menu_page/news_detail?id=' + id + '&from=' + from
    },
    getContent(content){
      return content.replace(/<\/?.+?\/?>/g,'')
    },
    queryCertificate(){
      this.$router.push({
        path: '/menu_page/certificate/certificate_query'
      })
    },
    hygl(){
      this.$router.push({
        path: '/menu_page/certificate/'
      })
    },
    myInfo(){
      this.$router.push({
        path: '/menu_page/my/my_index'
      })
    },
    toPath(item) { 
      if (item.linkPath != null) {
        window.location.href = item.linkPath
      }
    },
    trainExamList(){
      this.$router.push({
        path:'/menu_page/train_exam_list'
      })
    },
    choiceLoginType(type){
      this.loginForm.type = type;
      if(type==1){
        this.loginTip="手机号码";
        this.classLh16 ='';
      }
      if(type==2){
        this.loginTip="手机或信代码"
        this.classLh16 ='lh-16';
      }
    },
    // 图形验证码
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    // 登录
    login:function(){
      if(this.loginForm.loginPhone == null){
          this.$message.warning('登录手机号未填写!')
          return
      }
      if(this.loginForm.inputPwd == null){
        this.$message.warning('密码未填写!')
        return
      }
      if(this.loginForm.picCode == null){
        this.$message.warning('图形验证码未填写!')
        return
      }
      webLogin(this.loginForm).then(response => {
          const token = response.token
          setToken(token)
          this.$message.success('登录成功!')
          this.getCode();
          this.$store.dispatch("user/login",response.loginInfo)
          localStorage.setItem("loginInfo",JSON.stringify(response.loginInfo))
          if(response.loginInfo.state===-2){
            this.$alert('您的申请未审批通过，请重新申请！', '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: action => {
                  if (action) {
                    if(response.loginInfo.type===1){
                      window.location.href='/menu_page/customer/user_edit?id='+ this.$store.getters.loginId
                    }else{
                      window.location.href='/menu_page/customer/customer_edit?id='+this.$store.getters.loginId;
                    }
                  }
                }
              });
          }else{
            window.location.href='/menu_page/index?module='+0
          }
      })
    },
    // 查询登录信息
    getCurrentLoginInfo:function(){
      getCurrentLoginInfo().then(response => {
        this.loginInfo=response.data
      })
    }
  },
};
</script>

<!-- <style scoped lang="scss"> -->
<style scoped>
#mask {
      width: 100%;
      height: 100%;
      /* position: absolute; */
      top: 0;
      z-index: 101;
    }

.link {
  cursor: pointer;
}
 
/deep/.xwzx .el-menu--horizontal>.el-menu-item{height:40px;line-height:40px;}
/deep/.xwzx .el-menu {font-weight: bolder;border-bottom: 1px solid #E60000;}
/deep/.xwzx .el-menu .el-icon-arrow-down:before{color:#E60000}
/deep/.xwzx .el-icon-arrow-right:before{color:#E60000}
/deep/.xwzx .el-menu  .is-active,/deep/.xwzx .el-menu .is-active .el-submenu__title{background-color: #E60000 !important;border-bottom-color: #E60000 !important}
/* /deep/.xwzx .el-menu.el-menu--horizontal,/deep/.xwzx .el-menu .is-active .el-submenu__title{border-bottom:none !important} */


/deep/.jsfw .el-menu--horizontal>.el-menu-item{height:40px;line-height:40px;}
/deep/.jsfw .el-menu {font-weight: bolder;border-bottom: 1px solid #E60000;}
/deep/.jsfw .el-menu .el-icon-arrow-down:before{color:#E60000}
/deep/.jsfw .el-icon-arrow-right:before{color:#E60000}
/deep/.jsfw .el-menu  .is-active,/deep/.jsfw .el-menu .is-active .el-submenu__title{background-color: #E60000 !important;border-bottom-color: #E60000 !important}


/deep/.gyxh .el-menu--horizontal>.el-menu-item{height:40px;line-height:40px;}
/deep/.gyxh .el-menu {font-weight: bolder;border-bottom: 1px solid #E60000;}
/deep/.gyxh .el-menu .el-icon-arrow-down:before{color:#E60000}
/deep/.gyxh .el-icon-arrow-right:before{color:#E60000}
/deep/.gyxh .el-menu  .is-active,/deep/.gyxh .el-menu .is-active .el-submenu__title{background-color: #E60000 !important;border-bottom-color: #E60000 !important}

.gyxh-item{
  text-align: center;
  background-color:#F6F6F6;
  padding:10px; 
}
.gyxh-item p{
  margin-top:5px;
  margin-bottom:0px;
}
.gyxh1,.gyxh2{
  margin:20px 0px;
}
.gyxh1 .gyxh-item{
  line-height:40px;
  height:40px
}
.gyxh1 .gyxh-item img{
  vertical-align: middle;
}
.gyxh1 .gyxh-item span{
  margin-left:10px;
}
.gyxh2 .gyxh-item{
  height:85px;
  position: relative;
  text-align:center;
}

.gyxh2 .gyxh-item img{
  position: absolute;
  bottom:55px
}

.gyxh2 .gyxh-item p{
  position: absolute;
  top:50px;
  font-size:14px;
  width:calc(100% - 20px);
  text-align:center;
  line-height:18px;
}
.login-code-img {
  height: 35px;
  /* margin-left: 10px; */
  width:100%;
}


/deep/.lh-16 .el-form-item__label{
  line-height: 16px;
}

.banner{
  width:100%;
  height:25vw;
  min-width:1200px;
  min-height:300px;
}
/deep/.el-carousel__item.is-animating{
  width:100%;
  height:25vw;
  min-width:1200px;
  min-height:300px;
  overflow:hidden;
}
/deep/.el-carousel__container{
  width:100%;
  height:25vw;
  min-width:1200px;
  min-height:300px;
  overflow:hidden;

}
/deep/.el-carousel__item.is-active{
  width:100%;
  height:25vw;
  min-width:1200px;
  min-height:300px;
  overflow:hidden;
}
/deep/.el-carousel__item img{

  width:100%;
  height:25vw;
  min-width:1200px;
  min-height:300px;
}
</style>