<template>
  <div class="main-body" style="width:1280px;margin:0 auto">
    <div style="text-align:center">
      已报名列表
    </div>
    <div>
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
        <el-form-item label="课程类型" prop="courseType">
          <el-select v-model="queryParams.courseType" placeholder="请选择课程类型" clearable>
            <el-option
              v-for="dict in dict.type.course_type"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="课程名称" prop="courseName">
          <el-input
            v-model="queryParams.courseName"
            placeholder="请输入课程名称"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="课程老师" prop="teacher">
          <el-input
            v-model="queryParams.teacher"
            placeholder="请输入课程老师"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>

  
      <el-table v-loading="loading" :data="recordList">
        <el-table-column label="报名ID" align="center" prop="id" />
        <el-table-column label="课程名称" align="center" prop="courseName" />
        <el-table-column label="课程类型" align="center" prop="courseType">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.course_type" :value="scope.row.courseType"/>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100px" align="center" prop="status">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sign_status" :value="scope.row.status"/>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center" prop="name" />
        <el-table-column label="性别" align="center" prop="sex">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.sys_user_sex" :value="scope.row.sex"/>
          </template>
        </el-table-column>
        <el-table-column label="联系方式" align="center" prop="phone" />
        <el-table-column label="文化程度" align="center" prop="edu">
          <template slot-scope="scope">
            <dict-tag :options="dict.type.edu" :value="scope.row.edu"/>
          </template>
        </el-table-column>
        <el-table-column label="身份证" align="center" prop="idCard" />
        <el-table-column label="联系地址" align="center" prop="address" />
        <el-table-column label="查看详情" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-add"
              @click="goToDetail(scope.row.id)"
            >报名详情</el-button>

            <el-button
              v-if="scope.row.status == '3'"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="goToSignEdit(scope.row.id, scope.row.courseId)"
            >修改</el-button>

          </template>
        </el-table-column>
      </el-table>
         
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />

    </div>
  </div>
</template>

<script>

import { listSignRecord } from "@/api/courses/signRecord";

export default {
  name: "train_exam_list",
  dicts: ['course_type','sign_status','edu', 'sys_user_sex'],
  components: {},
  data() {
    return {
      loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 显示搜索条件
        showSearch: true,
        // 总条数
        total: 0,
        // 课程信息表格数据
        recordList: [],
        // 弹出层标题
        title: "",
        // 是否显示弹出层
        open: false,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          courseName: null,
          courseType: null,
          courseDetail: null,
          teacher: null,
          startTime: null,
          endTime: null,
          status: null,
        },
    };
  },
  created() {
      this.getList();
    },
  watch:{
  },
  methods: {
    getList() {
        this.loading = true;
        listSignRecord(this.queryParams).then(response => {
          console.log(response)
          this.recordList = response.rows;
          this.total = response.total;
          this.loading = false;
        });
      },
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      goToDetail(recordId){
        this.$router.push({path: '/menu_page/train_sign_detail',query:{ recordId: recordId}});
      },
      goToSignEdit(recordId, courseId){
        this.$router.push({path: '/menu_page/train_exam_registration',query:{courseId: courseId, recordId: recordId}});
      }
  },
};
</script>
<!-- <style scoped lang="scss"> -->
<style scoped >

.flex{
  display:flex;
}
.block-title{
  margin-top:50px;
}
.block-content-line-item{flex:4}
/deep/ .block-content-line-item .el-select{width:100%;}
/deep/ .block-content-line .el-form-item__label{padding-left: 30px;}
/deep/ .bcli-lh18  .el-form-item__label{line-height: 18px;}
/* /deep/ .block-content-line-item .el-form-item__content{margin-left: 120px !important;} */
/deep/ .label-center .el-form-item__label{text-align: center;}
.label1{
  width: 150px;padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2{
  padding-left: 30px;line-height: 32px;text-align: right;vertical-align: middle;float: left;font-size: 14px;color: #606266; padding: 0 12px 0 0;box-sizing: border-box;
}
.label2 span{
  padding-left:5px;padding-right: 5px;text-decoration: underline;
}
</style>